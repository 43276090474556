import React from "react";
import TextField from "./Elements/TextField";
import NumberField from "./Elements/NumberField";
import TextArea from "./Elements/TextArea";
import DropDown from "./Elements/DropDown";
import Password from "./Elements/Password";
import SwitchField from "./Elements/SwitchField";
import SliderField from "./Elements/SliderField";
import CheckBoxField from "./Elements/CheckBoxField";
import MultiSelect from "./Elements/MultiSelect";
import RadioField from "./Elements/RadioField";
import LookUpField from "./Elements/LookUpField";
import DateTimeField from "./Elements/DateTimeField";
import DateField from "./Elements/DateField";
import TimeField from "./Elements/TimeField";
import UploadImage from "./Elements/UploadImage";

export function isFormCompleted(chat) {
  // console.log("isComplted ==== ", chat);

  return chat.completed || (chat.options && chat.options.stage === "COMPLETED");
}

export function getFormFieldElement(
  isReadOnly = false,
  chat,
  field,
  action,
  completed,
  onBlurField,
  doDataLookUp,
  clearLookUpResults
) {
  switch (field.type) {
    case "title":
      // return <span className="d-block title">{field.title}</span>;
      return null;

    case "text_field":
      return (
        <TextField
          isReadOnly={isReadOnly}
          chat={chat}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "lookup":
      return (
        <LookUpField
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
          doDataLookUp={doDataLookUp}
          clearLookUpResults={clearLookUpResults}
        />
      );

    case "number":
    case "number_field":
      return (
        <NumberField
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "text_area":
      return (
        <TextArea
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "select":
    case "dropdown":
      return (
        <DropDown
          isReadOnly={isReadOnly}
          chat={chat}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "password_field":
      return (
        <Password
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "checkbox":
      return (
        <CheckBoxField
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "radiobutton":
      return (
        <RadioField
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "switch":
      return (
        <SwitchField
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "slider":
      return (
        <SliderField
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "date":
      return (
        <DateField
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "datetime":
      return (
        <DateTimeField
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "time":
      return (
        <TimeField
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "multi_selection":
      return (
        <MultiSelect
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );
    case "image_field":
      return (
        <UploadImage
          isReadOnly={isReadOnly}
          key={field.id}
          element={field}
          action={action}
          completed={completed}
          onBlurField={onBlurField}
        />
      );

    case "button":
      return null;

    default:
      return <div>Not Implemented</div>;
  }
}

export function getFormTitle(data, options) {
  if (options && options.title) {
    return options.title;
  } else {
    if (Array.isArray(data)) {
      let el = data.filter(formElement => formElement.type === "title");
      if (el && el[0]) {
        return el[0].title;
      }
      return null;
    }
  }
}

export function getFormDescription(data, options) {
  if (options && options.description) {
    return options.description;
  } else {
    return null;
  }
}

export function getFormFields(data, type) {
  if (!data) {
    return;
  }
  if (Array.isArray(data)) {
    return data.filter(
      formElement =>
        formElement.type !== "title" && formElement.type !== "button"
    );
  } else {
    console.log("form fields data ", data);
    let { fields } = data;

    return fields;
  }
}

export function getConfirmButtonName(data, options) {
  if (options && options.confirm) {
    return options.confirm;
  } else {
    if (Array.isArray(data)) {
      let button = data.filter(formElement => formElement.type === "button");
      if (button && button[0]) {
        return button[0].title;
      }
    }
  }
}

export function getCancelButtonName(data, options) {
  if (options && options.cancel) {
    return options.cancel;
  } else {
    return "Cancel";
  }
}
