import React from "react";
import ReactImageFallback from "react-image-fallback";

const R = require("ramda");

const Avatar = ({
  thumbnail = null,
  name,
  online = false,
  imgSrc,
  size = "avatar32",
  color,
  style
}) => {
  if (imgSrc) {
    // console.log("img in avatar ", imgSrc);

    color = color || "bg-primary";
    // let className = 'list-thumb avatar rounded-circle ' + size + ' ' + color
    // let className = color;
    let width = size + "px";
    return (
      <div style={{ ...style, overflow: "hidden", width: width }}>
        {online ? <i className="states on" /> : null}
        <ReactImageFallback
          className="img-fluid"
          src={imgSrc}
          fallbackImage="http://placehold.it/200x200"
          alt="NO LOGO"
        />
      </div>
    );
  }

  color = color || "bg-info";
  let nameClass = "list-thumb avatar text-info-light rounded-circle " + color;
  if (size === 16) {
    nameClass += " avatar16";
  } else if (size === 40) {
    nameClass += " avatar40";
  } else if (size === 64) {
    nameClass += " avatar64";
  } else if (size === 160) {
    nameClass += " avatar160";
  } else if (size === 120) {
    nameClass += " avatar120";
  } else {
    nameClass += " avatar32";
  }

  return (
    <div className={nameClass} style={{ ...style, overflow: "hidden" }}>
      {online ? <i className="states on" /> : null}
      {getNameAvatar(name)}
    </div>
  );
};

const getNameAvatar = name => {
  const nameAvatar = R.isNil(name)
    ? "NA"
    : R.pipe(
        R.split(" "),
        R.map(w => w.charAt(0)),
        R.take(2),
        R.map(w => w.toUpperCase()),
        R.join("")
      )(name);
  return nameAvatar;
};

export default Avatar;
