import React, { PureComponent } from "react";
import PaymentsContainer from "../../../Containers/PaymentsContainer";
import { connect } from "react-redux";
import { MessageTypeConstants } from "../../../Services/Message";
import {
  sendAMessage,
  removeRouteFromWindowComponent,
} from "../../../State/actions/chats";
import history from "../../../Services/History";
import { saveRoute } from "../../../State/actions/routeAction";

class PaymentFormView extends PureComponent {
  continuePayment = (token) => {
    const { chat, sendMessage } = this.props;
    let { options, messageId } = chat;
    let { amount, currency, description } = options;
    let responseChat = { ...chat };
    responseChat.message = { token: token, amount, currency, description };
    responseChat.options = options || {};
    responseChat.options.stage = "COMPLETED";
    responseChat.messageId = messageId;
    responseChat.messageType =
      MessageTypeConstants.MESSAGE_TYPE_STRIPE_RESPONSE;
    sendMessage(responseChat);
    if (this.props.cancel) {
      this.props.cancel();
    }
  };

  sendPaymentResponseMessage = (success) => {
    const { chats, sendMessage, componentinWindow } = this.props;
    console.log("sendPaymentResponseMessage ======", success);

    let { options, messageId } = componentinWindow;
    let { amount, currency, transactionId } = options;
    let responseChat = { ...chats };
    responseChat.message = {
      paymentSuccessful: success,
      transactionId,
      amount,
      currency,
    };
    responseChat.options = options || {};
    responseChat.options.stage = "COMPLETED";
    responseChat.messageId = messageId;
    responseChat.messageType =
      MessageTypeConstants.MESSAGE_TYPE_STRIPE_RESPONSE;

    // console.log("payment message to bot ", responseChat);

    sendMessage(responseChat);

    // if (success) {
    // debugger;
    this.props.saveRoute("/chats");
    this.props.removeRouteFromWindowComponent();
    history.push("/chats");
    // }
  };

  render() {
    let { componentinWindow } = this.props;
    let { options } = componentinWindow;
    // console.log("payment method ", this.props);

    return (
      <PaymentsContainer
        options={options}
        continuePayment={this.continuePayment}
        sendPaymentResponseMessage={this.sendPaymentResponseMessage}
      />
    );
  }
}

let actions = {
  sendMessage: sendAMessage,
  removeRouteFromWindowComponent: removeRouteFromWindowComponent,
  saveRoute: saveRoute,
};

const mapStateToProps = (state) => ({
  chats: state.chats,
  route: state.route,
  componentinWindow: state.chats.componentInWindow,
});

export default connect(mapStateToProps, actions)(PaymentFormView);
