// import { auth } from "../gRPC/Generated/AuthService";
// import RPC from "./RPC";

import AuthService from "../gRPC/Generated/AuthService";
import RPC from "./RPC";
import UserService from "../gRPC/Generated/UserService";
const auth = AuthService.auth;
const commonmessages = UserService.commonmessages;

class AuthServiceClient {
  static doConfirmSignUp(email, password, code) {
    return RPC.rpcCall(
      "/auth.AuthService/ConfirmSignup",
      { email: email, password: password, confirmCode: code },
      auth.SignupResponse,
      (request) => {
        return auth.SignupUser.encode(request).finish();
      },
      auth.SignupResponse.decode
    );
  }

  static doSignUp(signUpInfo) {
    return RPC.rpcCall(
      "/auth.AuthService/Signup",
      signUpInfo,
      auth.SignupResponse,
      (request) => {
        return auth.SignupUser.encode(request).finish();
      },
      auth.SignupResponse.decode
    );
  }

  // static doFrontMLogin(email, password) {
  //   return RPC.rpcCall(
  //     "/auth.AuthService/FrontmSignin",
  //     {
  //       email: email,
  //       password: password,
  //       platform: "web"
  //     },
  //     auth.SigninResponse,
  //     request => {
  //       return auth.FrontmSigninInput.encode(request).finish();
  //     },
  //     auth.SigninResponse.decode
  //   );
  // }

  static resetPassword(data) {
    return RPC.rpcCall(
      "/auth.AuthService/ResetPassword",
      data,
      auth.SignupResponse,
      (request) => {
        return auth.SignupUser.encode(request).finish();
      },
      auth.SignupResponse.decode
    );
  }
  static confirmPasswordReset(data) {
    return RPC.rpcCall(
      "/auth.AuthService/ConfirmPasswordReset",
      data,
      auth.SignupResponse,
      (request) => {
        return auth.SignupUser.encode(request).finish();
      },
      auth.SignupResponse.decode
    );
  }
  static sendCodeAgain(data) {
    return RPC.rpcCall(
      "/auth.AuthService/ResendSignupConfirmCode",
      data,
      auth.SignupResponse,
      (request) => {
        return auth.SignupUser.encode(request).finish();
      },
      auth.SignupResponse.decode
    );
  }

  // static doGoogleLogin(code) {
  //   return RPC.rpcCall(
  //     "/auth.AuthService/GoogleSignin",
  //     {
  //       code,
  //       platform: "web",
  //     },
  //     auth.SigninResponse,
  //     (request) => {
  //       return auth.GoogleSigninInput.encode(request).finish();
  //     },
  //     auth.SigninResponse.decode
  //   );
  // }

  static doFacebookLogin(token, userName, emailAddress) {
    return RPC.rpcCall(
      "/auth.AuthService/FacebookSignin",
      {
        token: token,
        platform: "web",
        userName: userName,
        emailAddress: emailAddress,
      },
      auth.SigninResponse,
      (request) => {
        return auth.FacebookSigninInput.encode(request).finish();
      },
      auth.SigninResponse.decode
    );
  }

  static changePassword = (request) => {
    return RPC.rpcCall(
      "/auth.AuthService/ChangePassword",
      request,
      auth.SignupResponse,
      (request) => {
        return auth.SignupUser.encode(request).finish();
      },
      auth.SignupResponse.decode
    );
  };

  static createAnonymousAccess = (urlDomain, botId) => {
    return RPC.rpcCall(
      "/auth.AuthService/CreateAnonymousAccess",
      { urlDomain: `${urlDomain}/${botId}`, botId: botId, platform: "web" },
      auth.SigninResponse,
      (request) => {
        return auth.AnonAccessInput.encode(request).finish();
      },
      auth.SigninResponse.decode
    );
  };

  static refreshAnonymousUserSession = () => {
    return RPC.rpcCall(
      "/auth.AuthService/RefreshAnonymousUserSession",
      {},
      auth.SignupResponse,
      (request) => {
        return commonmessages.Empty.encode(request).finish();
      },
      auth.SignupResponse.decode
    );
  };
}

export default AuthServiceClient;
