import store from "../../State/configureStore";
import { showSpinner, hideSpinner } from "../../State/actions/spinner";
import { logout } from "../../State/actions/user";
import {
  setBannerVisibility,
  setCounter
} from "../../State/actions/offlineBanner";
import Config from "./../../Utils/Config";

export default class ServiceClientUtils {
  static requestInterceptor(noSpinner) {
    if (!noSpinner) {
      store.dispatch(showSpinner());
    }
  }

  static handleResponse(response) {
    store.dispatch(hideSpinner());
    if (!response) {
      throw new Error();
    }

    if (
      (response.hasOwnProperty("success") && !response.success) ||
      (response.hasOwnProperty("error") && response.error != 0)
    ) {
      console.log("error log ", response);

      if (response.error === 98) {
        throw new Error(response.errorMessage);
      }

      throw new Error(response.message);
    }

    // if (!response.success) {
    //   if ((response.error && response.error != 0) || response.message) {
    //     // ServiceClientUtils.handleError(response.message);
    //     throw new Error(response.message);
    //   }
    // }

    return response;
  }

  static handleError(error, url) {
    store.dispatch(hideSpinner());

    store.dispatch(hideSpinner());
    if (error && error.code === 14) {
      console.log("API ERROR @ 14: ", error.message);
      store.dispatch(setBannerVisibility(true));
      store.dispatch(setCounter(5));
    } else {
      let message =
        error && error.message
          ? error.message
          : "Server error has occurred. Please try again";
      if (Config.envName === "dev") {
        message += ". URL= " + url;
      }
      let auth = store.getState().user ? store.getState().user.auth : null;
      if (!auth) {
        return;
      }
      console.log("API ERROR ======: ", message);
      //hiding the errors from users
      // Notify({
      //   type: "error",
      //   message
      // });
    }

    if (error.message === "UNAUTHORIZED") {
      console.log("API ERROR @ UNAUTHORIZED: ", error.message);
      store.dispatch(logout());
    }
    throw Error;
  }

  static parseBytesContent(content) {
    if (content && content.length > 0) {
      return JSON.parse(String.fromCharCode.apply(null, content));
    }
    return content;
  }
}
