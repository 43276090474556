import React from "react";
import Switch from "../../../Common/Switch";
import Mandatory from "./Mandatory";
import InfoIcon from "./InfoIcon";
import ErrorMessage from "./ErrorMessage";

export default function SwitchField({
  isReadOnly = false,
  element,
  action,
  completed,
  onBlurField
}) {
  let content = (isReadOnly && (
    <div className="d-flex flex-row justify-content-between readOnlyForm">
      <label htmlFor={element.id}>
        {element.title}
        <Mandatory element={element} />
      </label>
      <label>{element.value || ""}</label>
    </div>
  )) || (
    <div className="form-group px-4" onBlur={onBlurField}>
      <label
        htmlFor={element.id}
        className="d-flex align-items-center flex-row"
      >
        {element.title}
        <Mandatory element={element} />
        <span className="ml-2">
          <InfoIcon id={`tooltip-${element.id}`} info={element.info} />
        </span>
      </label>
      <div className="d-flex align-items-center">
        <Switch
          variant={"pill"}
          color={"primary"}
          checked={element.value}
          onChange={action}
          disabled={completed || element.readOnly}
        />
      </div>
      <ErrorMessage element={element} />
    </div>
  );
  return content;
}
