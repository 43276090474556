import React from "react";
import Mandatory from "./Mandatory";
import InfoIcon from "./InfoIcon";
import ErrorMessage from "./ErrorMessage";

export default function NumberField({
  isReadOnly = false,
  element,
  action,
  completed,
  onBlurField
}) {
  let content = (isReadOnly && (
    <div className="d-flex flex-row justify-content-between readOnlyForm">
      <label
        htmlFor={element.id}
        className="d-flex align-items-center flex-row"
      >
        {element.title}
        <Mandatory element={element} />
      </label>
      <label>{element.value || ""}</label>
    </div>
  )) || (
    <div className="form-group px-4">
      <div className="d-flex flex-column">
        <label
          htmlFor={element.id}
          className="d-flex align-items-center flex-row"
        >
          {element.title}
          <Mandatory element={element} />
          <span className="ml-2">
            <InfoIcon id={`tooltip-${element.id}`} info={element.info} />
          </span>
        </label>
        <input
          type="number"
          disabled={completed === true || element.readOnly}
          className="form-control form-content-input"
          id={element.id}
          value={element.value || ""}
          onChange={e => {
            action(e.target.value);
          }}
          placeholder=""
          onBlur={onBlurField}
        />
      </div>
      <ErrorMessage element={element} />
    </div>
  );

  return content;
}
