import React, { Component } from "react";
import MapGL, { Popup, FlyToInterpolator } from "react-map-gl";
import * as d3 from "d3-ease";
import _ from "lodash";
import Config from "../../Utils/Config";
import "./maps.css";
import WebMercatorViewport from "viewport-mercator-project";
import MapStyles from "./MapStyles";
import PolylineOverlay from "./PolyLineOverlay";
import MarkerComponent from "./MarkerComponent";
import CircleRoute from "./CircleRoute";
import { isFloat } from "../content/MapContentView"; // "../Interactions/content/MapContentView";

export default class MapComponent extends Component {
  constructor(props) {
    super(props);

    let {
      width,
      height,
      fitBounds,
      boundPointsCord = [],
      toFitBounds,
      boundPoints = [],
      latitude,
      longitude,
      zoom
    } = this.props;

    if (toFitBounds) {
      const viewport = new WebMercatorViewport({
        width,
        height
      });
      let bounds = boundPoints.map(d => {
        let lon = parseFloat(d.longitude);
        let lat = parseFloat(d.latitude);

        return [lon, lat];
      });
      const bounded = viewport.fitBounds(bounds, {
        padding: 40
      });

      latitude = bounded.latitude;
      longitude = bounded.longitude;
      zoom = bounded.zoom;
    }

    // console.log("bound cordinates ", fitBounds, boundPointsCord);
    // if (fitBounds) {
    //   const viewport = new WebMercatorViewport({
    //     width,
    //     height
    //   });
    //   const bounds = viewport.fitBounds(boundPointsCord, {
    //     padding: 40,
    //     offset: [0, -40]
    //   });

    //   console.log("we will see bound ", bounds);
    // }

    this.state = {
      polyLines: (this.props.polyLines && [...this.props.polyLines]) || [],
      planeRoutes: [],
      viewport: {
        latitude: parseFloat(latitude),
        longitude: parseFloat(longitude),
        zoom: zoom,
        bearing: 0,
        pitch: 0
      }
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.fitBounds !== this.props.fitBounds &&
      nextProps.boundPointsCord.length !== this.props.boundPointsCord
    ) {
      if (nextProps.fitBounds) {
        let width = window.innerWidth;
        let height = window.innerHeight;

        const viewport = new WebMercatorViewport({
          width: width - 50,
          height: height - 50
        });
        const bounds = viewport.fitBounds(nextProps.boundPointsCord, {
          padding: 40,
          offset: [0, -40]
        });
        let newLatitude = bounds.latitude;
        let newLongitude = bounds.longitude;
        let newZoom = bounds.zoom;
        // console.log("we will see bound ", bounds, newLatitude, newLongitude);

        this.setState({
          viewport: {
            latitude: parseFloat(newLatitude),
            longitude: parseFloat(newLongitude),
            zoom: newZoom,
            bearing: bounds.bearing || 0,
            pitch: bounds.pitch || 0
          }
        });
      }
    }
    if (
      nextProps.planeRoutes &&
      this.state.planeRoutes &&
      !_.isEqual(nextProps.planeRoutes, this.state.planeRoutes)
    ) {
      this.setState({ planeRoutes: [...nextProps.planeRoutes] });
    }
  }

  _onViewportChange = viewport => {
    let data = {
      latitude: viewport.latitude,
      longitude: viewport.longitude,
      zoom: viewport.zoom,
      transitionDuration: "3000"
    };
    if (this.props.onchangeMapPosition) {
      this.props.onchangeMapPosition(data);
    }
    this.setState({ viewport });
  };

  _renderMarker = (mark, index) => {
    // console.log("lets see the marker ok", mark);

    if (
      !mark ||
      !mark.coordinate ||
      !isFloat(mark.coordinate.longitude) ||
      !isFloat(mark.coordinate.latitude)
    ) {
      return null;
    }
    return (
      <MarkerComponent
        key={`marker-${index}`}
        markerData={mark}
        longitude={mark.coordinate.longitude}
        latitude={mark.coordinate.latitude}
        onClick={() => {
          // console.log("mark====", mark);
          this.setState({ popupInfo: mark });
        }}
        markerSize={this.props.markerSize}
        markerColor={mark.color || this.props.markerColor}
        iconType={mark.iconType}
        iconDirection={mark.coordinate.direction}
      />
    );
  };

  _renderCallOuts = (mark, index) => {
    if (
      mark &&
      mark.callOut &&
      mark.coordinate &&
      isFloat(mark.coordinate.longitude) &&
      isFloat(mark.coordinate.latitude)
    ) {
      return (
        <Popup
          key={`marker-popup-${index}`}
          tipSize={5}
          anchor="top"
          longitude={mark.coordinate.longitude}
          latitude={mark.coordinate.latitude}
          closeButton={false}
        >
          {mark.callOut.text}
        </Popup>
      );
    } else {
      return null;
    }
  };

  _renderPopup() {
    const { popupInfo } = this.state;
    // console.log("in the  pop up map", this.state);

    return (
      popupInfo && (
        <Popup
          tipSize={5}
          anchor="top"
          longitude={popupInfo.coordinate.longitude}
          latitude={popupInfo.coordinate.latitude}
          closeOnClick={false}
          onClose={() => this.setState({ popupInfo: null })}
        >
          <div>
            <div>
              <p>
                <strong>{popupInfo.title}</strong>
              </p>
              <p>{popupInfo.description}</p>
            </div>
          </div>
        </Popup>
      )
    );
  }

  renderPolyLines = polyLines => {
    if (!polyLines || polyLines.length === 0) {
      return null;
    }

    let nodes = [];
    polyLines.forEach((pl, index) => {
      if (pl && pl.coordinates) {
        let points = [];
        pl.coordinates.forEach(c => {
          if (c && isFloat(c.longitude) && isFloat(c.latitude)) {
            points.push([c.longitude, c.latitude]);
          }
        });
        if (points.length > 0) {
          nodes.push(
            <PolylineOverlay color={pl.color} key={index} points={points} />
          );
        }
      }
    });

    return nodes.length > 0 ? nodes : null;
  };

  renderPlaneRoutes = planeRoutes => {
    if (!planeRoutes || planeRoutes.length === 0) {
      return null;
    }

    let nodes = [];
    planeRoutes.forEach((planeRoute, index) => {
      if (
        planeRoute &&
        planeRoute.start &&
        planeRoute.end &&
        isFloat(planeRoute.start.longitude) &&
        isFloat(planeRoute.start.latitude) &&
        isFloat(planeRoute.end.longitude) &&
        isFloat(planeRoute.end.latitude)
      ) {
        nodes.push(<CircleRoute key={index} route={planeRoute} />);
      }
    });
    return nodes.length > 0 ? nodes : null;
  };

  render() {
    const { viewport, planeRoutes } = this.state;
    const { thumb, markers, polyLines, options } = this.props;
    // console.log("map props ========", this.props);
    let mapStyle;
    if (options) {
      switch (options.type) {
        case "streetSat":
          mapStyle = MapStyles.STREET_SATELLITE;
          break;
        case "Sat":
          mapStyle = MapStyles.SATELLITE;
          break;
        default:
          mapStyle = MapStyles.MAP_WITH_MARKERS;
          break;
      }
    } else {
      mapStyle = MapStyles.MAP_WITH_MARKERS;
    }

    if (
      !viewport.latitude ||
      !viewport.longitude ||
      !isFloat(viewport.longitude) ||
      !isFloat(viewport.latitude)
    ) {
      return (
        <div className="text-center p-3">
          Latitude and Longitude should be valid numbers.
        </div>
      );
    }

    return (
      // <div
      //   ariaLabel="map-view"
      //   tabIndex="0"
      //   style={{ width: "100%", height: "100%" }}
      // >
      <MapGL
        ariaLabel="map-view"
        tabIndex="0"
        {...viewport}
        width="100%"
        className="mapGlComponent"
        height={thumb ? 200 : "100%"}
        mapStyle={mapStyle}
        onViewportChange={this._onViewportChange}
        mapboxApiAccessToken={Config.mapboxApiAccessToken}
        transitionInterpolator={new FlyToInterpolator({ curve: 1, speed: 5 })}
        transitionEasing={d3.easePoly.exponent(1)}
      >
        {this.renderPolyLines(polyLines)}
        {this.renderPlaneRoutes(planeRoutes)}
        {markers && markers.map(this._renderMarker)}
        {markers && markers.map(this._renderCallOuts)}
        {this._renderPopup()}
      </MapGL>
      // </div>
    );
  }
}
