import ShortUUID from "short-uuid";
import dayjs from "dayjs";
// import Fingerprint2 from "fingerprintjs2";
// import { updateSubscriptionOnServer } from "../State/actions/notification";

export const spaceToUnderscore = value =>
  value.trim().split(" ").length > 1
    ? value
        .trim()
        .split(" ")
        .join("_")
    : value;

export function createUUID() {
  let uuid = ShortUUID.uuid();
  return ShortUUID().fromUUID(uuid);
}

export function getDateTimeString(createdOn) {
  let messageDate = dayjs(createdOn);
  let currentDate = dayjs();

  if (currentDate.endOf("day").diff(messageDate.endOf("day"), "days") === 0) {
    return messageDate.format("HH:mm");
  }

  if (currentDate.endOf("day").diff(messageDate.endOf("day"), "days") === 1) {
    return "Yesterday, " + messageDate.format("HH:mm");
  }

  if (currentDate.year() === messageDate.year()) {
    return messageDate.format("ddd, D MMM HH:mm");
  }

  return messageDate.format("ddd, D MMM YYYY HH:mm");
}

export function computeElapsedTime(modifiedOn) {
  const date1 = dayjs();
  const date2 = dayjs(modifiedOn);
  if (date1.diff(date2, "days") === 1) {
    return "1d";
  }
  if (date1.diff(date2, "days") > 1) {
    return date2.format("DD MMM");
  }

  if (date1.diff(date2, "hours") >= 1) {
    return date1.diff(date2, "hours") + "h";
  } else {
    if (date1.diff(date2, "minutes") === 0) {
      return "just now";
    }
    return date1.diff(date2, "minutes") + "m";
  }
}

export function urlB64ToUint8Array(base64String) {
  const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding)
    .replace(/\-/g, "+")
    .replace(/_/g, "/");

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}

// export function getDeviceId() {
//   return new Promise(function(resolve, reject) {
//     new Fingerprint2({ extendedJsFonts: true }).get(function(result) {
//       resolve(result);
//     });
//   });
// }

export function getUpdatedSubscriptions(subscriptions = [], subscription) {
  let extIndex = subscriptions.findIndex(sub => {
    return sub.deviceId === subscription.deviceId;
  });

  if (extIndex === -1) {
    if (subscription) {
      subscriptions.push(subscription);
      return subscriptions;
    }
  }
  if (subscription.subscription) {
    subscriptions[extIndex] = subscription;
  } else {
    subscriptions.splice(extIndex);
  }

  return subscriptions;
}

export const getStripeAmount = amount => (Math.round(amount * 100) / 100) * 100;
