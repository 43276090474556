import {
  storeAuthData,
  removeAuthData,
  removeFromStorage,
  storeDomainSelected,
  removeDomainSelcted,
  storeLoginState,
  removeLoginState,
  removeOpenForm,
  updateAuthData,
  removeSelectedConversation,
  removeLinkData,
  getSignupPath,
} from "../../Services/StorageService";
import history from "./../../Services/History";
// import Notify from "../../Components/ModalMessages/ToastNotif";
import AuthServiceClient from "../../Services/Clients/AuthServiceClient";
import UserServiceClient from "../../Services/Clients/UserServiceClient";
import ConversationServiceClient from "../../Services/Clients/ConversationServiceClient";
// import { fetchSubscribedChannels, fetchAllChannels } from "./channels";
import { getFavourite, getTimeLine } from "../actions/chats";
import axios from "axios";
// import { fetchContacts } from "./contacts";
// import {
//   fetchWalletBalance,
//   getPaginatedCallHistory
// } from "../../Services/VoipServices";

// import { getCallHistoryForSelectedContact } from "../../Services/VoipServices";
import { getProfilePhoto } from "../../Services/FilesService";

// import Toast from "../../Components/ModalMessages/Toast";
export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const USER_GEOLOCATION_DATA = "USER_GEOLOCATION_DATA";
export const USER_LOG_IN_ERROR = "USER_LOGG_IN_ERROR";
export const BOT_SUBSCRIPTIONS_RECEIVED = "BOT_SUBSCRIPTIONS_RECEIVED";
export const LOGOUT_USER = "LOGOUT_USER";
export const UPDATE_USER = "UPDATE_USER";
export const TERMS_AND_CONDITIONS_ACCEPTED = "TERMS_AND_CONDITIONS_ACCEPTED";
export const DOMAIN_RECEIVED = "DOMAIN_RECEIVED";
export const SELECTED_DOMAIN = "SELECTED_DOMAIN";
export const UPDATE_DOMAIN = "UPDATE_DOMAIN";
export const DOMAIN_SELECTED_IN = "DOMAIN_SELECTED_IN";
export const UPDATE_PHONE_BALANCE = "UPDATE_PHONE_BALANCE";
export const UPDATE_CALL_HISTORY = "UPDATE_CALL_HISTORY";
export const PROFILE_IMAGE = "PROFILE_IMAGE";
export const UPDATE_USER_AUTH_DATA = "UPDATE_USER_AUTH_DATA";
export const UPDATE_LOGIN_METHOD = "UPDATE_LOGIN_METHOD";
export const UPDATE_BANNER = "UPDATE_BANNER";
export const REMOVE_SELECTED_DOMAIN = "REMOVE_SELECTED_DOMAIN";
export const REMOVE_DOMAINS = "REMOVE_DOMAINS";
export const FORM_UNSAVED = "FORM_UNSAVED";
export const CLEAR_LOGIN_ERROR = "CLEAR_LOGIN_ERROR";
export const UPDATE_LOGGED_IN_USER_CALL_HISTORY =
  "UPDATE_LOGGED_IN_USER_CALL_HISTORY";
export const CLEAR_LOGGED_IN_USER_CALL_HISTORY =
  "CLEAR_LOGGED_IN_USER_CALL_HISTORY";
export const UPDATE_SELECTED_CONTACT_CALL_HISTORY =
  "UPDATE_SELECTED_CONTACT_CALL_HISTORY";
export const SET_REFRESH_ANONYMOUS_SESSION_FLAG =
  "SET_REFRESH_ANONYMOUS_SESSION_FLAG";
export const SHOW_ERROR_PAGE = "SHOW_ERROR_PAGE";

// export function doFrontMLogin(email, password, callback) {
//   return (dispatch, getState) => {
//     return AuthServiceClient.doFrontMLogin(email, password)
//       .then((response) => {
//         const { user, sessionId, newUser } = response;
//         dispatch(updateLoginMethod("frontm"));
//         dispatch(onSuccessfulAuth(user, sessionId, newUser, false));
//         getProfilePhoto(user.userId + ".png")
//           .then((file) => {
//             console.log("profile image ", file);
//           })
//           .catch((err) => {
//             console.log("no profile imgae", err);
//           });
//         dispatch(getAllDomains());
//         // dispatch(fetchWalletBalanceService());
//         callback(null);
//       })
//       .catch((error) => {
//         let message = error.message;
//         if (message && message.includes("confirmed")) {
//           callback(message);
//         } else {
//           callback(message);
//           dispatch({ type: USER_LOG_IN_ERROR, data: { message } });
//         }
//         // dispatch({ type: USER_LOG_IN_ERROR, data: { message: error.message } });
//       });
//   };
// }

export function getGeoLocationData() {
  const ipURL = "https://ipapi.co/json/";
  return (dispatch, getState) => {
    return axios
      .get(ipURL)
      .then((response) => {
        let data = response.data;

        let geoData = {
          country: data.country || "",
          country_calling_code: data.country_calling_code || "",
          country_name: data.country_name || "",
          currency: data.currency || "",
          languages: data.languages.split(",")[0] || "",
        };

        // console.log("**************", geoData);
        dispatch({ type: USER_GEOLOCATION_DATA, data: { ...geoData } });
      })
      .catch((error) => {
        console.log(error);
      });
  };
}

export function setSelectedDomain(data) {
  return (dispatch, getState) => {
    let selectedDomain = {
      selectedDomain: data.userDomain,
    };
    dispatch({
      type: SELECTED_DOMAIN,
      data: { selectedDomain: data },
    });
    dispatch(getTimeLine("domainChange"));
    dispatch(getFavourite(selectedDomain));
    // dispatch(fetchContacts(selectedDomain));
    dispatch(fetchBotSubscriptions(selectedDomain));
    // dispatch(fetchSubscribedChannels(selectedDomain));
    // dispatch(fetchAllChannels(selectedDomain));
  };
}

// export function updateLastLoggedInDomain(data) {
//   return (dispatch, getState) => {
//     return UserServiceClient.updateLastLoggedInDomain(data).then(response => {
//       console.log("updateLastLoggedInDomain", response);
//       dispatch({
//         type: LAST_SELECTED_DOMAIN,
//         data: {lastSelectedDomain}
//       })
//     });
//   };
// }

export function getAllDomains() {
  return (dispatch, getState) => {
    return UserServiceClient.getDomain().then((reponse) => {
      let isLastLogin = false;
      let lastLoginDomain = null;

      reponse.domains.forEach((elem) => {
        if (elem.lastLoggedIn) {
          isLastLogin = true;
          lastLoginDomain = elem;
        }
      });

      if (isLastLogin) {
        dispatch({
          type: SELECTED_DOMAIN,
          data: { selectedDomain: lastLoginDomain },
        });
      } else {
        dispatch({
          type: SELECTED_DOMAIN,
          data: { selectedDomain: reponse.domains[0] },
        });
      }
      dispatch(onFirstSelectDomain(lastLoginDomain));
      dispatch({
        type: DOMAIN_RECEIVED,
        data: { domains: reponse },
      });
    });
  };
}

export function setAllDomanins(data) {
  return (dispatch, getState) => {
    dispatch({
      type: DOMAIN_RECEIVED,
      data: { domains: data },
    });
  };
}

export function doAnonymousAuth(urlDomain, botId, callback) {
  // console.log("urlDomain, botId :: ", urlDomain, botId);
  return (dispatch, getState) => {
    return AuthServiceClient.createAnonymousAccess(urlDomain, botId)
      .then((response) => {
        // console.log("anonymous user created :: ", response);
        const { user, sessionId, newUser } = response;
        dispatch(onSuccessfulAuth(user, sessionId, newUser, true));
        callback && callback(null);
      })
      .catch((e) => {
        let message = e.message;
        console.log("doAnonymousAuth error :: ", message);
        dispatch(showErrorPage());
        // Toast({
        //   type: "error",
        //   message: message,
        //   autoClose: 4000
        // });
        callback && callback(message);
      });
  };
}

export function showErrorPage() {
  return {
    type: SHOW_ERROR_PAGE,
  };
}

export function refreshAnonymousUserSession(callback) {
  console.log("refresh anonymous user session");
  return (dispatch, getState) => {
    dispatch(setFlagForRefreshAnonymousSessionCall(true));
    return AuthServiceClient.refreshAnonymousUserSession()
      .then((response) => {
        console.log("anonymous user session refreshed ");
        updateAuthData();
        callback && callback(null);
      })
      .catch((error) => {
        let message = error.message;
        console.log(
          "error while anonymous user session refresh :: ",
          JSON.stringify(error.message)
        );
        // Toast({
        //   type: "error",
        //   message: message,
        //   autoClose: 4000
        // });
        callback && callback(message);
      })
      .finally(() => {
        dispatch(setFlagForRefreshAnonymousSessionCall(false));
      });
  };
}

export function updateAuthUser(data) {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_USER_AUTH_DATA,
      data: { newUser: data },
    });
  };
}
//
// export function updateUserSessionCreationTime() {
//   updateAuthData();
//   return {
//     type: UPDATE_USER_SESSION_CREATION_TIME,
//     data: dayjs()
//   };
// }

export function updateLoginMethod(data) {
  storeLoginState(data);
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_LOGIN_METHOD,
      data: {
        data,
      },
    });
  };
}

function onFirstSelectDomain(data) {
  storeDomainSelected(data);
  return {
    type: DOMAIN_SELECTED_IN,
    data: {
      selectedDomain: data,
    },
  };
}
export function onSelectDomain(data) {
  return {
    type: DOMAIN_SELECTED_IN,
    data: {
      selectedDomain: data,
    },
  };
}

export function setFlagForRefreshAnonymousSessionCall(status) {
  return {
    type: SET_REFRESH_ANONYMOUS_SESSION_FLAG,
    data: status,
  };
}

export function removeSelectedDomain() {
  return {
    type: REMOVE_SELECTED_DOMAIN,
  };
}
export function removeDomains() {
  return {
    type: REMOVE_DOMAINS,
  };
}

function onSuccessfulAuth(user, sessionId, newUser, isAnonymousUser) {
  storeAuthData({
    user,
    auth: {
      sessionId,
      newUser,
    },
    isAnonymousUser: isAnonymousUser,
  });

  return {
    type: USER_LOGGED_IN,
    data: {
      user,
      auth: {
        sessionId,
        newUser,
      },
      isAnonymousUser: isAnonymousUser,
    },
  };
}

export function hydrateUserData(data) {
  console.log("[hydrateUserData] data :: ", data);
  return {
    type: USER_LOGGED_IN,
    data: data,
  };
}

export function fetchBotSubscriptions(domain) {
  return (dispatch) => {
    UserServiceClient.fetchBotSubscriptions(domain).then((data) => {
      if (data.error != 0) {
        dispatch({
          type: BOT_SUBSCRIPTIONS_RECEIVED,
          data: { botSubscriptions: [] },
        });
        return;
      }
      const botIds =
        data.content && data.content.subscribed ? data.content.subscribed : [];

      return ConversationServiceClient.getCatalog({
        isWebRequest: true,
      }).then((response) => parseCatalogResponse(response, botIds, dispatch));
    });
  };
}

export function fetchCatalog() {
  return (dispatch) => {
    ConversationServiceClient.getCatalog({ isWebRequest: true }).then(
      (response) => {
        let subscribedBots = response.bots || [];
        // console.log("HERE ...... getCatalog response :: ", response);
        dispatch({
          type: BOT_SUBSCRIPTIONS_RECEIVED,
          data: { botSubscriptions: subscribedBots },
        });
      }
    );
  };
}

export function unsavedForm(bol) {
  return {
    type: FORM_UNSAVED,
    data: bol,
  };
}

function parseCatalogResponse(data, botIds, dispatch) {
  let bots = data.bots || [];
  const subscribedBots = bots.filter((bot) => botIds.includes(bot.botId));
  dispatch({
    type: BOT_SUBSCRIPTIONS_RECEIVED,
    data: { botSubscriptions: subscribedBots },
  });
}

export function logout() {
  let loginPath = getSignupPath();

  if (loginPath && loginPath.length) {
    history.push({
      pathname: loginPath,
    });
  } else {
    history.push("/login");
  }

  return (dispatch, getState) => {
    let checkFormUnsaved = getState().user.formUnsaved;
    if (!checkFormUnsaved) {
      window.document.title = "frontm.ai";
      clearTimeout(window.interval);
      removeAuthData();
      removeOpenForm();
      removeDomainSelcted();
      removeFromStorage();
      removeLoginState();
      removeLinkData();
      removeSelectedConversation();
      dispatch({ type: LOGOUT_USER });
      dispatch(removeSelectedDomain());
      dispatch(removeDomains());
    }
  };
}

export function updateUserProfile(data) {
  return {
    type: UPDATE_USER,
    data: { user: data },
  };
}
export function updateSelectedDomian(data) {
  return {
    type: UPDATE_DOMAIN,
    data: {
      selectedDomain: data,
    },
  };
}

export function closeBanner() {
  return {
    type: UPDATE_BANNER,
    data: {
      bannerType: null,
      message: null,
    },
  };
}

export function sendMsgForBanner(bannerType, msg) {
  console.log("send the to banner ", bannerType, msg);
  return {
    type: UPDATE_BANNER,
    data: {
      bannerType: bannerType,
      message: msg,
    },
  };
}

export function updateTnC(accepted) {
  return (dispatch) => {
    UserServiceClient.updateTnC(accepted).then((data) => {
      if (!data.error || data.error == 0) {
        dispatch({
          type: TERMS_AND_CONDITIONS_ACCEPTED,
          data: { accepted },
        });
      }
    });
  };
}

// export const fetchWalletBalanceService = () => dispatch => {
//   fetchWalletBalance()
//     .then(balance => {
//       dispatch(
//         setPhoneBalance({
//           balance
//         })
//       );
//     })
//     .catch(error => {
//       console.log("Cannot fetch wallet balance", error);
//     });
// };

// export const setCallHistory = () => dispatch => {
//   getCallHistory()
//     .then(callHistory => {
//       dispatch({
//         type: UPDATE_CALL_HISTORY,
//         payload: {
//           callHistory
//         }
//       });
//     })
//     .catch(error => {
//       console.log("Cannot fetch Call History");
//     });
// };

// export const setCallHistory = selectedContactId => dispatch => {
//   getCallHistoryForSelectedContact(selectedContactId).then(callHistory => {
//     dispatch({
//       type: UPDATE_SELECTED_CONTACT_CALL_HISTORY,
//       payload: {
//         callHistory,
//         selectedContactId
//       }
//     });
//   });
// };

// export const getLoggedInUserCallHistory = startTime => (dispatch, getState) => {
//   const timeStamp =
//     startTime ||
//     getState().user.timeStampOfLastRecordInExCallHistoryForLoggedInUser;
//   getPaginatedCallHistory(timeStamp)
//     .then(callHistory => {
//       dispatch({
//         type: UPDATE_LOGGED_IN_USER_CALL_HISTORY,
//         payload: {
//           records: callHistory.records,
//           moreRecordsExist: callHistory.moreRecordsExist
//         }
//       });
//     })
//     .catch(error => {
//       console.log("Cannot fetch logged in user Call History");
//     });
// };

export const clearLoginError = () => {
  return {
    type: CLEAR_LOGIN_ERROR,
    payload: "",
  };
};

export const clearLoggedInUserCallHistory = () => {
  return {
    type: CLEAR_LOGGED_IN_USER_CALL_HISTORY,
  };
};
export const setPhoneBalance = (payload) => ({
  type: UPDATE_PHONE_BALANCE,
  payload,
});
