import { agentguard } from "../gRPC/Generated/AgentGuardService";
import RPC from "./RPC";

class AgentGuardServiceClient {
  static execute(agInput) {
    return RPC.rpcCall(
      "/agentguard.AgentGuardService/Execute",
      agInput,
      agentguard.AgentGuardStringResponse,
      request => {
        // console.log("AgentGuardServiceClient ", request);
        return agentguard.AgentGuardInput.encode(request).finish();
      },
      agentguard.AgentGuardStringResponse.decode,
      {},
      true
    ).then(response => {
      let content = response.content;
      // console.log("sending message for contact card ", response, content);

      if (typeof content === "string") {
        response.content = JSON.parse(content);
      } else if (Array.isArray(content) && content[0]) {
        response.content = [JSON.parse(content[0])];
      }
      return response;
    });
  }
}

export default AgentGuardServiceClient;
