export default {
  envName: "development",
  baseURL: "https://elbdev.frontm.ai",
  filesAPI: "https://3nf11ibj25.execute-api.us-east-1.amazonaws.com/dev",
  contentURL: "https://devproxy.frontm.ai/botLogos/",
  soundURL: "https://devproxy.frontm.ai/sounds/",
  botFilesAPI: "https://devproxy.frontm.ai/dev/botfile?path=",
  gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
  paymentGatewayKey: "pk_test_tXlIyqK3p7LCtcF9JGwDgW0C",
  mapboxApiAccessToken:
    "pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
  gRPCURL: "https://grpcdev.frontm.ai:8080",

  webertcSignalServer: "commsdev.frontm.ai",
  mapURL: "https://devproxymaps.frontm.ai/styles/klokantech-basic/style.json",
  gnsDomain: "gnsTestBots",
  aageHempelDomain: "aagehempelEndUser",
  onshipDomain: "onshipEndUser",
};
