import React, { Component } from "react";
import ChatAvatar from "./ChatAvatar";
import ChatDateTimeUI from "./ChatDateTimeUI";
import { MessageTypeConstants } from "../../Services/Message";
import Avatar from "../Common/Avatar";

class ChatOthersUI extends Component {
  render() {
    let { chat, conversation, style, children } = this.props;

    // console.log(
    //   "we will see the object  =============================",
    //   this.props
    // );

    // console.log(typeof children);

    if (
      chat &&
      chat.messageType &&
      chat.messageType === MessageTypeConstants.MESSAGE_TYPE_CONTACT_CARD
    ) {
      if (typeof children === "object") {
        if (children.length > 0) {
          return children.map((elem, index) => {
            return (
              <div
                key={index}
                className="d-flex justify-content-start chat-message chat-others"
              >
                {/* <div className="mr-2">
                  <ChatAvatar conversation={conversation} chat={chat} />
                </div> */}
                <div className="d-flex flex-column">
                  <div
                    className="chat-others-message"
                    onClick={() => {
                      this.props.saveContactPopUp(elem, index);
                    }}
                    style={
                      (style,
                      {
                        display: "flex",
                        flexDirection: "row",
                        cursor: "pointer"
                      })
                    }
                  >
                    {/* <div>
            <Profile
          </div> */}
                    <Avatar
                      style={{ marginRight: "10px" }}
                      name={elem.userName}
                      size={30}
                      color="bg-info"
                    />
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column"
                      }}
                    >
                      <div>{elem.userName}</div>
                      <div>{elem.emailAddress}</div>
                    </div>
                  </div>
                  <div>
                    <ChatDateTimeUI chat={chat} />
                  </div>
                </div>
              </div>
            );
          });
        } else {
          return (
            <div className="d-flex justify-content-start chat-message chat-others">
              {/* <div className="mr-2">
                <ChatAvatar conversation={conversation} chat={chat} />
              </div> */}
              <div className="d-flex flex-column">
                <div
                  onClick={() => {
                    this.props.saveContactPopUp(children);
                  }}
                  className="chat-others-message"
                  style={
                    (style,
                    {
                      display: "flex",
                      flexDirection: "row",
                      cursor: "pointer"
                    })
                  }
                >
                  {/* <div>
            <Profile
          </div> */}
                  <Avatar
                    style={{ marginRight: "10px" }}
                    name={children.userName}
                    size={30}
                    color="bg-info"
                  />
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column"
                    }}
                  >
                    <div>{children.userName}</div>
                    <div>{children.emailAddress}</div>
                  </div>
                </div>
                <div>
                  <ChatDateTimeUI chat={chat} />
                </div>
              </div>
            </div>
          );
        }
      } else {
        return chat.message.map((elem, index) => {
          return (
            <div
              index={index}
              className="d-flex justify-content-start chat-message chat-others"
            >
              {/* <div className="mr-2">
                <ChatAvatar conversation={conversation} chat={chat} />
              </div> */}
              <div className="d-flex flex-column">
                <div
                  className="chat-others-message"
                  style={(style, { flexDirection: "row" })}
                >
                  {/* <div>
            <Profile
          </div> */}
                  <div>
                    <div>{elem.userName}</div>
                    <div>{elem.emailAddress}</div>
                  </div>
                </div>
                <div>
                  <ChatDateTimeUI chat={chat} />
                </div>
              </div>
            </div>
          );
        });
      }
    } else {
      if (typeof children === "string") {
        return (
          <div className="d-flex justify-content-start chat-message chat-others">
            {/* <div className="mr-2">
              <ChatAvatar conversation={conversation} chat={chat} />
            </div> */}
            <div className="d-flex flex-column">
              <div className="chat-others-message" style={style}>
                {children}
              </div>
              <div>
                <ChatDateTimeUI chat={chat} />
              </div>
            </div>
          </div>
        );
      } else if (typeof children === "object" && Array.isArray(children)) {
        /*This is to check the URL in the response of the bot message. */
        if (children.length > 0) {
          /*`insideDiv` keep all the html component inside as the `children` is array of objects which contain react html components*/
          let insideDiv = children.map(elem => {
            return elem;
          });

          return (
            <div className="d-flex justify-content-start chat-message chat-others">
              {/* <div className="mr-2">
                <ChatAvatar conversation={conversation} chat={chat} />
              </div> */}
              <div className="d-flex flex-column">
                <div className="chat-others-message" style={style}>
                  {insideDiv}
                </div>
                <div>
                  <ChatDateTimeUI chat={chat} />
                </div>
              </div>
            </div>
          );
        }
      } else if (typeof children === "object") {
        console.log("Webapp :: going to show bouncing wait message indicator");
        return (
          <div className="d-flex justify-content-start chat-message chat-others">
            {/* <div className="mr-2">
              <ChatAvatar conversation={conversation} chat={chat} />
            </div> */}
            <div className="d-flex flex-column">
              <div className="chat-others-message" style={style}>
                {children}
              </div>
              <div>
                <ChatDateTimeUI chat={chat} />
              </div>
            </div>
          </div>
        );
      } else {
        return null;
      }
    }

    // return (
    //   <div style={style}>
    //     <div className="media clearfix others-chat" style={{ margin: "0px" }}>
    //       <div className="d-flex mr-2">
    //         <span>
    //           <ChatAvatar conversation={conversation} chat={chat} />
    //         </span>
    //       </div>
    //       <div className="media-body">{children}</div>
    //     </div>
    //     <div className="media clearfix others-chat" style={{ margin: "0px" }}>
    //       <div className="d-flex mr-3">
    //         <span style={{ width: "24px" }} />
    //       </div>
    //       <div className="media-body fs10 text-muted">
    //         {getDateTimeString(chat.createdOn)}
    //       </div>
    //     </div>
    //   </div>
    // );
  }
}

export default ChatOthersUI;
