import React, { Component } from "react";
import HeaderNavBar from "../Components/HeaderNavBar";
import RouterComponent from "../Components/RouterComponent";
import { connect } from "react-redux";

class MainContainer extends Component {
  render() {
    const { font = null, borderColor = null } = this.props.widget;

    let mainStyle = {};
    if (font) {
      mainStyle = { ...mainStyle, ...{ font } };
    }
    if (borderColor) {
      mainStyle = { ...mainStyle, ...{ borderColor } };
    }

    return (
      <div className="Main-wrapper" style={mainStyle}>
        <HeaderNavBar />
        <RouterComponent />
      </div>
    );
  }
}

const mapDataToProps = (state, props) => {
  return {
    widget: state.widget.widget_customisations
  };
};

export default connect(mapDataToProps)(MainContainer);
