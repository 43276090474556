import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Scrollbars } from "react-custom-scrollbars";
import ChatMessage from "../ChatMessage";
import SmartReplies from "../content/SmartReplies";
import ChatOthersSpinner from "../chats/ChatOthersSpinner";

import { MessageTypeConstants } from "../../Services/Message";
import _ from "lodash";
import {
  storeOpenForm,
  removeOpenForm,
  getStoredForm,
  getLastMessageId,
  addLastMessageId,
} from "../../Services/StorageService";
import {
  setScrollPositionForConversation,
  routeToWindowComponent,
} from "../../State/actions/chats";
import history from "../../Services/History";
import { saveRoute } from "../../State/actions/routeAction";

function getHeight(searchBoxMessage) {
  if (!searchBoxMessage) {
    return "calc(100% - 90px)";
  }

  if (searchBoxMessage) {
    let message = searchBoxMessage.message;
    if (message && message.results) {
      if (message.results.length === 0) {
        return "calc(100% - 180px)";
      } else {
        return "calc(100%- 200px)";
      }
    } else {
      return "calc(100%- 120px)";
    }
  }
}

class ChatMessages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstForm: {},
      smartReplyMessage: null,
    };
    this.highLightedHeight = -1;
    this.chatScroll = React.createRef();
    this.state = {};
    this.fetchPaginatedArchiveMessages = _.debounce(
      this.fetchPaginatedArchiveMessages.bind(this),
      500
    );
  }

  shouldHighlightMessage(linkData, conversation) {
    if (!linkData) {
      return false;
    }

    let { type, botId, action } = linkData;

    if (action !== "read") {
      return false;
    }

    if (
      type === "b" &&
      (botId === conversation.bot || botId === conversation.bot.botId)
    ) {
      return true;
    }

    if (type === "c" && botId === conversation.conversationId) {
      return true;
    }
  }

  componentDidMount() {
    if (this.props.smartReplyMessage) {
      this.setState({ smartReplyMessage: { ...this.props.smartReplyMessage } });
    }
    if (this.props.shouldScrollToTop) {
      let height =
        this.highLightedHeight + 150 > 0 ? this.highLightedHeight + 150 : 0;
      this.chatScroll.current.scrollTop(150 + height);
    } else {
      // console.log("Manish [componentDidUpdate] setting chatScroll to bottom");
      this.chatScroll.current.scrollToBottom();
      let { linkData, conversation } = this.props;
      if (this.shouldHighlightMessage(linkData, conversation)) {
        let messageId = linkData.message;
        if (!messageId) {
          return;
        }

        let height =
          this.highLightedHeight - 150 < 0 ? 0 : this.highLightedHeight - 150;
        this.chatScroll.current.scrollTop(height);
      }
    }
  }

  componentDidUpdate() {
    // console.log("new smartReplyMessage", this.props.smartReplyMessage);
    if (
      !_.isEqual(this.props.smartReplyMessage, this.state.smartReplyMessage)
    ) {
      this.setState({ smartReplyMessage: this.props.smartReplyMessage });
    }

    if (this.props.shouldScrollToTop) {
      let height =
        this.highLightedHeight + 150 > 0 ? this.highLightedHeight + 150 : 0;
      this.chatScroll.current.scrollTop(150 + height);
    } else {
      this.chatScroll.current.scrollToBottom();
      let { linkData, conversation } = this.props;
      if (this.shouldHighlightMessage(linkData, conversation)) {
        let messageId = linkData.message;
        if (!messageId) {
          return;
        }

        let height =
          this.highLightedHeight - 150 < 0 ? 0 : this.highLightedHeight - 150;
        this.chatScroll.current.scrollTop(height);
      }
    }

    let messageArr = [...this.props.messages];
    let lastMessage = { ...messageArr[messageArr.length - 1] };
    if (
      lastMessage.messageType &&
      (lastMessage.messageType === MessageTypeConstants.MESSAGE_TYPE_FORM2 ||
        lastMessage.messageType === MessageTypeConstants.MESSAGE_TYPE_STRIPE ||
        lastMessage.messageType === MessageTypeConstants.MESSAGE_TYPE_TABLE)
    ) {
      let keyLast = lastMessage.messageId;
      let lastMsgObj = { ...getStoredForm() };
      lastMsgObj[keyLast] = lastMessage;

      // if (
      //   this.props.route.previousRoute === null &&
      //   this.props.route.currentRoute === "/chats"
      // ) {
      //   storeOpenForm(lastMsgObj);
      // }

      let lastMessageId = getLastMessageId();

      if (!lastMessageId || lastMessageId !== keyLast) {
        addLastMessageId(keyLast);
        storeOpenForm(lastMsgObj);
      }
    } else {
      removeOpenForm();
    }
  }

  componentWillUnmount() {
    removeOpenForm();
  }

  setHighlightedElementScrollHeight = (height) => {
    this.highLightedHeight = height;
  };

  renderView({ style = {}, ...props }) {
    const viewStyle = {
      position: "relative",
      overflowY: "scroll",
    };
    return (
      <div className="box" style={{ ...style, ...viewStyle }} {...props} />
    );
  }

  fetchPaginatedArchiveMessages = () => {
    const {
      hasMoreMessages,
      createdOn,
    } = this.props.conversationPaginationParameterMap;
    console.log("Sourav Logging:::Conversation Tyoe Check", this.props.chats);
    if (hasMoreMessages) {
      this.props.setScrollPositionForConversation(
        true,
        this.props.conversation
      );
      this.props.fetchArchivedMessages(this.props.conversation, createdOn);
    }
  };

  handleScroll = () => {
    // const currentTop = this.chatScroll.current.getScrollTop();
    const { top } = this.chatScroll.current.getValues();
    if (top === 0) {
      return this.fetchPaginatedArchiveMessages();
    } else if (top === 1) {
      this.props.setScrollPositionForConversation(
        false,
        this.props.conversation
      );
    }
  };

  openMap = (data) => {
    // console.log("opening map ", data);
    if (data.messageType === "map") {
      this.props.saveRoute("/map");
      this.props.routeToWindowComponent(data);
      history.push("/map");
    }
  };

  render() {
    let {
      background,
      conversation,
      messages,
      self,
      isContentShown,
      linkData,
      contentMessage,
      closeContent,
      // smartReplyMessage,
      sendMessage,
      closeSmartReply,
      showWaitSpinner,
      searchBoxMessage,
      saveContactPopUp,
    } = this.props;

    let { smartReplyMessage } = this.state;

    // console.log(
    //   "show chat smart reply",
    //   smartReplyMessage,
    //   this.props.smartReplyMessage
    // );
    // if (background) {
    //   messages = messages.filter(
    //     elem => elem.messageType === MessageTypeConstants.MESSAGE_TYPE_STRING
    //   );
    // }

    if (!messages) {
      return <div />;
    }

    let chatListClass = "chat-list";
    let customStyle = {
      flexDirection: "column-reverse",
      marginLeft: "20vw",
      marginRight: "20vw",
      width: "45vw",
    };
    if (isContentShown) {
      chatListClass += " chat-list-with-content";
      customStyle = { flexDirection: "column-reverse" };
    }

    // console.log("all the prosp ", messages);
    return (
      <Scrollbars
        className="message-scroll"
        autoHide
        ref={this.chatScroll}
        onScroll={() => this.handleScroll()}
        // onUpdate={(e) => {
        //   this.chatScroll.current.scrollToBottom();
        // }}
        style={{
          display: "flex",
          flexDirection: "column-reverse",
          width: "100%",
          position: "relative",
          margin: "0 auto",
          height: background ? "70vh" : getHeight(searchBoxMessage),
        }}
        renderView={this.renderView}
      >
        <div className="flex">
          <div className="p-3 mt-auto">
            <div
              className={chatListClass}
              style={
                background ? { flexDirection: "column-reverse" } : customStyle
              }
            >
              {messages.map((chat, index) => {
                let active = contentMessage
                  ? contentMessage.messageId === chat.messageId
                  : false;

                return (
                  <ChatMessage
                    background={background}
                    key={index}
                    conversation={conversation}
                    chat={chat}
                    // openContent={openContent}
                    openContent={() => this.openMap(chat)}
                    self={self}
                    highlight={linkData && chat.messageId === linkData.message}
                    setHighlightedElementScrollHeight={
                      this.setHighlightedElementScrollHeight
                    }
                    active={active}
                    closeContent={closeContent}
                    isContentShown={isContentShown}
                    saveContactPopUp={(elem, index) => {
                      saveContactPopUp(elem, index);
                    }}
                  />
                );
              })}
              {showWaitSpinner && (
                <ChatOthersSpinner conversation={conversation} />
              )}
            </div>
          </div>
        </div>
        {smartReplyMessage && (
          <SmartReplies
            smartReplies={smartReplyMessage}
            sendMessage={sendMessage}
            close={closeSmartReply}
            conversation={conversation}
          />
        )}
      </Scrollbars>
    );
  }
}

ChatMessages.propTypes = {
  messages: PropTypes.array.isRequired,
  height: PropTypes.string.isRequired,
  // conversation: PropTypes.object.isRequired,
  self: PropTypes.string.isRequired,
};

const mapActionToProps = {
  setScrollPositionForConversation,
  saveRoute: saveRoute,
  routeToWindowComponent: routeToWindowComponent,
};
const mapStateToProps = (state) => ({
  chats: state.chats,
  route: state.route,
});

export default connect(mapStateToProps, mapActionToProps)(ChatMessages);
