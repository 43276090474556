import ConversationServiceClient from "../../Services/Clients/ConversationServiceClient";
import _ from "lodash";
import ShortUUID from "short-uuid";
import {
  createIMConversation,
  createNewConversation,
  createTempConversation,
  getArchivedMessages,
  getContactAddIgnoreMessages,
  getConversationDetails,
  getOutgoingMessageRequest,
  sendMessage,
  startChannelConversation,
  toggleConversationFavourite,
  getUserDetails,
  getConversationByBotId,
  checkIfConversationExists,
  getConversationById,
  sortByModifiedOn,
  getPaginatedArchiveMessages
} from "../../Services/InteractionsService";
import {
  storeUserDetails,
  getStoredUserDetails,
  storeSelectedConversation,
  getSelectedConversation,
  removeOpenForm,
  removeStoreFormFromLocal,
  getStoredForm
} from "../../Services/StorageService";
import { FRONTM_BOT_ID, FRONTM_WEB_BOT, IM_BOT } from "../../Utils/Constants";
// import Notify from "../../Components/ModalMessages/ToastNotif";
import {
  IntToMessageTypeConstants,
  MessageTypeConstants
} from "../../Services/Message";
import { setPhoneBalance } from "./user";
// import { fetchContacts } from "./contacts";
import ServiceClientUtils from "../../Services/Clients/ServiceClientUtils";
import UserServiceClient from "../../Services/Clients/UserServiceClient";
import store from "../configureStore";
const R = require("ramda");
// import { sendMessageToSocket } from "../../Services/RTCService";
const dayjs = require("dayjs");
export const TIME_LINE_RECEIVED = "TIMELINE_RECEIVED";
export const ADD_NEW_CONVERSATION = "ADD_NEW_CONVERSATION";
export const NEW_SERVER_MESSAGE_RECEIVED = "NEW_SERVER_MESSAGE_RECEIVED";
export const NEW_SERVER_FORM_CLOSE_MESSAGE_RECEIVED =
  "NEW_SERVER_FORM_CLOSE_MESSAGE_RECEIVED";
export const NEW_CLIENT_MESSAGE_RECEIVED = "NEW_CLIENT_MESSAGE_RECEIVED";
export const CHANGE_SELECTED_CONVERSATION = "CHANGE_SELECTED_CONVERSATION";
export const ARCHIVED_MESSAGES_RECEIVED = "ARCHIVED_MESSAGES_RECEIVED";
export const CLOSE_CONTENT_AREA = "CLOSE_CONTENT_AREA";
export const OPEN_CONTENT_AREA = "OPEN_CONTENT_AREA";
export const CLOSE_SMART_REPLY = "CLOSE_SMART_REPLY";
export const NEW_LINK_RECEIVED = "NEW_LINK_RECEIVED";
export const RESET_LINK = "RESET_LINK";
export const TOGGLE__FAVORITE_CONVERSATION = "TOGGLE__FAVORITE_CONVERSATION";
export const HIDE_TIMELINE = "HIDE_TIMELINE";
export const SHOW_TIMELINE = "SHOW_TIMELINE";
export const SEND_SEARCH_BOX_QUERY = "SEND_SEARCH_BOX_QUERY";
export const CLOSE_SEARCH_BOX = "CLOSE_SEARCH_BOX";
export const SET_FORM_IN_POPUP = "SET_FORM_IN_POPUP";
export const SET_WINDOW = "SET_WINDOW";
export const NEW_CONTACT_ACCEPT_IGNORE_MESSAGE__RECEIVED =
  "NEW_CONTACT_ACCEPT_IGNORE_MESSAGE__RECEIVED";
export const CLEAR_ACCEPT_IGNORE_CONTACT = "CLEAR_ACCEPT_IGNORE_CONTACT";
export const GET_FAVOURITES = "GET_FAVOURITES";
export const CONVERSATION_SCROLL_POSITION = "CONVERSATION_SCROLL_POSITION";
export const REMOVE_FROM_WINDOW = "REMOVE_FROM_WINDOW";
export const SET_WINDOW_MIN_MAX = "SET_WINDOW_MIN_MAX";
export const SHOW_CHAT_NON_CONVERSATIONAL = "SHOW_CHAT_NON_CONVERSATIONAL";
export const TOGGLE_TOP_NAV_BAR = "TOGGLE_TOP_NAV_BAR";
export const TOGGLE_SIDE_NAV_BAR = "TOGGLE_SIDE_NAV_BAR";
export const REMOVE_FROM_SHOW_CARDS_ONLY = "REMOVE_FROM_SHOW_CARDS_ONLY";
export const REMOVE_HTML_CONTENT = "REMOVE_HTML_CONTENT";
export const REMOVE_CARDS_CONTENT = "REMOVE_CARDS_CONTENT";
export const REMOVE_FORM_IN_POPUP = "REMOVE_FORM_IN_POPUP";
export const CONTACT_ACCEPTED = "CONTACT_ACCEPTED";
export const RESET_MESSAGE_COUNT = "RESET_MESSAGE_COUNT";
export const SET_WINDOW_ROUTE = "SET_WINDOW_ROUTE";
export const REMOVE_WINDOW_ROUTE = "REMOVE_WINDOW_ROUTE";

function generateUUID() {
  let uuid = ShortUUID.uuid();
  return ShortUUID().fromUUID(uuid);
}

export function sendAMessage(responseMessage, hidden) {
  // sendMessageToSocket(responseMessage.message);
  console.log("all data for sending message ", responseMessage);

  return (dispatch, getState) => {
    let userId = getState().user.user.userId;
    let selectedConversation = getState().chats.selectedConversation;
    responseMessage.messageId = responseMessage.messageId || generateUUID();
    let {
      message,
      completed,
      messageId,
      options,
      messageType = MessageTypeConstants.MESSAGE_TYPE_STRING
    } = responseMessage;

    if (!hidden) {
      dispatch({
        type: NEW_CLIENT_MESSAGE_RECEIVED,
        data: {
          conversationId: selectedConversation.conversationId,
          message: {
            messageType: messageType,
            message,
            options: options,
            createdBy: userId,
            createdOn: Date.now(),
            completed: completed,
            messageId: messageId,
            self: true
          }
        }
      });
    }
    sendMessage(
      getOutgoingMessageRequest(
        selectedConversation,
        "sendMessage",
        responseMessage
      )
    );
  };
}

export function getFavourite(data) {
  // console.log("data fo fav ", data);

  return (dispatch, getState) => {
    return ConversationServiceClient.fetchFavourite(data).then(response => {
      // console.log("gettttttttttttttt", response);
      dispatch({
        type: GET_FAVOURITES,
        data: { getFavourites: response }
      });
    });
  };
}

export function getTimeLine(data = null) {
  return (dispatch, getState) => {
    // console.log("get all state", getState());
    return ConversationServiceClient.fetchTimeLine().then(response => {
      let timeLine = [];
      if (response) {
        let recents = response.content ? response.content.conversations : [];
        if (
          getState().selectedDomain &&
          getState().selectedDomain.viewModes &&
          !getState().selectedDomain.viewModes.chat
        ) {
          recents = recents.filter(elem => {
            return elem.bot.botId === "onboarding-bot";
          });
        }
        let favourites = response.content ? response.content.favourites : [];
        favourites.forEach(conversation => {
          conversation.favourite = true;
        });
        timeLine = [].concat(favourites).concat(recents);
        sortByModifiedOn(timeLine);
      }

      dispatch({
        type: TIME_LINE_RECEIVED,
        data: {
          timeLine: timeLine,
          userId: getState().user.user && getState().user.user.userId
        }
      });

      let linkData = getState().chats.linkData;
      if (!linkData) {
        let timeLineEmpty = !timeLine || timeLine.length === 0;
        if (
          timeLineEmpty &&
          getConversationByBotId(getState().chats, FRONTM_BOT_ID)
        ) {
          dispatch(addFrontMAssistantToTimeLine());
        } else if (!timeLineEmpty) {
          let conversation = getSelectedConversation();
          if (conversation) {
            dispatch(changeSelectedConversation(conversation, true));
            initiateConversation(conversation);
            dispatch(fetchArchivedMessages(conversation));
          }
        } else {
          if (!data || data !== "domainChange") {
            // console.log("get all state", getState());
            const fmTimeLine = getConversationByBotId(
              getState().chats,
              FRONTM_BOT_ID
            );
            console.log("changing conversation to frontm assistant ");
            if (!fmTimeLine) {
              dispatch(addFrontMAssistantToTimeLine());
            } else {
              dispatch(changeConversation(fmTimeLine));
            }
          } else {
            console.log(
              "resetting selected conversation and changing conversation on domain switch"
            );
            const fmTimeLine = getConversationByBotId(
              getState().chats,
              FRONTM_BOT_ID
            );
            dispatch(changeSelectedConversation(null, true));
            dispatch(
              setScrollPositionForConversation(false, fmTimeLine || timeLine[0])
            );
            dispatch(changeConversation(fmTimeLine || timeLine[0]));
          }
        }
      } else {
        // let timeLineEmpty = !timeLine || timeLine.length === 0;
        // if (
        //   timeLineEmpty ||
        //   !getConversationByBotId(getState().chats, FRONTM_BOT_ID)
        // ) {
        //   createNewConversation(getState().user.user.userId, {
        //     botId: FRONTM_BOT_ID,
        //     botName: "FrontM Web"
        //   }).then(conversation => {
        //     conversation.bot.logoUrl = "AuthenticationLogo.png";
        //     dispatch({ type: ADD_NEW_CONVERSATION, data: { conversation } });
        //     dispatch(initiateConversationForLink());
        //   });
        // } else {
        //   dispatch(initiateConversationForLink());
        // }
        dispatch(initiateConversationForLink());
      }
    });
  };
}

export function setScrollPositionForConversation(status, conversation) {
  return {
    type: CONVERSATION_SCROLL_POSITION,
    data: { status, conversation }
  };
}

function addFrontMAssistantToTimeLine() {
  return (dispatch, getState) => {
    createNewConversation(getState().user.user.userId, FRONTM_WEB_BOT).then(
      conversation => {
        dispatch({ type: ADD_NEW_CONVERSATION, data: { conversation } });
        dispatch(changeSelectedConversation(conversation, true));
        dispatch(setScrollPositionForConversation(false, conversation));
        if (conversation && !conversation.archivedMessagesRead) {
          dispatch(fetchArchivedMessages(conversation));
        }
      }
    );
  };
}

export function updateWindowTitle(conversation) {
  if (conversation && conversation.bot) {
    if (conversation.bot.botId === IM_BOT) {
      if (conversation.channel && conversation.channel.channelName) {
        window.document.title = conversation.channel.channelName;
      } else if (conversation.contact && conversation.contact.userName) {
        window.document.title = conversation.contact.userName;
      }
    } else {
      if (conversation.bot.botName) {
        window.document.title = conversation.bot.botName;
      }
    }
  }
}

export function getFrontMAssistant(timeLine) {
  return (dispatch, getState) => {
    let linkData = getState().chats.linkData;
    // console.log("getFronmAssintant ================ ", linkData);

    if (!linkData) {
      let timeLineEmpty = !timeLine || timeLine.length === 0;
      if (
        timeLineEmpty ||
        !getConversationByBotId(getState().chats, FRONTM_BOT_ID)
      ) {
        dispatch(addFrontMAssistantToTimeLine());
      } else {
        dispatch(changeConversation(timeLine[0]));
      }
    } else {
      dispatch(initiateConversationForLink());
    }
  };
}

export function changeConversation(conversation, moveToTop) {
  return (dispatch, getState) => {
    let chats = getState().chats;
    // console.log(
    //   "[changeConversation] chats.selectedConversation :: ",
    //   chats.selectedConversation,
    //   "  conversation.conversationId :: ",
    //   conversation.conversationId
    // );
    if (
      conversation &&
      chats.selectedConversation &&
      chats.selectedConversation.conversationId === conversation.conversationId
    ) {
      return;
    }
    dispatch(changeSelectedConversation(conversation, moveToTop));
    dispatch(setScrollPositionForConversation(false, conversation));
    //todo read archived message
    if (conversation && !conversation.archivedMessagesRead) {
      dispatch(fetchArchivedMessages(conversation));
    }

    initiateConversation(conversation);
  };
}

export function fetchArchivedMessages(conversation, createdOn) {
  return (dispatch, getState) => {
    getPaginatedArchiveMessages(conversation, createdOn).then(response => {
      const messages = response.messages;
      const moreMessagesExist = response.moreMessagesExist;
      // removeOpenForm();
      dispatch(
        onArchivedMessagesReceived(
          conversation.conversationId,
          messages,
          moreMessagesExist,
          getState().user.user.userId
        )
      );
    });
  };
}

function onArchivedMessagesReceived(
  conversationId,
  messages,
  moreMessagesExist,
  userId
) {
  return {
    type: ARCHIVED_MESSAGES_RECEIVED,
    data: {
      conversationId: conversationId,
      messages: messages,
      moreMessagesExist,
      userId
    }
  };
}

export function createConversation(bot, userId) {
  if (!bot || !userId) {
    return;
  }
  return (dispatch, getState) => {
    let { timeLine } = getState().chats;
    const isContact = !!bot.userId;
    if (isContact) {
      let conversationTimeLine = timeLine.filter(
        conversation =>
          conversation.contact && conversation.contact.userId === bot.userId
      );
      if (conversationTimeLine.length !== 0) {
        dispatch(changeSelectedConversation(conversationTimeLine[0], true));
        dispatch(
          setScrollPositionForConversation(false, conversationTimeLine[0])
        );
        if (
          conversationTimeLine &&
          !conversationTimeLine.archivedMessagesRead
        ) {
          dispatch(fetchArchivedMessages(conversationTimeLine[0]));
        }
        initiateConversation(conversationTimeLine[0]);
        return;
      }
      // for im. don't create a conversation in the backend until a message is sent.
      let conversation = createIMConversation(userId, bot);
      // not to fetch archived ones for the new conversation
      conversation.archivedMessagesRead = true;
      dispatch({ type: ADD_NEW_CONVERSATION, data: { conversation } });
      dispatch(changeSelectedConversation(conversation, true));
      dispatch(setScrollPositionForConversation(false, conversation));
      return;
    }

    const conversationTimeLine = timeLine.filter(
      conversation =>
        (conversation.bot && conversation.bot === bot.botId) ||
        (conversation.bot && conversation.bot.botId === bot.botId)
    );
    if (conversationTimeLine.length !== 0) {
      dispatch(changeSelectedConversation(conversationTimeLine[0], true));
      dispatch(
        setScrollPositionForConversation(false, conversationTimeLine[0])
      );
      if (conversationTimeLine && !conversationTimeLine.archivedMessagesRead) {
        dispatch(fetchArchivedMessages(conversationTimeLine[0]));
      }
      initiateConversation(conversationTimeLine[0]);
      return;
    }

    createNewConversation(userId, bot)
      .then(conversation => {
        dispatch({ type: ADD_NEW_CONVERSATION, data: { conversation } });
        dispatch(changeSelectedConversation(conversation, true));
        dispatch(setScrollPositionForConversation(false, conversation));
      })
      .catch(error => {
        console.log(error);
      });
  };
}

function parseMessages(rawMessage, chatsState) {
  let contents = ServiceClientUtils.parseBytesContent(rawMessage.details);

  delete rawMessage.details;
  return contents.map(content => {
    let newMessage = { ...rawMessage };
    content.messageType =
      content.messageType || IntToMessageTypeConstants[rawMessage.contentType];
    content.message = content.message || content.msg;
    delete content.msg;
    return Object.assign({}, newMessage, content);
  });
}

function getMessageSenderDetails(messageSenderId) {
  return new Promise((resolve, reject) => {
    let storedUser = getStoredUserDetails(messageSenderId);
    if (storedUser) {
      resolve(storedUser);
    } else {
      getUserDetails(messageSenderId).then(userDetails => {
        storeUserDetails(messageSenderId, userDetails);
        resolve(userDetails);
      });
    }
  });
}

export function updateWalletBalance(rawMessage) {
  return dispatch => {
    let parsed_message = parseMessages(rawMessage);
    if (parsed_message.length > 0) {
      let wallet_message = parsed_message[0];
      let wallet_bal = R.pathOr(
        null,
        ["message", "pstn-balance"],
        wallet_message
      );
      if (!wallet_bal) return;
      console.log("Sourav Logging:::: New Wallet Balance", wallet_bal);
      dispatch(
        setPhoneBalance({
          balance: wallet_bal
        })
      );
      return;
    }
  };
}

export function resetMessageCount(selectedContact) {
  return (dispatch, getState) => {
    const timeLine = getState().chats && getState().chats.timeLine;
    const selectedContactId = selectedContact.userId;
    let index = 0;
    for (let i = 0; i < timeLine.length; i++) {
      const conversation = timeLine[i];
      if (
        conversation.contact &&
        conversation.contact.userId === selectedContactId
      ) {
        index = i;
        break;
      }
    }
    let newTimeLine = [...timeLine];
    if (
      newTimeLine[index] &&
      newTimeLine[index].newMessagesCount !== undefined
    ) {
      newTimeLine[index].newMessagesCount = 0;
      dispatch({
        type: RESET_MESSAGE_COUNT,
        data: newTimeLine
      });
    }
  };
}

export function ingestMessage(rawMessage) {
  if (!rawMessage) {
    return;
  }

  let conversationId = rawMessage.conversation;
  let botId = rawMessage.bot;
  let messageSenderId = rawMessage.createdBy;

  return (dispatch, getState) => {
    let chatsState = getState().chats;
    let conversation = getConversationById(chatsState, conversationId);
    let promise = null;
    if (conversation) {
      promise = new Promise((resolve, reject) => {
        resolve(conversation);
      });
    } else {
      promise = getConversationDetails(
        conversationId,
        botId,
        messageSenderId
      ).then(conversationDetails => {
        // console.log("conversationData owner", conversationDetails);
        if (conversationDetails) {
          let conversation = createTempConversation(
            conversationDetails,
            conversationId,
            botId
          );
          dispatch({ type: ADD_NEW_CONVERSATION, data: { conversation } });
          return conversation;
        }
      });
    }

    promise.then(conversation => {
      let messages = parseMessages(rawMessage, chatsState);
      messages.forEach(message => {
        // console.log("checking the mesage received ", message);

        if (conversation && conversation.channel) {
          getMessageSenderDetails(messageSenderId).then(
            messageSenderDetails => {
              message.conversationOwner = messageSenderDetails;
              dispatch({
                type: NEW_SERVER_MESSAGE_RECEIVED,
                data: { conversationId, message }
              });
            }
          );
        } else if (botId === IM_BOT) {
          let participants = conversation && conversation.participants;
          let index = participants.findIndex(
            participant => participant.userId === messageSenderId
          );
          if (index !== -1) {
            message.conversationOwner = participants[index];
          }

          if (
            message.messageType ===
            MessageTypeConstants.MESSAGE_TYPE_ACCEPT_IGNORE_CONTACT
          ) {
            dispatch({
              type: NEW_CONTACT_ACCEPT_IGNORE_MESSAGE__RECEIVED,
              data: { conversationId, message }
            });

            let selectedDomain = {
              selectedDomain: getState().selectedDomain.userDomain
            };

            // dispatch(fetchContacts(selectedDomain));
          }
          if (
            message.messageType ===
            MessageTypeConstants.MESSAGE_TYPE_CONTACT_BEEN_ACCEPTED
          ) {
            console.log("Manish contact accepted :: ", message);
            dispatch({
              type: CONTACT_ACCEPTED,
              data: { conversationId, message }
            });
          }
          // else {
          dispatch({
            type: NEW_SERVER_MESSAGE_RECEIVED,
            data: { conversationId, message }
          });
          // }
        } else {
          if (
            message.messageType === MessageTypeConstants.MESSAGE_TYPE_CLOSE_FORM
          ) {
            dispatch({
              type: NEW_SERVER_FORM_CLOSE_MESSAGE_RECEIVED,
              data: { conversationId, message }
            });

            let newResponse = {};
            newResponse.message = {
              action: "cancel",
              formId: message.message.formId
            };
            newResponse.messageType =
              MessageTypeConstants.MESSAGE_TYPE_FORM_RESPONSE;
            dispatch(sendAMessage(newResponse, true));
          } else if (
            message.messageType ===
            MessageTypeConstants.MESSAGE_TYPE_CLOSE_CONTROL
          ) {
            const data = { options: { controlId: message.message.controlId } };
            const conversationModeMap = chatsState.conversationModeMap;
            let conversationalMode = conversationModeMap[conversationId];
            let conversational =
              (!_.isEmpty(conversationalMode) &&
                !conversationalMode.conversational) ||
              false;
            removeControlBasedOnConversationMode(
              data,
              conversationId,
              conversational
            );
          } else {
            dispatch({
              type: NEW_SERVER_MESSAGE_RECEIVED,
              data: { conversationId, message }
            });
          }
        }
      });
    });
  };
}

function removeControlBasedOnConversationMode(
  data,
  conversationId,
  conversational
) {
  let component = getStoredForm();
  if (
    component &&
    component.options &&
    component.options.controlId === data.options.controlId
  ) {
    removeOpenForm();
  }
  if (!conversational) {
    removeFromWindow(data);
  } else {
    removeFormInPopup(data);
  }
}

export function initiateChannelConversation(channel, callback) {
  return (dispatch, getState) => {
    const chatsState = getState().chats;
    let channelId = channel.channelId;
    if (checkIfConversationExists(chatsState, channelId)) {
      let conversation = getConversationById(chatsState, channelId);
      dispatch(changeSelectedConversation(conversation, true));
      dispatch(setScrollPositionForConversation(false, conversation));
      if (!conversation.archivedMessagesRead) {
        dispatch(fetchArchivedMessages(conversation));
      }
      let message = {
        messageType: "Runmode",
        message: {
          conversational: true,
          background: {}
        }
      };

      dispatch({
        type: NEW_SERVER_MESSAGE_RECEIVED,
        data: { conversationId: channelId, message }
      });

      callback(null);
    } else {
      startChannelConversation(getState().user.user.userId, channel).then(
        conversation => {
          dispatch({ type: ADD_NEW_CONVERSATION, data: { conversation } });
          dispatch(changeSelectedConversation(conversation, true));
          dispatch(setScrollPositionForConversation(false, conversation));
          dispatch(fetchArchivedMessages(conversation));
          let message = {
            messageType: "Runmode",
            message: {
              conversational: true,
              background: {}
            }
          };

          dispatch({
            type: NEW_SERVER_MESSAGE_RECEIVED,
            data: { conversationId: channelId, message }
          });
          callback(null);
        }
      );
    }
  };
}

function sendApproveContactMessage(
  conversationId,
  sender,
  createdBy,
  dispatch
) {
  let messages = getContactAddIgnoreMessages(sender);
  messages.forEach(message => {
    //todo
    // dispatch(processMessage(conversationId, message, createdBy, new Date()));
  });
}

function initiateConversation(conversation) {
  store.dispatch(removeCardCotent());
  sendMessage(
    getOutgoingMessageRequest(conversation, "startConversation", {
      message: "",
      messageType: MessageTypeConstants.MESSAGE_TYPE_STRING
    })
  );
}

export function changeSelectedConversation(conversation, moveToTop) {
  updateWindowTitle(conversation);
  storeSelectedConversation(conversation);
  return {
    type: CHANGE_SELECTED_CONVERSATION,
    data: { conversation: conversation, toTop: moveToTop }
  };
}

export function closeSmartReply() {
  return {
    type: CLOSE_SMART_REPLY
  };
}

export function closeContentArea() {
  return {
    type: CLOSE_CONTENT_AREA
  };
}

export function openContent(type, message) {
  let data = {};
  if (type === "content") {
    data.contentMessage = message;
  } else {
    data.smartReplyMessage = message;
  }

  return {
    type: OPEN_CONTENT_AREA,
    data: data
  };
}

export function populateLinkData(type, botId, message, action) {
  return {
    type: NEW_LINK_RECEIVED,
    data: {
      type,
      botId,
      message,
      action
    }
  };
}

export function resetLinkData(botId, message, action) {
  return {
    type: RESET_LINK
  };
}

export function initiateConversationForLink() {
  return (dispatch, getState) => {
    let linkData = getState().chats.linkData;
    let conversationalMode = R.pathOr(
      null,
      ["chats", "conversationModeMap"],
      getState()
    );
    let isConversational =
      conversationalMode && conversationalMode instanceof Map
        ? conversationalMode.get("conversational")
        : true;
    console.log("Sourav Logging::: Is Conversational??", isConversational);
    if (!linkData) {
      return;
    }

    let { type, botId, message, action } = linkData;
    // dispatch(resetLinkData());
    let conversation =
      type === "b"
        ? getConversationByBotId(getState().chats, botId)
        : getConversationById(getState().chats, botId);
    let botSubscriptions = getState().user.botSubscriptions;

    if (message && action === "send") {
      if (conversation) {
        dispatch(changeSelectedConversation(conversation, true));
        dispatch(setScrollPositionForConversation(false, conversation));
        if (!conversation.archivedMessagesRead) {
          dispatch(fetchArchivedMessages(conversation));
        }
        dispatch(
          sendAMessage({
            message,
            messageType: MessageTypeConstants.MESSAGE_TYPE_STRING
          })
        );
        return;
      }

      if (type === "b") {
        let botIndex = botSubscriptions.findIndex(bot => {
          return botId === bot.botId;
        });
        if (botIndex === -1 && botId !== FRONTM_BOT_ID) {
          // Notify({
          //   type: "error",
          //   message:
          //     "You have not subscribed to the mentioned bot yet. Please subscribe to the bot and open the link again.",
          //   autoClose: 10000
          // });
          return;
        }

        let bot =
          botId === FRONTM_BOT_ID ? FRONTM_WEB_BOT : botSubscriptions[botIndex];

        createNewConversation(
          getState().user.user.userId,
          bot,
          "sendMessage",
          message
        )
          .then(conversation => {
            if (botId === FRONTM_BOT_ID) {
              conversation.bot.logoUrl = "AuthenticationLogo.png";
            }

            dispatch({ type: ADD_NEW_CONVERSATION, data: { conversation } });
            dispatch(changeSelectedConversation(conversation, true));
            dispatch(setScrollPositionForConversation(false, conversation));
            dispatch({
              type: NEW_CLIENT_MESSAGE_RECEIVED,
              data: {
                conversationId: conversation.conversationId,
                message: {
                  type: "string",
                  message,
                  user: getState().user.user.userId,
                  createdOn: Date.now(),
                  time: dayjs().format("hh:mm a"),
                  createdBy: "self"
                }
              }
            });
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        // Notify({
        //   type: "error",
        //   message: "Mentioned conversation does not exist.",
        //   autoClose: 70000
        // });
      }
    } else if (message && action === "read") {
      if (!conversation) {
        // Notify({
        //   type: "error",
        //   message: "Mentioned conversation does not exist.",
        //   autoClose: 7000
        // });
        return;
      }
      dispatch(changeSelectedConversation(conversation, true));
      dispatch(setScrollPositionForConversation(false, conversation));
      if (!conversation.archivedMessagesRead) {
        dispatch(fetchArchivedMessages(conversation));
      }
    } else if (!message) {
      if (conversation) {
        dispatch(changeConversation(conversation, true));
        return;
      }
      if (type === "b") {
        let botIndex = botSubscriptions.findIndex(bot => {
          return botId === bot.botId;
        });
        if (botIndex === -1 && botId !== FRONTM_BOT_ID) {
          // Notify({
          //   type: "error",
          //   message:
          //     "You have not subscribed to the mentioned bot yet. Please subscribe to the bot and open the link again.",
          //   autoClose: 10000
          // });
          return;
        }

        let bot =
          botId === FRONTM_BOT_ID ? FRONTM_WEB_BOT : botSubscriptions[botIndex];

        createNewConversation(getState().user.user.userId, bot)
          .then(conversation => {
            if (botId === FRONTM_BOT_ID) {
              conversation.bot.logoUrl = "AuthenticationLogo.png";
            }
            dispatch({ type: ADD_NEW_CONVERSATION, data: { conversation } });
            dispatch(changeSelectedConversation(conversation, true));
            dispatch(setScrollPositionForConversation(false, conversation));
          })
          .catch(error => {
            console.log(error);
          });
      } else if (type && (type !== "b" || type !== "c")) {
        conversation = getSelectedConversation();
        if (!_.isEmpty(conversation)) {
          console.log(
            "Initiating existing Conversation from ls ::",
            conversation
          );
          dispatch(changeSelectedConversation(conversation, true));
          dispatch(setScrollPositionForConversation(false, conversation));
          initiateConversation(conversation);
          dispatch(fetchArchivedMessages(conversation));
        } else {
          console.log("conversation doesn't exist. Create new conversation ");
          createNewConversation(getState().user.user.userId, { botId: botId })
            .then(conversation => {
              // console.log("conversation created :: ", conversation);
              dispatch({ type: ADD_NEW_CONVERSATION, data: { conversation } });
              dispatch(changeSelectedConversation(conversation, true));
              dispatch(setScrollPositionForConversation(false, conversation));
            })
            .catch(error => {
              console.log("[initiateConversationForLink] error :: ", error);
            });
        }
      } else {
        // Notify({
        //   type: "error",
        //   message: "Mentioned conversation does not exist.",
        //   autoClose: 70000
        // });
      }
    }
  };
}

export function openPushConversation(conversationId) {
  return (dispatch, getState) => {
    let conversation = getConversationById(getState().chats, conversationId);
    if (conversation) {
      dispatch(changeSelectedConversation(conversation, true));
      dispatch(setScrollPositionForConversation(false, conversation));
    }
  };
}

export function addConversationToFavourites(conversationId, userDomain) {
  return (dispatch, getState) => {
    toggleConversationFavourite(conversationId, userDomain, "add")
      .then(response => {
        dispatch({
          type: TOGGLE__FAVORITE_CONVERSATION,
          data: { conversationId, favourite: true }
        });
        // Notify({
        //   type: "success",
        //   message: "Conversation has been marked as favourite."
        // });
      })
      .catch(error => {});
  };
}

export function removeConversationFromFavourites(conversationId, userDomain) {
  return (dispatch, getState) => {
    toggleConversationFavourite(conversationId, userDomain, "remove")
      .then(response => {
        dispatch({
          type: TOGGLE__FAVORITE_CONVERSATION,
          data: { conversationId, favourite: false }
        });
        // Notify({
        //   type: "success",
        //   message: "Conversation has been removed from the list of favourites."
        // });
      })
      .catch(error => {});
  };
}

export function hideTimeLine() {
  return {
    type: HIDE_TIMELINE
  };
}

export function showTimeLine() {
  return {
    type: SHOW_TIMELINE
  };
}

export function closeSearchBox(message) {
  if (message) {
    return (dispatch, getState) => {
      dispatch(sendAMessage(message, true));
      dispatch({ type: CLOSE_SEARCH_BOX });
    };
  }
  return { type: CLOSE_SEARCH_BOX };
}

export function sendSearchBoxQuery() {
  return { type: SEND_SEARCH_BOX_QUERY };
}

export function setFormInPopup(chat) {
  return { type: SET_FORM_IN_POPUP, data: { chat } };
}

export function removeFormInPopup(data) {
  return (dispatch, getState) => {
    let chatsObj = getState().chats;
    let formInPopup = chatsObj.formInPopup;
    if (
      formInPopup &&
      formInPopup.options.controlId === data.options.controlId
    ) {
      return { type: REMOVE_FORM_IN_POPUP };
    }
  };
}

export function setWindowMinMax(data, index) {
  return (dispatch, getState) => {
    let chatsObj = getState().chats;
    let dataInWindow = { ...chatsObj.componentInWindow };
    let windowKey =
      (getState().chats &&
        getState().chats.selectedConversation &&
        getState().chats.selectedConversation.conversationId) ||
      null;
    if (Object.keys(dataInWindow).length > 0) {
      dataInWindow[windowKey][index].minimize = data.minimize;
    }
    // console.log("data == ", dataInWindow);
    dispatch({ type: SET_WINDOW_MIN_MAX, data: { ...dataInWindow } });
  };
}

export function setFormInWindow(chat) {
  // console.log("data in window for the min", chat);

  if (!chat.options) {
    chat.options = { ...chat.message };
  }

  return (dispatch, getState) => {
    chat.minimize = false;
    let chatsObj = getState().chats;
    let dataInWindow = { ...chatsObj.componentInWindow };
    let windowKey =
      (getState().chats &&
        getState().chats.selectedConversation &&
        getState().chats.selectedConversation.conversationId) ||
      null;

    if (!windowKey) {
      return;
    }
    let windowArr = [];

    if (Object.keys(dataInWindow).length === 0) {
      windowArr.push(chat);
      dataInWindow[windowKey] = windowArr;
    } else {
      if (!dataInWindow[windowKey]) {
        windowArr.push(chat);
        dataInWindow[windowKey] = windowArr;
      } else {
        windowArr = [...dataInWindow[windowKey]];

        if (windowArr.length === 0) {
          windowArr.push(chat);
        } else {
          let getIndex = windowArr.findIndex(
            elem => elem.options.controlId === chat.options.controlId
          );
          if (getIndex <= -1) {
            windowArr.push(chat);
          } else {
            if (
              chat.options.stage === "COMPLETED" &&
              chat.options.minimizeOnConfirm
            ) {
              chat.minimize = true;
            } else {
              removeStoreFormFromLocal();
            }
            windowArr[getIndex] = chat;
          }
        }
        dataInWindow[windowKey] = windowArr;
      }
    }

    dispatch({
      type: SET_WINDOW,
      data: dataInWindow
    });
  };
}

export function routeToWindowComponent(data) {
  // console.log(data);
  if (!data.options) {
    data.options = { ...data.message };
  }

  return (dispatch, getState) => {
    let dataInWindow = { ...data };
    dispatch({
      type: SET_WINDOW_ROUTE,
      data: dataInWindow
    });
  };
}

export function removeRouteFromWindowComponent() {
  return {
    type: REMOVE_WINDOW_ROUTE
  };
}

export function removeFromWindow(data) {
  return (dispatch, getState) => {
    let windowKey =
      (getState().chats &&
        getState().chats.selectedConversation &&
        getState().chats.selectedConversation.conversationId) ||
      null;
    let getWindowObj = { ...getState().chats.componentInWindow };
    let arr =
      getWindowObj && getWindowObj[windowKey]
        ? [...getWindowObj[windowKey]]
        : [];
    if (!data.options.controlId) {
      return;
    }
    let windowIdToRemove = data.options.controlId;

    let indexToRemove = null;
    if (arr && arr.length > 0) {
      arr.forEach((elem, index) => {
        if (
          elem.options.controlId &&
          windowIdToRemove === elem.options.controlId
        ) {
          indexToRemove = index;
        }
      });
    }
    if (indexToRemove !== null && indexToRemove > -1) {
      getWindowObj[windowKey].splice(indexToRemove, 1);
    }
    console.log("data to remove ", getWindowObj);
    dispatch({
      type: REMOVE_FROM_WINDOW,
      data: { ...getWindowObj }
    });
  };
}

export function resetSelectedConversationFormInWindow(data, updatedObj) {
  // console.log("Manish resetSelectedConversationFormInWindow");
  return (dispatch, getState) => {
    let windowKey =
      (getState().chats &&
        getState().chats.selectedConversation &&
        getState().chats.selectedConversation.conversationId) ||
      null;
    if (!windowKey) {
      return;
    }
    let windowObj = { ...getState().chats.componentInWindow };
    let arr =
      windowObj && windowObj[windowKey] ? [...windowObj[windowKey]] : [];
    if (!data.options.controlId) {
      return;
    }
    let windowIdToReset = data.options.controlId;
    let indexToReset = null;
    if (arr && arr.length > 0) {
      arr.forEach((elem, index) => {
        if (
          elem.options.controlId &&
          windowIdToReset === elem.options.controlId
        ) {
          indexToReset = index;
        }
      });
    }
    if (indexToReset !== null && indexToReset > -1) {
      let windowObjectToRest = windowObj[windowKey][indexToReset];
      Object.keys(updatedObj).forEach(fieldName => {
        windowObjectToRest[fieldName] = updatedObj[fieldName];
      });
      // windowObjectToRest[fieldName] = updatedContent;
      windowObj[windowKey][indexToReset] = windowObjectToRest;
    }
    dispatch({
      type: REMOVE_FROM_WINDOW,
      data: { ...windowObj }
    });
  };
}

export function clearAcceptIgnoreButtons() {
  return { type: CLEAR_ACCEPT_IGNORE_CONTACT };
}

export function showChatNonConversational(status) {
  return {
    type: SHOW_CHAT_NON_CONVERSATIONAL,
    data: status
  };
}

export function toggleTopNavBar() {
  return {
    type: TOGGLE_TOP_NAV_BAR
  };
}

export function toggleSideNavBar() {
  return {
    type: TOGGLE_SIDE_NAV_BAR
  };
}

export function removeCradsFromShowOnlyCards(data) {
  return (dispatch, getState) => {
    let cardArr = [...getState().chats.showOnlyCards];
    let getInd = cardArr.findIndex(elem => data.cardId === elem.cardId);
    if (getInd > -1) {
      cardArr.splice(getInd, 1);
    }

    dispatch({ type: REMOVE_FROM_SHOW_CARDS_ONLY, data: cardArr });
  };
}

export function removeHTMLCotent() {
  return {
    type: REMOVE_HTML_CONTENT
  };
}
export function removeCardCotent() {
  return {
    type: REMOVE_CARDS_CONTENT
  };
}
