import React, { Component } from "react";
import { connect } from "react-redux";
import {
  hydrateUserData,
  doAnonymousAuth,
  refreshAnonymousUserSession,
} from "../../State/actions/user";
import { populateLinkData } from "../../State/actions/chats";
import { setWidgetCustomisations } from "../../State/actions/widgetActions";
import { saveRoute } from "../../State/actions/routeAction";
import dayjs from "dayjs";
import _ from "lodash";
import {
  getAuthData,
  removeAuthData,
  removeLinkData,
  removeSelectedConversation,
} from "../../Services/StorageService";
import history from "../../Services/History";
import WidgetNotification from "../WidgetNotification";

const TWO_WEEK = 60 * 5; //60 * 60 * 24 * 14; 5 min
const ONE_HOUR = 60 * 2;
class ChatIcon extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAnonymousUser: null,
      showNotification: true,
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.isAnonymousUser !== this.state.isAnonymousUser) {
      this.setState({ isAnonymousUser: nextProps.isAnonymousUser });
    }
  }

  componentDidMount() {
    window.addEventListener("message", this.handleIframeTask);
  }
  clickOnChatToggle = () => {
    // console.log("toogle chat");
    this.setState({ showNotification: false });
    this.props.toggleChat();
    this.props.saveRoute("/chats");
    history.push("/chats");
  };

  handleIframeTask = (e) => {
    let param = e.data;
    // console.log("Sourav Logging:::: MY PARAMS", param);
    if (param instanceof Object) {
      return;
    }
    if (param) {
      let paramData = JSON.parse(param);
      if (paramData.customisations) {
        console.log(
          "We got customisation data for UI",
          paramData.customisations
        );
        this.props.setWidgetCustomisations(paramData.customisations);
      }
      this.setState({ params: { ...paramData } });
      // if (this.props.match && this.props.match.url) {
      // }
      this.anonymousUserFlow();
    }
  };

  anonymousUserFlow = () => {
    let { params } = this.state;
    let user = getAuthData();
    console.log("get user data ", params);

    if (user && user.isAnonymousUser) {
      let storedAt = user.stored_at;
      let currentTime = dayjs();
      const diff = currentTime.diff(dayjs(storedAt), "second");
      if (diff > TWO_WEEK) {
        // this.processAnonymousUserCreation("frontmai", "onboarding-bot");
        this.processAnonymousUserCreation(params.type, params.botId);
      } else if (diff > ONE_HOUR) {
        console.log("link older than one hour");
        this.props.hydrateUserData(user);
        this.props.refreshAnonymousUserSession((error, data) => {
          if (!error) {
            this.processAnonymousUserLink();
          }
        });
      } else {
        this.props.hydrateUserData(user);
        this.processAnonymousUserLink();
      }
    } else {
      this.processAnonymousUserCreation(params.type, params.botId);
      // this.processAnonymousUserCreation(urlDomain, botId);
    }
  };

  processAnonymousUserCreation = (type, botId) => {
    removeAuthData();
    removeSelectedConversation();
    removeLinkData();
    this.props.doAnonymousAuth(type, botId, (error, data) => {
      // console.log("anonymous user ", type, botId, error);
      if (!error) {
        this.processAnonymousUserLink(type, botId);
      } else {
        console.log("ERROR doAnonymousAuth callback ");
      }
    });
  };

  chatIconStyle = () => {
    let { params } = this.state;
    if (
      params &&
      params.customisations &&
      params.customisations.chatIconCustomisation
    ) {
      let { customisations } = params;
      return {
        position: "absolute",
        backgroundColor: "#00bdf2",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        bottom: customisations.chatIconCustomisation.bottom || "0px",
        right: customisations.chatIconCustomisation.right || "10px",
        borderRadius:
          customisations.chatIconCustomisation.borderRadius || "50%",
        width: customisations.chatIconCustomisation.width,
        height: customisations.chatIconCustomisation.height,
      };
    } else {
      return {
        position: "absolute",
        bottom: "0px",
        right: "10px",
        backgroundColor: "#00bdf2",
        width: "45px",
        height: "45px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        cursor: "pointer",
      };
    }
  };

  processAnonymousUserLink = (type = "frontmai", botId = "onboarding-bot") => {
    let { populateLinkData } = this.props;
    let { params } = this.state;
    console.log("Manish processAnonymousUserLink params :: ", params);
    populateLinkData(params.type, params.botId);
  };
  render() {
    let { isAnonymousUser, showNotification, params } = this.state;
    let {
      chatIconPath = "/img/map-chat-icon@3x.png",
      chatIconClosePath = "/img/map-chat-close-icon@2x.png",
      chatIconPosition = null,
    } = this.props.widget;

    let chatIconStyle = chatIconPosition ? chatIconPosition : {};

    // console.log("CHAT ICON PATH", chatIconPath);

    return (
      <div
        className="chat-icon"
        style={this.chatIconStyle()}
        onClick={() => this.clickOnChatToggle()}
      >
        {this.props.forIcon ? (
          <img
            style={{ width: "15px", height: "15px" }}
            src={chatIconClosePath}
            alt="close-icon"
          />
        ) : (
          <img src={chatIconPath} alt="open chat icon" />
        )}
        {isAnonymousUser && showNotification && (
          <div>
            <WidgetNotification isAnonymousUser={isAnonymousUser} />
          </div>
        )}
      </div>
    );
  }
}

const mapActionsToProps = {
  hydrateUserData: hydrateUserData,
  populateLinkData: populateLinkData,
  setWidgetCustomisations: setWidgetCustomisations,
  doAnonymousAuth: doAnonymousAuth,
  refreshAnonymousUserSession: refreshAnonymousUserSession,
  saveRoute: saveRoute,
};

const mapDataToProps = (state) => {
  return {
    widget: state.widget.widget_customisations,
    user: (state.user && state.user.user) || null,
    isAnonymousUser: state.user.isAnonymousUser || false,
  };
};
export default connect(mapDataToProps, mapActionsToProps)(ChatIcon);
