import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import HeaderNavBar from "../HeaderNavBar";
import ChatInputBox from "../ChatInputBox";
import {
  sendAMessage,
  closeSmartReply,
  openContent,
  closeSearchBox,
  sendSearchBoxQuery,
  setScrollPositionForConversation,
  fetchArchivedMessages,
  setFormInWindow,
  showChatNonConversational,
  removeCradsFromShowOnlyCards,
  removeHTMLCotent,
  ingestMessage,
  initiateConversationForLink
} from "../../State/actions/chats";
import { refreshAnonymousUserSession } from "../../State/actions/user";
import ChatMessages from "../ChatMessages";
const HALF_HOUR = 1 * 60 * 1000; // 30 * 60 * 1000; 1min

class BotMesseneger extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messages: [],
      smartReplyMessage: null
    };
  }
  componentDidMount() {
    // console.log(
    //   "BotMesseneger Going to refresh session === ",
    //   this.props.messages
    // );
    if (this.props.smartReplyMessage) {
      this.setState({
        smartReplyMessage: { ...this.props.smartReplyMessage }
      });
    }
    if (this.props.messages && this.props.messages.length <= 0) {
      setInterval(() => {
        this.props.isAnonymousUser &&
          !this.props.isRefreshAnonymousSessionBeingCalled &&
          this.props.refreshAnonymousUserSession();
      }, HALF_HOUR);
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    // console.log("new message ", nextProps.smartReplyMessage);

    if (nextProps.messages.length !== prevState.messages.length) {
      let tempMessages = [...nextProps.messages];

      return {
        messages: [...tempMessages]
      };
    }

    if (!_.isEqual(nextProps.smartReplyMessage, prevState.smartReplyMessage)) {
      return {
        smartReplyMessage: nextProps.smartReplyMessage
      };
    }

    return null;
  }

  render() {
    const {
      userId,
      isAnonymousUser,
      background,
      selectedConversation,
      sendAMessage,
      conversationPaginationParameterMap,
      shouldScrollToTop,
      mainChatHeight,
      contentMessage,
      openContent,
      closeContent,
      // smartReplyMessage,
      showWaitSpinner,
      searchBoxMessage,
      fetchArchivedMessages
    } = this.props;

    let { messages, smartReplyMessage } = this.state;

    // console.log(
    //   "we will see the message we are sending ",
    //   smartReplyMessage,
    //   this.props.smartReplyMessage
    // );

    const { backgroundImage = null } = this.props.widget;
    let customStyle = {};
    if (backgroundImage) {
      customStyle = {
        ...customStyle,
        ...{ backgroundImage: `url(${backgroundImage})`, objectFit: "cover" }
      };
    }
    return (
      <div className="bot-messenger" style={customStyle}>
        <ChatMessages
          background={background}
          messages={messages}
          conversationPaginationParameterMap={
            conversationPaginationParameterMap
          }
          shouldScrollToTop={shouldScrollToTop}
          height={mainChatHeight}
          conversation={selectedConversation}
          self={userId}
          isContentShown={
            !!contentMessage //leave
          }
          openContent={
            openContent //leave
          }
          contentMessage={contentMessage}
          closeContent={closeContent}
          smartReplyMessage={
            smartReplyMessage //leave
          }
          sendMessage={sendAMessage}
          closeSmartReply={closeSmartReply}
          showWaitSpinner={showWaitSpinner}
          searchBoxMessage={searchBoxMessage}
          fetchArchivedMessages={fetchArchivedMessages}
        />
        <div className="sidebar-body-footer d-flex align-items-center">
          <div className="input-group">
            {/* {!searchBoxMessage && ( */}
            <ChatInputBox
              isAnonymousUser={isAnonymousUser}
              background={background}
              // isContentShown={!!contentMessage}
              // contacts={contacts}
              conversation={selectedConversation}
              sendMessage={sendAMessage}
              // disable={disableMessageInput}
            />
            {/* )} */}
            {/* {searchBoxMessage && (
              <ChatSearchBox
                background={background}
                isContentShown={!!contentMessage}
                searchBoxMessage={searchBoxMessage}
                conversation={selectedConversation}
                sendMessage={sendAMessage}
                disable={disableMessageInput}
                closeSearchBox={closeSearchBox}
                sendSearchBoxQuery={sendSearchBoxQuery}
              />
            )} */}
          </div>
        </div>
      </div>
    );
  }
}

const mapActionToProps = {
  sendAMessage: sendAMessage,
  closeSmartReply: closeSmartReply,
  openContent: openContent,
  closeSearchBox: closeSearchBox,
  fetchArchivedMessages,
  sendSearchBoxQuery: sendSearchBoxQuery,
  setScrollPositionForConversation,
  setFormInWindow,
  showChatNonConversational,
  removeCradsFromShowOnlyCards: removeCradsFromShowOnlyCards,
  removeHTMLCotent: removeHTMLCotent,
  initiateConversationForLink,
  ingestMessage,
  refreshAnonymousUserSession
};

const mapDataToProps = (state, props) => {
  let chats = state.chats;
  let conversation = chats.selectedConversation;
  let selectedConversationId = conversation && conversation.conversationId;
  // console.log("state :: ", chats.chatLog);
  return {
    widget: state.widget.widget_customisations,
    userId: state.user.user.userId,
    isAnonymousUser: state.user.isAnonymousUser || false,
    selectedConversation: conversation,
    messages: conversation
      ? chats.chatLog.get(selectedConversationId) || []
      : [],
    conversationPaginationParameterMap: conversation
      ? state.chats.conversationPaginationParameterMap[
          conversation.conversationId
        ] || {}
      : {},
    shouldScrollToTop: conversation
      ? state.chats.shouldScrollToTopConversationMap[
          conversation.conversationId
        ] || false
      : false,
    contentMessage: chats.contentMessage,
    smartReplyMessage: chats.smartReplyMessage || null,
    disableMessageInput: chats.disableMessageInput,
    mainChatHeight: chats.mainChatHeight,
    linkData: chats.linkData,
    hiddenTimeLine: state.chats.hiddenTimeLine,
    showWaitSpinner: state.chats.showWaitSpinner,
    searchBoxMessage: state.chats.searchBoxMessage,
    notificationTypes: state.appNotification.notificationTypes,
    notificationShow: state.appNotification && state.appNotification.show,
    offlineBannerVisibility: state.offlineBanner.visibility,
    componentInWindow: chats.componentInWindow,
    conversational: selectedConversationId
      ? chats.conversationModeMap[selectedConversationId]
        ? chats.conversationModeMap[selectedConversationId].conversational ===
          false
          ? false
          : true
        : true
      : true,
    background: selectedConversationId
      ? (chats.conversationModeMap[selectedConversationId] &&
          chats.conversationModeMap[selectedConversationId].background) ||
        {}
      : {}
  };
};

export default connect(mapDataToProps, mapActionToProps)(BotMesseneger);
