// import { user, commonmessages } from "../gRPC/Generated/UserService";

import UserService from "../gRPC/Generated/UserService";
import RPC from "./RPC";
const user = UserService.user;
const commonmessages = UserService.commonmessages;

class UserServiceClient {
  static fetchUserDetails(reqInfo) {
    console.log("fetching request ", reqInfo);

    return RPC.rpcCall(
      "/user.UserService/GetUserDetails",
      reqInfo,
      user.User,
      request => {
        return user.User.encode(request).finish();
      },
      user.User.decode,
      {},
      true
    );
  }

  static updateUserProfile(userInfo) {
    console.log("udate user ", user.User);

    return RPC.rpcCall(
      "/user.UserService/UpdateUserProfile",
      userInfo,
      user.UpdateUserProfileResponse,
      request => {
        console.log("let see the request", request);

        return user.User.encode(request).finish();
      },
      user.UpdateUserProfileResponse.decode
    );
  }

  static getContacts(data) {
    return RPC.rpcCall(
      "/user.UserService/GetContacts",
      data,
      user.ContactsResponse,
      request => {
        return commonmessages.SelectedDomainInput.encode(request).finish();
      },
      user.ContactsResponse.decode
    );
  }

  static getContactHistory(data) {
    return RPC.rpcCall(
      "/user.UserService/GetCallHistoryForContact",
      data,
      user.CallHistoryResponse,
      request => {
        return user.CallHistoryInput.encode(request).finish();
      },
      user.CallHistoryResponse.decode
    );
  }

  static fetchBotSubscriptions(data) {
    return RPC.rpcCall(
      "/user.UserService/GetBotSubscriptions",
      data,
      user.BotSubscriptionsResponse,
      request => {
        return commonmessages.SelectedDomainInput.encode(request).finish();
      },
      user.BotSubscriptionsResponse.decode
    );
  }

  static subscribeBot(req) {
    console.log("subscribe req ", req);

    return RPC.rpcCall(
      "/user.UserService/SubscribeBot",
      req,
      user.SubscribeBotResponse,
      request => {
        return user.SubscribeBotInput.encode(request).finish();
      },
      user.SubscribeBotResponse.decode
    );
  }

  //todo change it to new method for web or pass appropriate params.
  static generateTwilioToken() {
    return RPC.rpcCall(
      "/user.UserService/GenerateWebTwilioToken",
      {},
      user.TwilioTokenResponse,
      request => {
        return user.TwilioTokenInput.encode(request).finish();
      },
      user.TwilioTokenResponse.decode
    );
  }

  static subscribeDomain(req) {
    return RPC.rpcCall(
      "/user.UserService/SubscribeDomain",
      req,
      user.SubscribeDomainResponse,
      request => {
        return user.SubscribeDomainInput.encode(request).finish();
      },
      user.SubscribeDomainResponse.decode
    );
  }

  static updateTnC(accepted) {
    return RPC.rpcCall(
      "/user.UserService/ManageTnc",
      { action: accepted ? "accept" : "reject" },
      user.ManageTncResponse,
      request => {
        return user.ManageTncInput.encode(request).finish();
      },
      user.ManageTncResponse.decode
    );
  }

  static getDomain() {
    return RPC.rpcCall(
      "/user.UserService/GetUserDomains",
      {},
      user.UserDomainsResponse,
      request => {
        return commonmessages.Empty.encode(request).finish();
      },
      user.UserDomainsResponse.decode
    );
  }

  static updateLastLoggedInDomain(data) {
    return RPC.rpcCall(
      "/user.UserService/UpdateLastLoggedInDomain",
      data,
      commonmessages.Empty,
      request => {
        return user.LastLoggedInDomainInput.encode(request).finish();
      },
      commonmessages.Empty.decode
    );
  }

  static getCallHistory() {
    return RPC.rpcCall(
      "/user.UserService/GetCallHistory",
      {},
      user.CallHistoryResponse,
      request => {
        return commonmessages.Empty.encode(request).finish();
      },
      user.CallHistoryResponse.decode
    );
  }

  static getPaginatedCallHistory(startTime) {
    let data = { startTime: startTime };
    return RPC.rpcCall(
      "/user.UserService/GetPaginatedCallHistory",
      data,
      user.PaginatedCallHistoryResponse,
      request => {
        return user.PaginatedCallHistoryInput.encode(request).finish();
      },
      user.PaginatedCallHistoryResponse.decode
    );
  }
}

export default UserServiceClient;
