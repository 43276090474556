import React from "react";
import ChatDateTimeUI from "./ChatDateTimeUI";
import { MessageTypeConstants } from "../../Services/Message";
import Avatar from "../Common/Avatar";
import ReactEmoji from "react-emoji";

const ChatSelfUI = ({ chat, style, children }) => {
  // console.log("type of messsage ", children, chat);
  if (chat.messageType === MessageTypeConstants.MESSAGE_TYPE_CONTACT_CARD) {
    return children.map((elem, index) => {
      return (
        <div
          key={index}
          className="d-flex flex-column justify-content-end align-items-end chat-message chat-self"
        >
          <div
            className="chat-self-message"
            style={(style, { display: "flex", flexDirection: "row" })}
          >
            {/* <div>
            <Profile
          </div> */}
            {/* <div>
              <span>Contact Details</span>
              <hr className="hr-mb-0 hr-mt-0" />
            </div> */}
            <Avatar
              style={{ marginRight: "10px" }}
              name={elem.userName}
              size={30}
              color="bg-info"
            />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>{elem.userName}</div>
              <div>{elem.emailAddress}</div>
            </div>
          </div>
          <div>
            <ChatDateTimeUI chat={chat} />
          </div>
        </div>
      );
    });
  } else {
    return (
      <div className="d-flex flex-column justify-content-end align-items-end chat-message chat-self">
        <div className="chat-self-message" style={style}>
          {typeof children === "string" ? ReactEmoji.emojify(children) : null}
        </div>
        <div>
          <ChatDateTimeUI chat={chat} />
        </div>
      </div>
    );
  }
};

export default ChatSelfUI;
