import React, { Component } from "react";
import { Elements, StripeProvider } from "react-stripe-elements";
import StripeCheckoutForm from "./StripeCheckoutForm";
import Config from "../../Utils/Config";
import ScriptLoader from "react-script-loader-hoc";

class StripePayments extends Component {
  render() {
    // console.log("stripe ============", this.props);
    if (!this.props.scriptsLoadedSuccessfully) {
      return null;
    }
    return (
      <StripeProvider apiKey={Config.paymentGatewayKey}>
        <div className="stripe-component">
          <Elements>
            <StripeCheckoutForm {...this.props} />
          </Elements>
        </div>
      </StripeProvider>
    );
  }
}
export default ScriptLoader("https://js.stripe.com/v3/")(StripePayments);
