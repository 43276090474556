import React from "react";
import Mandatory from "./Mandatory";
import InfoIcon from "./InfoIcon";
import ErrorMessage from "./ErrorMessage";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function DateTimeField({
  isReadOnly = false,
  element,
  action,
  completed,
  onBlurField
}) {
  if (element.value) {
    element.value = new Date(element.value).getTime();
  }
  let content = (isReadOnly && (
    <div className="d-flex flex-row justify-content-between readOnlyForm">
      <label htmlFor={element.id}>
        {element.title}
        <Mandatory element={element} />
      </label>
      <label>{element.value || ""}</label>
    </div>
  )) || (
    <div className="form-group px-4">
      <div className="d-flex flex-column">
        <label
          htmlFor={element.id}
          className="d-flex align-items-center flex-row"
        >
          {element.title}
          <Mandatory element={element} />
          <span className="ml-2">
            <InfoIcon id={`tooltip-${element.id}`} info={element.info} />
          </span>
        </label>
        {/*<input*/}
        {/*  type="datetime-local"*/}
        {/*  disabled={completed === true || element.readOnly}*/}
        {/*  className="form-control form-content-input"*/}
        {/*  id={element.id}*/}
        {/*  value={element.value}*/}
        {/*  onChange={e => {*/}
        {/*    action(e.target.value);*/}
        {/*  }}*/}
        {/*  style={{ width: "200px" }}*/}
        {/*  onBlur={onBlurField}*/}
        {/*/>*/}

        {/*<DatePicker*/}
        {/*  selected={element.value}*/}
        {/*  onChange={value => {*/}
        {/*    action(value);*/}
        {/*  }}*/}
        {/*  showTimeSelect*/}
        {/*  timeFormat="HH:mm"*/}
        {/*  timeIntervals={15}*/}
        {/*  dateFormat="MMMM d, yyyy h:mm aa"*/}
        {/*  timeCaption="time"*/}
        {/*  onBlur={onBlurField}*/}
        {/*/>*/}

        <DatePicker
          selected={element.value}
          onChange={value => {
            if (value) {
              action(value.getTime());
            } else {
              action(null);
            }
          }}
          disabled={element.readOnly || false}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy HH:mm"
          showTimeInput
          onBlur={onBlurField}
        />
      </div>
      <ErrorMessage element={element} />
    </div>
  );

  return content;
}
