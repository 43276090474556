import React, { Component } from "react";
import ChatAvatar from "./ChatAvatar";
import ChatDateTimeUI from "./ChatDateTimeUI";

class ChatLinkOthersUI extends Component {
  render() {
    let { chat, conversation, children } = this.props;
    // console.log("cards ============", children, chat, conversation);

    return (
      <div className="d-flex justify-content-start chat-message chat-others">
        {/* <div className="mr-2">
          <ChatAvatar conversation={conversation} chat={chat} />
        </div> */}
        <div className="d-flex flex-column" style={{ width: "85%" }}>
          {children}
          <div>
            <ChatDateTimeUI chat={chat} />
          </div>
        </div>
      </div>
    );
  }
}

export default ChatLinkOthersUI;
