export const MessageTypeConstants = {
  MESSAGE_TYPE_STRING: "string",
  MESSAGE_TYPE_LIST: "list",
  MESSAGE_TYPE_SLIDER: "slider",
  MESSAGE_TYPE_BUTTON: "button",
  MESSAGE_TYPE_FORM: "form",
  MESSAGE_TYPE_FORM2: "form2",
  MESSAGE_TYPE_HTML: "html",
  MESSAGE_TYPE_IMAGE: "image",
  MESSAGE_TYPE_VIDEO: "video",
  MESSAGE_TYPE_PDF: "pdf",
  MESSAGE_TYPE_TEXT: "txt",
  MESSAGE_TYPE_OTHER_FILE: "other_file",
  MESSAGE_TYPE_CSV: "csv",
  MESSAGE_TYPE_JAVASCRIPT: "js",
  MESSAGE_TYPE_MAP: "map",
  MESSAGE_TYPE_SLIDER_RESPONSE: "slider_response",
  MESSAGE_TYPE_BUTTON_RESPONSE: "button_response",
  MESSAGE_TYPE_FORM_RESPONSE: "form_response",
  MESSAGE_TYPE_TABLE_RESPONSE: "table_response",
  MESSAGE_TYPE_AUDIO: "audio",
  MESSAGE_TYPE_CHART: "chart",
  MESSAGE_TYPE_WAIT: "wait",
  MESSAGE_TYPE_SESSION_START: "session_start",
  MESSAGE_TYPE_BARCODE: "barcode",
  MESSAGE_TYPE_FORM_OPEN: "form_open",
  MESSAGE_TYPE_FORM_CANCEL: "form_cancel",
  MESSAGE_TYPE_SLIDER_CANCEL: "slider_cancel",
  MESSAGE_TYPE_SMART_SUGGESTIONS: "smart_suggestion",
  MESSAGE_TYPE_WEB_CARD: "web_card",
  MESSAGE_TYPE_MENU: "menu",
  MESSAGE_TYPE_TABLE: "table",
  MESSAGE_TYPE_DATA: "data",
  MESSAGE_TYPE_DATA_CARD: "data_card",
  MESSAGE_TYPE_CONTACT_CARD: "contact_card",
  MESSAGE_TYPE_STRIPE: "stripe",
  MESSAGE_TYPE_STRIPE_RESPONSE: "stripe_response",
  MESSAGE_TYPE_CLOSE_FORM: "close_form",
  MESSAGE_TYPE_MAP_RESPONSE: "map_response",
  MESSAGE_TYPE_SEARCH_BOX: "search_box",
  MESSAGE_TYPE_SEARCH_BOX_RESPONSE: "search_box_response",
  MESSAGE_TYPE_CARDS: "cards",
  MESSAGE_TYPE_STD_NOTIFICATION: "standard_notification",
  MESSAGE_TYPE_CRITICAL_NOTIFICATION: "critical_notification",
  MESSAGE_TYPE_ACCEPT_IGNORE_CONTACT: "accept_ignore_contact",
  MESSAGE_TYPE_CONTACT_BEEN_ACCEPTED: "contact_been_accepted",
  MESSAGE_TYPE_DO_BALANCE: "wallet_balance_update",
  MESSAGE_TYPE_RUNMODE: "Runmode",
  MESSAGE_TYPE_TRACKING_VIEW_MESSAGE: "tracking_view_message",
  MESSAGE_TYPE_CONTAINER: "container",
  MESSAGE_TYPE_CONTAINER_RESPONSE: "container_response",
  MESSAGE_TYPE_TRACKING_VIEW_RESPONSE: "tracking_view_response",
  MESSAGE_TYPE_CLOSE_CONTROL: "close_control",
  MESSAGE_TYPE_CARD_RESPONSE: "card_response"
};

export const IntToMessageTypeConstants = {
  10: MessageTypeConstants.MESSAGE_TYPE_STRING,
  30: MessageTypeConstants.MESSAGE_TYPE_IMAGE,
  40: MessageTypeConstants.MESSAGE_TYPE_VIDEO,
  60: MessageTypeConstants.MESSAGE_TYPE_AUDIO,
  140: MessageTypeConstants.MESSAGE_TYPE_HTML,
  200: MessageTypeConstants.MESSAGE_TYPE_LIST,
  210: MessageTypeConstants.MESSAGE_TYPE_SLIDER,
  220: MessageTypeConstants.MESSAGE_TYPE_BUTTON,
  230: MessageTypeConstants.MESSAGE_TYPE_FORM,
  240: MessageTypeConstants.MESSAGE_TYPE_MAP,
  250: MessageTypeConstants.MESSAGE_TYPE_SMART_SUGGESTIONS,
  260: MessageTypeConstants.MESSAGE_TYPE_WEB_CARD,
  270: MessageTypeConstants.MESSAGE_TYPE_STD_NOTIFICATION,
  280: MessageTypeConstants.MESSAGE_TYPE_CRITICAL_NOTIFICATION,
  310: MessageTypeConstants.MESSAGE_TYPE_PDF,
  320: MessageTypeConstants.MESSAGE_TYPE_TEXT,
  330: MessageTypeConstants.MESSAGE_TYPE_OTHER_FILE,
  340: MessageTypeConstants.MESSAGE_TYPE_CSV,
  350: MessageTypeConstants.MESSAGE_TYPE_JAVASCRIPT,
  400: MessageTypeConstants.MESSAGE_TYPE_FORM2,
  410: MessageTypeConstants.MESSAGE_TYPE_MENU,
  420: MessageTypeConstants.MESSAGE_TYPE_TABLE,
  150: MessageTypeConstants.MESSAGE_TYPE_DATA,
  430: MessageTypeConstants.MESSAGE_TYPE_CONTACT_CARD,
  440: MessageTypeConstants.MESSAGE_TYPE_DATA_CARD,
  450: MessageTypeConstants.MESSAGE_TYPE_FORM_RESPONSE,
  460: MessageTypeConstants.MESSAGE_TYPE_STRIPE,
  470: MessageTypeConstants.MESSAGE_TYPE_STRIPE_RESPONSE,
  480: MessageTypeConstants.MESSAGE_TYPE_CLOSE_FORM,
  490: MessageTypeConstants.MESSAGE_TYPE_MAP_RESPONSE,
  500: MessageTypeConstants.MESSAGE_TYPE_CHART,
  510: MessageTypeConstants.MESSAGE_TYPE_SEARCH_BOX, //510 is defined for runmode in backend (check)
  520: MessageTypeConstants.MESSAGE_TYPE_SEARCH_BOX_RESPONSE,
  530: MessageTypeConstants.MESSAGE_TYPE_CARDS,
  550: MessageTypeConstants.MESSAGE_TYPE_RUNMODE,
  560: MessageTypeConstants.MESSAGE_TYPE_TABLE_RESPONSE,
  541: MessageTypeConstants.MESSAGE_TYPE_CONTAINER,
  542: MessageTypeConstants.MESSAGE_TYPE_CONTAINER_RESPONSE,
  570: MessageTypeConstants.MESSAGE_TYPE_TRACKING_VIEW_MESSAGE,
  571: MessageTypeConstants.MESSAGE_TYPE_TRACKING_VIEW_RESPONSE,
  1000: MessageTypeConstants.MESSAGE_TYPE_CONTACT_BEEN_ACCEPTED,
  1001: MessageTypeConstants.MESSAGE_TYPE_ACCEPT_IGNORE_CONTACT,
  11000: MessageTypeConstants.MESSAGE_TYPE_DO_BALANCE,
  543: MessageTypeConstants.MESSAGE_TYPE_CLOSE_CONTROL,
  572: MessageTypeConstants.MESSAGE_TYPE_CARD_RESPONSE
};
