import React, { Component } from "react";
import { connect } from "react-redux";
import BotMesseneger from "../Components/BotMessenger";
import {
  ingestMessage,
  initiateConversationForLink
} from "../State/actions/chats";
import QueueServiceClient from "../Services/Clients/QueueServiceClient";
import { fetchCatalog } from "../State/actions/user";
import _ from "lodash";
import { getSelectedConversation } from "../Services/StorageService";
class WidgetNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      countMessages: 0
    };
  }
  componentDidMount() {
    this.rtcCall = QueueServiceClient.setupRTC(this.receiveIncoming);
    let timeLine = [...this.props.timeLine];
    console.log("Widget componentDidMount Setup RTC", timeLine);
    if (_.isEmpty(timeLine)) {
      this.props.isAnonymousUser &&
        setTimeout(() => {
          console.log(
            "Initiating conversation for link and fetching catalog for anonymous user"
          );
          this.props.initiateConversationForLink();
          this.props.fetchCatalog();
        }, 10);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      this.state.messages &&
      nextProps.messages &&
      !_.isEqual(nextProps.messages, this.state.messages)
    ) {
      this.setState({
        messages: nextProps.messages,
        countMessages: nextProps.messages.length
      });

      setInterval(() => {
        this.setState({
          messages: null
        });
      }, 3000);
    }
  }

  resetRTC = () => {
    if (this.rtcCall) {
      this.rtcCall.cancel();
    }
    this.rtcCall = QueueServiceClient.setupRTC(this.receiveIncoming);
  };

  receiveIncoming = message => {
    if (message.conversation === "") {
      return;
    }
    this.props.ingestMessage(message);
  };

  renderMessage = messages => {
    if (messages) {
      return messages.map((elem, index) => {
        if (typeof elem.message === "string") {
          return (
            <div
              key={index}
              className="notification-chat-bubble"
              style={{
                width: "190px",
                minHeight: "85px",
                backgroundColor: "rgba(255, 255,255, 0.8)",
                borderRadius: "10px",
                marginBottom: "5px",
                padding: "10px 15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #e4e4e4",
                cursor: "default",
                overflowY: "auto"
              }}
            >
              <span
                style={{
                  fontSize: "12px",
                  wordBreak: "break-word"
                }}
              >
                {elem.message}
              </span>
            </div>
          );
        }
      });
    }
  };

  render() {
    let { countMessages, messages } = this.state;
    // console.log("let see message ", messages);

    if (countMessages === 0) {
      return null;
    } else {
      return (
        <div>
          <div
            style={{
              position: "absolute",
              backgroundColor: "#E5453B",
              color: "#fff",
              width: "21px",
              height: "21px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              top: "-8px",
              right: "1px"
            }}
          >
            <span
              style={{
                color: "#fff",
                fontSize: "14px"
              }}
            >
              {this.state.countMessages}
            </span>
          </div>
          <div className="notificationStyle bounce"
            style={{
              position: "absolute",
              width: "190px",
              height: "200px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "50%",
              top: "-210px",
              right: "0px",
              flexDirection: "column"
            }}
          >
            {this.renderMessage(messages)}
          </div>
        </div>
      );
    }
  }
}

const mapActionToProps = {
  initiateConversationForLink,
  ingestMessage,
  fetchCatalog: fetchCatalog
};

const mapDataToProps = (state, props) => {
  let chats = state.chats;
  let conversation = chats.selectedConversation;
  let selectedConversationId = conversation && conversation.conversationId;
  return {
    timeLine: state.chats.timeLine,
    messages: conversation
      ? chats.chatLog.get(conversation.conversationId) || []
      : []
  };
};

export default connect(mapDataToProps, mapActionToProps)(WidgetNotification);
