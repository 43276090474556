import React, { PureComponent } from "react";
// import { getFileIconClass } from "./UploadingFilesView";
import ChatLinkSelfUI from "./ChatLinkSelfUI";
import ChatLinkOthersUI from "./ChatLinkOthersUI";
import { getFileUrl, getFileUsingUrl } from "../../Services/FilesService";

class ChatLinkFile extends PureComponent {
  downloadFile(chat) {
    console.log("donwload file ", chat);

    let message = Array.isArray(chat.message) ? chat.message[0] : chat.message;
    let fileName =
      chat.options && chat.options.fileName ? chat.options.fileName : message;

    getFileUsingUrl(
      getFileUrl(this.props.conversation.conversationId, message)
    ).then(url => {
      let a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    });
  }

  render() {
    let { chat, conversation, self } = this.props;
    let ChatUI = self ? ChatLinkSelfUI : ChatLinkOthersUI;
    let type = chat.messageType;

    return (
      <ChatUI chat={chat} conversation={conversation}>
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "100px",
            width: "100px",
            border: "1px solid #FFFFFF",
            borderRadius: "10px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0 0 3px 0 rgba(0,0,0,0.5)"
          }}
        >
          <i
            // className={getFileIconClass(type)}
            style={{ color: "#e8e9ee", fontSize: "3.5em" }}
          />

          <a
            onClick={() => {
              this.downloadFile(chat);
            }}
            className="oval d-flex justify-content-center
              align-items-center"
            style={{
              marginTop: "20px",
              position: "absolute",
              marginLeft: "15px",
              height: "28px",
              width: "28px",
              backgroundColor: "#00bcf4",
              borderRadius: "50%"
            }}
          >
            <i className="icon-download2 text-white" />
          </a>
        </div>
      </ChatUI>
    );
  }
}

export default ChatLinkFile;
