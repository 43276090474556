import { combineReducers } from "redux";
import userReducer from "./userReducer";
import chatsReducer from "./chatsReducer";

import spinnerReducer from "./spinnerReducer";
import modalReducer from "./modalReducer";
import notificationReducer from "./notificationReducer";
import filesReducer from "./filesReducer";
import phoneReducer from "./phoneReducer";
import offlineBannerReducer from "./offlineBannerReducer";
import appNotificationsReducer from "./appNotificationsReducer";
import appNavigationBarStatusReducer from "./appNavigationBarStatusReducer";
import routeReducer from "./routeReducer";
import widgetReducer from "./widgetReducer";

const rootReducer = combineReducers({
  user: userReducer,
  chats: chatsReducer,
  spinner: spinnerReducer,
  modal: modalReducer,
  notification: notificationReducer,
  files: filesReducer,
  phone: phoneReducer,
  offlineBanner: offlineBannerReducer,
  appNotification: appNotificationsReducer,
  appNavBarStatus: appNavigationBarStatusReducer,
  route: routeReducer,
  widget: widgetReducer
});
export default rootReducer;
