import React, { Component } from "react";
import { connect } from "react-redux";
import { createBrowserHistory } from "history";
import history from "../../Services/History";
import { removeRouteFromWindowComponent } from "../../State/actions/chats";
import { saveRoute } from "../../State/actions/routeAction";
import Config from "../../Utils/Config";
const R = require("ramda");
class HeaderNavBar extends Component {
  setHeaderTitle = data => {
    if (data === "/chats") {
      return this.props.botName;
    } else {
      return this.props.title;
    }
  };

  render() {
    let imgSrc = "";
    let bot =
      this.props.bot && Object.keys(this.props.bot).length > 0
        ? this.props.bot
        : null;

    if (!bot) {
      imgSrc = "/img/home-icon-apps@2x.png";
    } else {
      imgSrc = `${R.prop("contentURL", Config)}${R.prop("logoUrl", bot)}`;
    }
    const {
      backgroundColor = null,
      borderColor = null,
      backButtonImage = null,
      botLogoIconPath = null
    } = this.props.widget;
    // console.log("BORDER::::", backgroundColor);
    let headerStyle = {
      position: "relative"
      // border: "0.6px solid #dedede"
    };

    if (backgroundColor) {
      headerStyle = { ...headerStyle, backgroundColor };
    }
    if (borderColor) {
      // headerStyle = { ...headerStyle, borderColor };
    }

    if (botLogoIconPath) {
      imgSrc = botLogoIconPath;
    }

    let imageSource = backButtonImage
      ? backButtonImage
      : "/img/header-back-arrow@2x.png";

    return (
      <div
        className="d-flex justify-content-center align-items-center header-nav"
        style={headerStyle}
      >
        {this.props.route.currentRoute !== "/chats" && (
          <a
            style={{ position: "absolute", left: "10px" }}
            onClick={() => {
              this.props.removeRouteFromWindowComponent();
              this.props.saveRoute("/chats");
              history.push("/chats");
            }}
          >
            <img style={{ width: "18px" }} src={imageSource} alt="back" />
          </a>
        )}

        {bot && this.props.route.currentRoute === "/chats" && (
          <img
            src={imgSrc}
            style={{
              width: "auto",
              height: "40px",
              marginRight: "5px"
            }}
            alt="logo"
          />
        )}
        {bot && this.props.route.currentRoute !== "/chats" && (
          <span> {this.setHeaderTitle(this.props.route.currentRoute)}</span>
        )}
      </div>
    );
  }
}

const mapActionToProps = {
  removeRouteFromWindowComponent: removeRouteFromWindowComponent,
  saveRoute: saveRoute
};

const mapDataToProps = (state, props) => {
  return {
    isAnonymousUser: state.user.isAnonymousUser || false,
    widget: state.widget.widget_customisations,
    route: state.route,
    bot: state.user.botSubscriptions && { ...state.user.botSubscriptions[0] },
    botName:
      state.user.botSubscriptions &&
      state.user.botSubscriptions[0] &&
      state.user.botSubscriptions[0].botName
        ? state.user.botSubscriptions[0].botName
        : "Bot",
    title:
      state.chats.componentInWindow && state.chats.componentInWindow.options
        ? state.chats.componentInWindow.options.title
        : "Title"
  };
};

export default connect(mapDataToProps, mapActionToProps)(HeaderNavBar);
