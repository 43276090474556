import React, { Component } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { setFormInWindow } from "../../State/actions/chats";
import { MessageTypeConstants } from "../../Services/Message";
import MapComponent from "../Maps/MapComponent";
import MapContentView from "../content/MapContentView";

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}
class ChatMessageMapForWindow extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    let thumb = false;
    let { message, options } = this.props.componentinWindow;

    return (
      <div className="map-route">
        <div style={{ height: "480px" }}>
          <MapContentView data={message} options={options} thumb />
        </div>
      </div>
    );
  }
}

let actions = {
  setFormInWindow: setFormInWindow
};

const mapStateToProps = state => ({
  chats: state.chats,
  route: state.route,
  componentinWindow: state.chats.componentInWindow
});

export default connect(mapStateToProps, actions)(ChatMessageMapForWindow);
