import React, { Component } from "react";
import { connect } from "react-redux";
import ChatContentView from "./ChatContentView";
import { MessageTypeConstants } from "../../Services/Message";
import { closeSmartReply } from "../../State/actions/chats";

class SmartReplies extends Component {
  constructor(props) {
    super(props);
    this.smartReplies = React.createRef();
  }

  // componentDidUpdate() {
  //   let height = 0;
  //   if (this.smartReplies && this.smartReplies.current) {
  //     height = this.smartReplies.current.clientHeight;
  //   }
  //   console.log(height);
  //   this.props.setSmartReplyHeight(height);
  // }

  setRef = (component) => {
    this.smartReplies = component;
  };

  render() {
    let { smartReplies, closeSmartReply, conversation } = this.props;
    if (
      !smartReplies ||
      !smartReplies.message ||
      smartReplies.message.length === 0
    ) {
      return null;
    }
    return (
      <div
        style={{
          height: "auto",
          maxHeight: "200px",
          overflow: "auto",
        }}
        ref={this.smartReplies}
      >
        <ChatContentView
          content={smartReplies}
          sendMessage={this.props.sendMessage}
          smartReply
          closeSmartReply={closeSmartReply}
          conversation={conversation}
          noCloseBtn={
            smartReplies.messageType ===
            MessageTypeConstants.MESSAGE_TYPE_SMART_SUGGESTIONS
          }
        />
      </div>
    );
  }
}

const mapActionToProps = {
  closeSmartReply: closeSmartReply,
};

export default connect(null, mapActionToProps)(SmartReplies);
