import React, { PureComponent } from "react";
import Mandatory from "./Mandatory";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { CustomInput, Label } from "reactstrap";
import InfoIcon from "./InfoIcon";
import ErrorMessage from "./ErrorMessage";
import {
  uploadFile,
  getFileName,
  getFileUrl,
  getFileUsingUrl,
  getFormPhoto
} from "../../../../Services/FilesService";
import store from "../../../../State/configureStore";
import Avatar from "../../../Common/Avatar";

export default class UploadImage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      element: this.props.element,
      uploadStatus: null,
      imgFileName: "",
      imgPath: "",
      completed: this.props.completed,
      crop: {
        aspect: 16 / 9,
        unit: "%",
        width: 100
      }
    };
  }

  UNSAFE_componentWillMount() {
    let { completed, element } = this.props;
    let { value } = element;
    if (value) {
      // completed is false when opened in edit mode from smart suggestion and hence images are not uploaded on form
      //   console.log("value of the img", value);
      let conversationId = store.getState().chats.selectedConversation
        .conversationId;

      getFormPhoto(conversationId, value)
        .then(file => {
          this.setState({ imgPath: file });
        })
        .catch(error => {
          console.log(error);
          this.setState({ imgPath: null });
        });
      //   getFileUsingUrl(this.props.element.value).then(url => {
      //     this.setState({ imgPath: url });
      //   });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    this.setState({ element: nextProps.element });
  }

  showOptions = () => {
    this.setState({ showOptions: true });
  };

  closeOptions = () => {
    this.setState({ showOptions: false });
  };

  onFileUploadProgress = progressEvent => {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    );
    this.setState({ percentCompleted });
  };

  uploadTempPhoto = e => {
    this.setState({
      tempPath: URL.createObjectURL(e.target.files[0]),
      tempFile: e.target.files,
      uploadStatus: "edit"
    });
  };

  finalUpload = async () => {
    if (this.imageRef) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        this.state.crop,
        "newFile.jpeg"
      );

      console.log("final upload", croppedImageUrl);

      this.setState({ croppedImageUrl, uploadStatus: null }, () => {
        this.uploadThePhoto();
      });
    }
  };

  onImageLoaded = image => {
    this.imageRef = image;
  };

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");
    });
  }

  uploadThePhoto = () => {
    this.setState({ uploading: true });
    let file = this.state.tempFile;
    console.log("file data ", file);

    if (!file) {
      return;
    }

    let conversationId = store.getState().chats.selectedConversation
      .conversationId;

    let fileImgName =
      this.state.tempFile && this.state.tempFile[0]
        ? this.state.tempFile[0].name
        : "";
    let fileName = getFileName();
    fileName += fileImgName.substr(
      fileImgName.lastIndexOf("."),
      fileImgName.length - 1
    );
    // let imgName = this.blobToFile(this.state.croppedImageUrl, fileName);
    let imgName = new File([this.state.croppedImageUrl], fileName, {
      type: file[0].type,
      lastModified: Date.now()
    });

    console.log("image upload ", fileName, imgName);

    uploadFile(conversationId, fileName, imgName, this.onFileUploadProgress)
      .then(res => {
        this.setState({ uploading: false, imgFileName: fileImgName });
        return getFileUrl(conversationId, fileName);
      })
      .then(fileUrl => {
        console.log("file url ", fileUrl, fileName, imgName);
        this.props.action(fileName);
        return getFileUsingUrl(fileUrl);
      })
      .then(url => {
        console.log("get final url", url);
        this.setState({ tempPath: url });
      })
      .catch(error => {
        console.log("error on upload ", error);
        this.setState({ uploading: false });
        // todo handle error
      });
  };

  uploadPhoto = e => {
    this.setState({ uploading: true });
    let file = e.target.files;
    if (!file) {
      return;
    }

    let conversationId = store.getState().chats.selectedConversation
      .conversationId;

    let imgName = file[0];
    let fileImgName = file && file[0] ? file[0].name : "";
    let fileName = getFileName();
    fileName += fileImgName.substr(
      fileImgName.lastIndexOf("."),
      fileImgName.length - 1
    );

    uploadFile(conversationId, fileName, imgName, this.onFileUploadProgress)
      .then(res => {
        this.setState({ uploading: false, imgFileName: fileImgName });
        return getFileUrl(conversationId, fileName);
      })
      .then(fileUrl => {
        console.log("file url ", fileUrl, fileName, imgName);
        this.props.action(fileName);
        return getFileUsingUrl(fileUrl);
      })
      .then(url => {
        console.log("get final url", url);
        this.setState({ imgPath: url });
      })
      .catch(error => {
        console.log("error on upload ", error);
        this.setState({ uploading: false });
        // todo handle error
      });
  };

  removeImage = () => {
    console.log("remove image");
    this.props.action("");
    this.setState({
      completed: false,
      value: "",
      imgPath: "",
      tempPath: "",
      tempFile: "",
      uploadStatus: null
    });
  };

  renderLabels = () => {
    let { element, uploadStatus } = this.state;
    let { action } = this.props;
    if (uploadStatus && uploadStatus === "edit") {
      return (
        <Label
          style={{
            padding: 0,
            margin: 0,
            color: "#00A7D6",
            cursor: "pointer",
            fontSize: "14px"
          }}
          onClick={this.finalUpload}
        >
          Save
        </Label>
      );
    } else {
      if (action && !element.readOnly) {
        return (
          <Label
            style={{
              padding: 0,
              margin: 0,
              color: "#00A7D6",
              cursor: "pointer",
              fontSize: "14px"
            }}
            onClick={this.removeImage}
          >
            Change photo
          </Label>
        );
      }
    }
    return null;
  };

  render() {
    let noImage = "No image was uploaded";
    let {
      uploading,
      percentCompleted,
      imgPath,
      tempUpload,
      tempPath,
      completed,
      element
    } = this.state;
    let { value } = element;
    let { action } = this.props;
    // console.log("all the images ", this.props, this.state);

    return (
      <div className="form-group px-4">
        <hr />
        {/* <label className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <span className="has-arrow">
              {element.title}
              <Mandatory element={element} />
            </span>
            <span className="ml-2">
              <InfoIcon id={`tooltip-${element.id}`} info={element.info} />
            </span>
          </div>
        </label> */}
        {completed === true && !value && !action ? (
          <span> {noImage} </span>
        ) : (
          <div>
            <a
              className="primary-link my-2 d-flex justify-content-center align-items-center mr-2"
              style={{
                width: "330px",
                height: "300px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#F4F4F4"
              }}
            >
              {imgPath ? (
                <div
                  className="d-flex justify-content-center align-items-center flex-column upload-image-bg"
                  style={{
                    width: "100%",
                    height: "300px",
                    padding: 0,
                    margin: 0
                  }}
                >
                  {/*  editable image uploader*/}
                  {/* {this.state.croppedImageUrl ? (
                    <img
                      src={this.state.croppedImageUrl}
                      width="100%"
                      alt="img"
                    />
                  ) : (
                    <ReactCrop
                      src={tempPath}
                      crop={this.state.crop}
                      onImageLoaded={this.onImageLoaded}
                      style={{ width: "100%", height: "300px" }}
                      onChange={crop => {
                        this.setState({ crop });
                      }}
                    />
                  )}

                  {this.renderLabels()} */}

                  {/* Non editable image uploader*/}
                  <img src={imgPath} width="100%" height="300px" alt="img" />
                  {action && !element.readOnly && (
                    <Label
                      style={{
                        padding: 0,
                        margin: 0,
                        color: "#00A7D6",
                        cursor: "pointer",
                        fontSize: "14px"
                      }}
                      onClick={this.removeImage}
                    >
                      Change photo
                    </Label>
                  )}
                </div>
              ) : (
                !element.readOnly && (
                  <div
                    className="d-flex justify-content-center align-items-center"
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Label
                      className="d-flex justify-content-center align-items-center border-for-uploadImage"
                      htmlFor="localContactImageBrowser"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center"
                        }}
                      >
                        <img
                          style={{ width: "50px" }}
                          src="./img/avatar-icon-placeholder@2x.png"
                          alt=""
                        />
                        <span style={{ color: "#9B9B9B", fontSize: "16px" }}>
                          Upload a photo
                        </span>
                        <span className="ml-2">
                          <InfoIcon
                            id={`tooltip-${element.id}`}
                            info={element.info}
                          />
                        </span>
                      </div>
                    </Label>
                    <CustomInput
                      type="file"
                      id="localContactImageBrowser"
                      name="customFile"
                      accept=".png,.jpg"
                      onChange={this.uploadPhoto}
                    />
                  </div>
                )
              )}
            </a>

            {uploading && (
              <progress
                id="progressBar"
                value={percentCompleted}
                max="100"
                style={{ width: "100%" }}
              />
            )}
          </div>
        )}
        <ErrorMessage element={element} />
        <hr />
      </div>
    );
  }
}
