import React, { Component } from "react";
import { getFileUrl, getFileUsingUrl } from "../../Services/FilesService";

class ImageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() {
    let { data } = this.props;
    this.loadImage(Array.isArray(data) ? data[0] : data);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    let nextData = nextProps.data;
    let data = this.props.data;
    let nextFileName = Array.isArray(nextData) ? nextData[0] : nextData;
    let fileName = Array.isArray(data) ? data[0] : data;

    if (nextFileName !== fileName) {
      this.loadImage(nextFileName);
    }
  }

  loadImage = fileName => {
    this.setState({ loading: true });
    getFileUsingUrl(getFileUrl(this.props.conversationId, fileName))
      .then(content => {
        this.setState({ loading: false });
        this.setState({ imgUrl: content });
      })
      .catch(error => {
        this.setState({ loading: false });
      });
  };

  render() {
    let { imgUrl } = this.state;
    let node = null;
    if (imgUrl) {
      node = (
        <img
          id="image-message"
          src={imgUrl}
          alt="img-message"
          style={
            this.props.style || {
              overflow: "hidden",
              width: "100%"
            }
          }
        />
      );
    }

    return (
      <div>
        <img
          id="image-message"
          alt="img-message"
          src={imgUrl}
          style={this.props.style || { overflow: "hidden", width: "100%" }}
        />
        {/*{this.state.loading && (*/}
        {/*<div className="d-flex justify-content-center my-4">*/}
        {/*<NoStateSpinner />*/}
        {/*</div>*/}
        {/*)}*/}
      </div>
    );
  }
}
export default ImageContent;
