import React, { Component } from "react";
import BotMesseneger from "../Components/BotMessenger";

class MessengerContainer extends Component {
  render() {
    return <BotMesseneger />;
  }
}

export default MessengerContainer;
