import React, { Component } from "react";
import { connect } from "react-redux";
import FormContentView from "../Components/content/forms/FormContentView";
import {
  sendAMessage,
  resetSelectedConversationFormInWindow,
  removeRouteFromWindowComponent
} from "../State/actions/chats";
import {
  removeStoreFormFromLocal,
  removeOpenForm
} from "../Services/StorageService";
import { saveRoute } from "../State/actions/routeAction";
import history from "../Services/History";

class FormContainer extends Component {
  componentDidMount() {}

  cancelForm = () => {
    // removeStoreFormFromLocal();
    // console.log("data to go back ", data);
    this.props.removeRouteFromWindowComponent();
    this.props.saveRoute("/chats");
    history.push("/chats");
    // history.goBack();
  };
  render() {
    let chat = { ...this.props.formData };
    if (chat && Object.keys(chat) && Object.keys(chat).length === 0) {
      return null;
    }
    let hasCards = true;
    let conversational = false;
    return (
      <FormContentView
        chat={chat}
        genericMessage={{}}
        completed={this.props.completed}
        cancelForm={this.cancelForm}
        closeForm={this.cancelForm}
        sendMessage={this.props.sendAMessage}
        hasCards={hasCards}
        resetSelectedConversationFormInWindow={
          this.props.resetSelectedConversationFormInWindow
        }
        setFormInWindow={this.props.setFormInWindow}
      />
    );
  }
}

const mapActionToProps = {
  sendAMessage: sendAMessage,
  resetSelectedConversationFormInWindow: resetSelectedConversationFormInWindow,
  removeRouteFromWindowComponent: removeRouteFromWindowComponent,
  saveRoute: saveRoute
};

const mapDataToProps = (state, props) => {
  // console.log("mapDataToProps state :: ", state);
  return {
    formData: state.chats.componentInWindow,
    isAnonymousUser: state.user.isAnonymousUser || false
  };
};

export default connect(mapDataToProps, mapActionToProps)(FormContainer);
