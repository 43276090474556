import dayjs from "dayjs";
var windowStorage = require("window.name");
export function storeAuthData(data) {
  const myData = {
    ...data,
    stored_at: dayjs()
  };
  windowStorage.setItem("LoggedState", myData);
}

export function updateAuthData() {
  const authData = getAuthData();
  if (authData && authData.auth) {
    authData.auth.newUser = false;
  }
  const myData = {
    ...authData,
    stored_at: dayjs()
  };
  windowStorage.setItem("LoggedState", myData);
}

export function storeOpenForm(data) {
  // console.log("storing form ", data);

  const myData = {
    ...data
  };
  windowStorage.setItem("openForm", myData);
}

export function addLastMessageId(data) {
  windowStorage.setItem("isMessageOpen", data);
}
export function getLastMessageId() {
  let stored = windowStorage.getItem("isMessageOpen");
  if (stored) {
    return stored;
  }
  return null;
}
export function removeLastMessageId() {
  windowStorage.removeItem("isMessageOpen");
}

export function storeDomainSelected(data) {
  const myData = {
    ...data
  };
  windowStorage.setItem("StoredDomain", myData);
}
export function storeLoginState(data) {
  windowStorage.setItem("LoginState", data);
}
export function removeLoginState(data) {
  windowStorage.removeItem("LoginState", data);
}

export function removeOpenForm() {
  windowStorage.removeItem("openForm");
}

export function removeAuthData() {
  windowStorage.removeItem("LoggedState");
}
export function removeDomainSelcted() {
  windowStorage.removeItem("StoredDomain");
}

export function storeAuthCode(authCode, email) {
  windowStorage.setItem("AuthCode", {
    [email]: authCode
  });
}

export function setSignupPath(pathName) {
  windowStorage.setItem("pathName", pathName);
}

export function getStoredForm() {
  let openForm = windowStorage.getItem("openForm");
  if (openForm) {
    return openForm;
  }
}

export function getSignupPath() {
  return windowStorage.getItem("pathName");
  // if (signupPath) {
  //   return JSON.parse(signupPath);
  // }
  // return null;
}

export function getAuthCode() {
  let authCode = windowStorage.getItem("AuthCode");
  if (authCode) {
    return authCode;
  }
  return null;
}

export function unsetAuthCode() {
  windowStorage.removeItem("AuthCode");
}

export function getAuthData() {
  let stored = windowStorage.getItem("LoggedState");
  if (stored) {
    return stored;
  }
  return null;
}

export function getDomainSelcted() {
  let stored = windowStorage.getItem("StoredDomain");
  if (stored) {
    return stored;
  }
  return null;
}
export function getLoginState() {
  let stored = windowStorage.getItem("LoginState");
  if (stored) {
    return stored;
  }
  return null;
}

export function updateDataInStorage(key, value) {
  let data = getAuthData();
  if (data) {
    data[key] = value;
    storeAuthData(data);
  }
}

export function storeUserDetails(userId, user) {
  windowStorage.setItem(userId, user);
}

export function getStoredUserDetails(userId) {
  let stored = windowStorage.getItem(userId);
  if (stored) {
    return stored;
  }
  return null;
}

export function putInStorage(key, value) {
  windowStorage.setItem(key, value);
}

export function getFromStorage(key) {
  let stored = windowStorage.getItem(key);
  if (stored) {
    return stored;
  }
  return null;
}

export function removeFromStorage(key) {
  windowStorage.removeItem(key);
}

export function storeLinkData(value) {
  putInStorage("linkData", value);
}

export function getLinkData() {
  return getFromStorage("linkData");
}

export function removeLinkData() {
  removeFromStorage("linkData");
}

export function storeFormInLocal(data) {
  putInStorage("storeForm", data);
}

export function getStoreFormFromLocal() {
  return getFromStorage("storeForm");
}

export function removeStoreFormFromLocal() {
  removeFromStorage("storeForm");
}

//  for refresh FC-685
export function storeSelectedConversation(value) {
  putInStorage("SelectedConversation", value);
}

export function getSelectedConversation() {
  return getFromStorage("SelectedConversation");
}

export function removeSelectedConversation() {
  removeFromStorage("SelectedConversation");
}
