export const SAVE_ROUTE = "SAVE_ROUTE";

export function saveRoute(data) {
  console.log("route saving ", data);

  return {
    type: SAVE_ROUTE,
    data: data
  };
}
