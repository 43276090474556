import React, { Component } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import ReactEmoji from "react-emoji";

class Chips extends Component {
  render() {
    let { data: messages, sendMessage } = this.props;

    // if (!_.isEmpty(this.props.background)) {
    //   customStyle = {};
    // }
    if (!messages || messages.length === 0) {
      return null;
    }

    // let newMessage = [
    //   ":) It was good.",
    //   ":/ Hmm. I am not sure",
    //   ":( It was awful",
    // ];

    let buttons = [];

    if (Array.isArray(messages)) {
      buttons = messages.map(function(msg, index) {
        return (
          <a
            key={index}
            style={{ margin: "3px 3px", display: "inline-block" }}
            onClick={() => {
              // console.log("sending messag from chips ======== ", msg);
              sendMessage(msg);
            }}
          >
            <div
              className="media clearfix media-chit"
              style={{ margin: "0px" }}
            >
              <div className="medbody">
                <p
                // style={{
                //   whiteSpace: "nowrap",
                // }}
                >
                  {ReactEmoji.emojify(msg)}
                </p>
              </div>
            </div>
          </a>
        );
      });
    }

    return (
      <div
        className="py-1 px-2"
        style={Object.assign({
          flex: 1,
          width: "100%",
          display: "flex",
          overflowX: "auto",
          overflowY: "hidden",
        })}
      >
        {/* <div className="d-flex justify-content-end flex-wrap">{buttons}</div> */}
        <div
          className="d-flex justify-content-center"
          style={{
            width:
              this.props.suggestionsLayout === "vertical" ? "100%" : "unset",
            marginBottom: "10px",
            // flexDirection:
            //   this.props.suggestionsLayout === "vertical" ? "column" : "row",
            alignItems:
              this.props.suggestionsLayout === "vertical"
                ? "flex-end"
                : "flex-start",
            flexWrap:
              this.props.suggestionsLayout === "vertical" ? "wrap" : "unset",
          }}
        >
          {buttons}
        </div>
      </div>
    );
  }
}

const mapDataToProps = (state) => {
  let chats = state.chats;
  let conversation = chats.selectedConversation;
  let selectedConversationId = conversation && conversation.conversationId;
  return {
    conversational: chats.conversational,
    background: selectedConversationId
      ? (chats.conversationModeMap[selectedConversationId] &&
          chats.conversationModeMap[selectedConversationId].background) ||
        {}
      : {},
    suggestionsLayout: selectedConversationId
      ? (chats.conversationModeMap[selectedConversationId] &&
          chats.conversationModeMap[selectedConversationId]
            .suggestionsLayout) ||
        "horizontal"
      : "horizontal",
  };
};

export default connect(mapDataToProps, null)(Chips);
