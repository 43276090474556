import {
  USER_LOG_IN_ERROR,
  USER_LOGGED_IN,
  LOGOUT_USER,
  BOT_SUBSCRIPTIONS_RECEIVED,
  UPDATE_USER,
  TERMS_AND_CONDITIONS_ACCEPTED,
  USER_GEOLOCATION_DATA,
  UPDATE_PHONE_BALANCE,
  UPDATE_CALL_HISTORY,
  PROFILE_IMAGE,
  UPDATE_USER_AUTH_DATA,
  UPDATE_LOGIN_METHOD,
  UPDATE_BANNER,
  FORM_UNSAVED,
  UPDATE_LOGGED_IN_USER_CALL_HISTORY,
  UPDATE_MORE_RECORDS_EXIST,
  CLEAR_LOGGED_IN_USER_CALL_HISTORY,
  UPDATE_SELECTED_CONTACT_CALL_HISTORY,
  CLEAR_LOGIN_ERROR,
  SET_REFRESH_ANONYMOUS_SESSION_FLAG,
  SHOW_ERROR_PAGE
} from "../actions/user";
import _ from "lodash";

import { UPDATE_USER_BOT_SUBSCRIPTION } from "../actions/notification";
import { getUpdatedSubscriptions } from "../../Utils/Helpers";
import { updateDataInStorage } from "../../Services/StorageService";

let initialState = {
  user: null,
  auth: null,
  error: null,
  formUnsaved: false,
  botSubscriptions: [],
  settingsOpen: false,
  balance: 0,
  callHistory: [],
  loggedInUserCallHistory: [],
  timeStampOfLastRecordInRcvdCallHistoryForLoggedInUser: null,
  hasMoreRecords: true,
  contactsCallHistory: {},
  clear_login_error: "",
  isRefreshAnonymousSessionBeingCalled: false,
  show404Error: false
};

function userReducer(state = initialState, action) {
  // console.log("data in user Reducer ", state, action);

  switch (action.type) {
    case USER_LOG_IN_ERROR:
      return { ...state, error: action.data.message };
    case SHOW_ERROR_PAGE:
      return { ...state, show404Error: !state.show404Error };
    case USER_LOGGED_IN:
      return {
        ...state,
        user: action.data.user,
        auth: action.data.auth,
        isAnonymousUser: action.data.isAnonymousUser,
        error: null
      };
    case PROFILE_IMAGE:
      return {
        ...state,
        profileImage: action.data
      };
    case UPDATE_BANNER:
      return {
        ...state,
        bannerDetails: {
          ...action.data
        }
      };
    case FORM_UNSAVED:
      return {
        ...state,
        formUnsaved: action.data
      };
    case USER_GEOLOCATION_DATA:
      return {
        ...state,
        geoData: { ...action.data }
      };

    case BOT_SUBSCRIPTIONS_RECEIVED:
      return {
        ...state,
        botSubscriptions: [].concat(action.data.botSubscriptions)
      };

    case UPDATE_USER_BOT_SUBSCRIPTION:
      let subscription = action.data.subscription;
      let subscriptions = state.user.webSubscriptions || [];
      let updatedSubscriptions = getUpdatedSubscriptions(
        subscriptions,
        subscription
      );
      return {
        ...state,
        user: {
          ...state.user,
          webSubscriptions: [].concat(updatedSubscriptions)
        }
      };

    case UPDATE_USER:
      let updUser = Object.assign({}, state.user, action.data.user);
      updateDataInStorage("user", updUser);
      return {
        ...state,
        user: updUser
      };

    case UPDATE_PHONE_BALANCE:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_CALL_HISTORY:
      return {
        ...state,
        ...action.payload
      };
    case UPDATE_SELECTED_CONTACT_CALL_HISTORY:
      const { callHistory, selectedContactId } = action.payload;
      let updatedContactCallHistory = state.contactsCallHistory;
      updatedContactCallHistory[selectedContactId] = callHistory
        ? callHistory.content
        : [];

      return {
        ...state,
        contactsCallHistory: updatedContactCallHistory
      };
    case UPDATE_LOGGED_IN_USER_CALL_HISTORY:
      let rcvdCallHistory = action.payload.records;
      let moreRecordsExist = action.payload.moreRecordsExist;
      // rcvdCallHistory.sort((a, b) => b.callTimestamp - a.callTimestamp)
      const exCallHistory = state.loggedInUserCallHistory;

      const customResults = _.differenceWith(
        rcvdCallHistory,
        exCallHistory,
        (rcvdCallRecord, exCallRecord) =>
          exCallRecord.callTimestamp === rcvdCallRecord.callTimestamp
      );
      // console.log("customResults", customResults);
      exCallHistory.push(...customResults);
      exCallHistory.sort((a, b) => b.callTimestamp - a.callTimestamp);

      const timeStampOfLastRecordInExCallHistoryForLoggedInUser = exCallHistory
        ? exCallHistory[exCallHistory.length - 1].callTimestamp
        : null;

      const updatedRecords = [...exCallHistory, ...rcvdCallHistory];

      return {
        ...state,
        loggedInUserCallHistory: exCallHistory,
        timeStampOfLastRecordInExCallHistoryForLoggedInUser: timeStampOfLastRecordInExCallHistoryForLoggedInUser,
        hasMoreRecords: moreRecordsExist
      };

    case CLEAR_LOGGED_IN_USER_CALL_HISTORY:
      return {
        ...state,
        loggedInUserCallHistory: [],
        timeStampOfLastRecordInRcvdCallHistoryForLoggedInUser: null,
        hasMoreRecords: true
      };
    case CLEAR_LOGIN_ERROR:
      return { ...initialState, error: "" };

    case TERMS_AND_CONDITIONS_ACCEPTED:
      let user = state.user;
      if (action.data.accepted) {
        user.tncAccept = true;
        updateDataInStorage("user", user);
        return { ...state, user: { ...user } };
      } else {
        return { ...state };
      }
    case UPDATE_LOGIN_METHOD:
      let loginState = action.data.data;
      return {
        ...state,
        loginState: loginState
      };

    case UPDATE_USER_AUTH_DATA:
      let newUserVal = action.data.newUser;
      return {
        ...state,
        auth: {
          ...state.auth,
          newUser: newUserVal
        }
      };
    case SET_REFRESH_ANONYMOUS_SESSION_FLAG:
      let status = action.data;
      return {
        ...state,
        isRefreshAnonymousSessionBeingCalled: status
      };
    case LOGOUT_USER:
      return {
        ...initialState,
        loggedInUserCallHistory: [],
        timeStampOfLastRecordInRcvdCallHistoryForLoggedInUser: null,
        hasMoreRecords: true,
        contactsCallHistory: {}
      };

    default:
      return state;
  }
}

export default userReducer;
