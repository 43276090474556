import React from "react";
import Mandatory from "./Mandatory";
import Radio from "./Radio";
import InfoIcon from "./InfoIcon";
import ErrorMessage from "./ErrorMessage";

export default function RadioField({
  isReadOnly = false,
  element,
  action,
  completed,
  onBlurField
}) {
  let { options = [], value } = element;
  let content = (isReadOnly && (
    <div className="d-flex flex-row justify-content-between readOnlyForm">
      <label htmlFor={element.id}>
        {element.title}
        <Mandatory element={element} />
      </label>
      <label>{value || ""}</label>
    </div>
  )) || (
    <div className="form-group px-4">
      <label htmlFor={element.id}>
        <div className="d-flex align-items-center">
          {element.title}
          <Mandatory element={element} />
          <span className="ml-2">
            <InfoIcon id={`tooltip-${element.id}`} info={element.info} />
          </span>
        </div>
      </label>
      <div>
        {options.map((option, index) => {
          return (
            <Radio
              checked={value === option}
              option={option}
              action={action}
              disabled={element.readOnly}
              id={element.id + "_RadioField_" + index}
            />
          );
        })}
      </div>
      <ErrorMessage element={element} />
    </div>
  );
  return content;
}
