import { SET_WIDGET_CUSTOMISATIONS } from "../actions/widgetActions";

let initialState = {
  widget_customisations: {}
};

const widgetReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_WIDGET_CUSTOMISATIONS:
      return { ...state, ...{ widget_customisations: action.payload } };
    default:
      return state;
  }
};

export default widgetReducer;
