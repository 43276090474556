import { SAVE_ROUTE } from "../actions/routeAction";

let initialState = {
  currentRoute: null,
  previousRoute: null
};

function routeReducer(state = initialState, action) {
  switch (action.type) {
    case SAVE_ROUTE:
      const currentRoute = state.currentRoute;
      // console.log("currentRoute === ", action.data);
      // console.log("previousRoute === ", currentRoute);

      return {
        ...state,
        currentRoute: action.data,
        previousRoute: currentRoute
      };
    default:
      return state;
  }
}

export default routeReducer;
