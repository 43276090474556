import RPC from "./RPC";
import { queue, commonmessages } from "../gRPC/Generated/QueueService";

export default class QueueServiceClient {
  static setupRTC(receiveMessage) {
    return RPC.serverStreaming(
      "/queue.QueueService/GetStreamingQueueMessage",
      {},
      {},
      commonmessages.Empty,
      request => {
        return commonmessages.Empty.encode(request).finish();
      },
      queue.QueueMessage.decode,
      receiveMessage,
      error => {
        console.log(new Date());
        console.log(
          "Sourav Logging:::: Error Streaming",
          JSON.stringify(error)
        );
        console.log(
          "error while receiving the incoming message: ",
          JSON.stringify(error)
        );
        if (error.code === 16 || error.code === 14) {
          return;
        }
        window.dispatchEvent(new Event("stream_closed"));
      }
    );
  }
}

//
// let numberOfRetries = 0;
// let connected = true;
//
// export default class QueueServiceClient {
//   static setupRTC(receiveMessage) {
//     let connection = RPC.serverStreaming(
//       "/queue.QueueService/GetStreamingQueueMessage",
//       {},
//       {},
//       commonmessages.Empty,
//       request => {
//         return commonmessages.Empty.encode(request).finish();
//       },
//       queue.QueueMessage.decode,
//       data => {
//         numberOfRetries = 0;
//         connected = true;
//         receiveMessage(data);
//       },
//       error => {
//         console.log(new Date());
//         console.log(
//           "error while receiving the incoming message: ",
//           JSON.stringify(error)
//         );
//         connected = false;
//         if (error && error.code === 14) {
//           if (numberOfRetries < 3 && !connected) {
//             QueueServiceClient.setupRTC(receiveMessage);
//             numberOfRetries++;
//           }
//           /*else {
//             if (numberOfRetries >= 3) {
//               Notify({
//                 type: "warning",
//                 message:
//                   "Please refresh the browser to reestablish the connection with the server"
//               });
//             }
//           }*/
//         }
//       }
//     );
//     return connection;
//   }
// }
