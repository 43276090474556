import * as gRPCWeb from "grpc-web";
import ServiceClientUtils from "./ServiceClientUtils";
import store from "../../State/configureStore";
import Config from "./../../Utils/Config";
import { storeLatestCall } from "../../State/actions/offlineBanner";
const grpcClient = new gRPCWeb.GrpcWebClientBase({});

var deadline;

let RPC_SERVER = Config.gRPCURL;

export default class RPC {
  static rpcCall(
    url,
    request,
    responseType,
    requestSerializeFn,
    responseDeserializeFn,
    options = {},
    noSpinner
  ) {
    let auth = store.getState().user ? store.getState().user.auth : null;
    return new Promise((resolve, reject) => {
      ServiceClientUtils.requestInterceptor(noSpinner);
      console.log(
        "REQUEST: ",
        JSON.stringify(request),
        "URL: ",
        url,
        " auth : ",
        auth
      );
      deadline = new Date();
      deadline.setSeconds(deadline.getSeconds() + 15);
      grpcClient.rpcCall(
        RPC_SERVER + url,
        request,
        Object.assign(options, {
          sessionId: auth ? auth.sessionId : null,
          deadline: deadline.getTime()
        }),
        new gRPCWeb.AbstractClientBase.MethodInfo(
          responseType,
          requestSerializeFn,
          responseDeserializeFn
        ),
        (error, response) => {
          if (error) {
            console.log("Sourav Logging:::: GRPC ERRRRRRROOOORRRR", error);
            let latestAPICallObj = {
              url,
              request,
              responseType,
              requestSerializeFn,
              responseDeserializeFn,
              options,
              noSpinner
            };
            store.dispatch(storeLatestCall(latestAPICallObj));
            ServiceClientUtils.handleError(error, url);
            reject(error);
            return;
          }
          try {
            ServiceClientUtils.handleResponse(response);
          } catch (e) {
            reject(e);
          }
          resolve(response);
        }
      );
    });
  }

  static serverStreaming(
    url,
    request,
    options,
    responseType,
    requestSerializeFn,
    responseDeserializeFn,
    successCb,
    errorCb
  ) {
    let auth = store.getState().user ? store.getState().user.auth : null;
    // console.log(
    //   "serverStreaming REQUEST: ",
    //   JSON.stringify(request),
    //   "URL: ",
    //   url,
    //   " auth : ",
    //   auth
    // );
    deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 1800);
    let call = grpcClient.serverStreaming(
      RPC_SERVER + url,
      request,
      Object.assign(options, {
        sessionId: auth ? auth.sessionId : null,
        deadline: deadline.getTime()
      }),
      new gRPCWeb.AbstractClientBase.MethodInfo(
        responseType,
        requestSerializeFn,
        responseDeserializeFn
      )
    );

    call.on("data", successCb);
    call.on("error", errorCb);

    call.on("status", status => {
      console.log("GRPC status: ", status);
      if (status.code !== 0 && status.code !== 16 && status.code !== 14) {
        console.log("Sourav Logging:::: Sttream Is Closed RESET!!!");
        window.dispatchEvent(new Event("stream_closed"));
      }
    });
    call.on("end", data => {
      console.log("GRPC  end: ", data);
      window.dispatchEvent(new Event("stream_closed"));
    });
    return call;
  }
}
