import React, { Component } from "react";
import PropTypes from "prop-types";

import { MessageTypeConstants } from "../../Services/Message";

class ChatInputBox extends Component {
  constructor(props) {
    super(props);
    this.state = { message: "", charLeft: 256 };
    this.inputBox = React.createRef();
  }

  onChangeMessage = (e) => {
    this.setState({ message: e.target.value });
    let counter = this.countCharacters(e.target.value);
    this.setState({ charLeft: counter });
  };

  sendMessage = (e) => {
    e.preventDefault();
    let message = this.state.message;
    if (!message || !message.length) {
      return;
    }
    this.props.sendMessage({
      message: this.state.message,
      messageType: MessageTypeConstants.MESSAGE_TYPE_STRING,
    });
    this.setState({ message: "", charLeft: 256 });
  };

  countCharacters = (data) => {
    return 256 - data.length;
  };

  render() {
    return (
      <div
        className="show d-flex px-2 justify-content-center align-items-center"
        style={{
          width: "100%",
          height: "70px",
          backgroundColor: "rgb(245, 246, 248)",
          borderBottomLeftRadius: "10px",
          borderBottomRightRadius: "10px",
        }}
      >
        <form
          className="form-inline"
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
          onSubmit={this.sendMessage}
        >
          <div
            className="d-flex justify-content-center align-items-center input-text-div"
            style={{
              boxSizing: "border-box",
              width: "100%",
              border: "0.2px solid rgba(91,91,91,0.2)",
              borderRadius: "10px",
              backgroundColor: "#FFFFFF",
              boxShadow: "0 0 4px 0 rgba(0,0,0,0.08)",
            }}
          >
            <input
              ref={this.inputBox}
              className="form-control chat-input border0"
              placeholder="Aa"
              maxLength="256"
              value={this.state.message}
              onChange={this.onChangeMessage}
              disabled={this.props.disable}
            />

            <span className="char-left-text">
              {this.state.charLeft} characters left
            </span>
          </div>
        </form>
      </div>
    );
  }
}

ChatInputBox.propTypes = {
  sendMessage: PropTypes.func.isRequired,
};

export default ChatInputBox;
