import React from "react";
import Select from "react-select";
import Mandatory from "./Mandatory";
import InfoIcon from "./InfoIcon";
import ErrorMessage from "./ErrorMessage";

export default function DropDown({
  isReadOnly = false,
  chat,
  element,
  action,
  completed,
  onBlurField
}) {
  let value = element.value;
  let closeAllow;

  if (chat.options && !chat.options.allowClose) {
    closeAllow = false;
  } else {
    closeAllow = true;
  }
  if (typeof value !== "object") {
    value = { value: value, label: value };
  }

  let { options = [] } = element;
  let content = (isReadOnly && (
    <div className="d-flex flex-row justify-content-between readOnlyForm">
      <label htmlFor={element.id}>
        {element.title}
        <Mandatory element={element} />
      </label>
      <label>{value || ""}</label>
    </div>
  )) || (
    <div className="form-group px-4">
      <div className="d-flex flex-column">
        <label
          htmlFor={element.id}
          className="d-flex align-items-center flex-row"
        >
          {element.title}
          <Mandatory element={element} />
          <span className="ml-2">
            <InfoIcon id={`tooltip-${element.id}`} info={element.info} />
          </span>
        </label>
        <div style={{ width: "95%" }}>
          <Select
            isDisabled={
              (closeAllow && completed === true) || element.readOnly // menuIsOpen={true}
            }
            id={element.id}
            onChange={option => {
              action(option ? option.value : null);
            }}
            aria-label={element.id}
            aria-labelledby={element.id}
            placeholder=""
            value={value}
            options={options.map(option => {
              return { label: option, value: option };
            })}
            isClearable={!element.mandatory}
            onBlur={onBlurField}
          />
        </div>
      </div>
      <ErrorMessage element={element} />
    </div>
  );
  return content;
}
