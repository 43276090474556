import React, { PureComponent } from "react";
import _ from "lodash";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { hideModal, showModal } from "../../State/actions/modal";
import { connect } from "react-redux";
import { setFormInWindow } from "../../State/actions/chats";
import { MessageTypeConstants } from "../../Services/Message";
import { Tooltip } from "reactstrap";

class ChatMessageTableForWindow extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      more: false,
      // rows: this.props.chats && [...this.props.chats.message],
      // columnNames:
      //   this.props.chats && this.props.chats.options
      //     ? [...this.props.chats.options.columnNames]
      //     : Object.keys(this.props.chats.message[0]),
      rows: [],
      columnNames: {},
      showErrorMsgToolTipArr: [],
      activeId: null
    };
  }

  componentWillReceiveProps(nextProps, prevProps) {
    console.log(
      "all the props in componentWillReceiveProps",
      nextProps,
      prevProps
    );
  }

  toggle = tableErrorIconId => {
    const clonedShowErrorMsgToolTip = Object.assign(
      [],
      this.state.showErrorMsgToolTipArr
    );
    clonedShowErrorMsgToolTip[tableErrorIconId] = !clonedShowErrorMsgToolTip[
      tableErrorIconId
    ];
    this.setState({
      showErrorMsgToolTipArr: clonedShowErrorMsgToolTip
    });
  };

  toggleClass = index => {
    const isMobile = window.innerWidth <= 600;
    isMobile && this.setState({ activeId: index });
  };

  checkingColumn = (data, inx) => {
    let { rows } = this.state;
    let { chat, sendMessage } = this.props;
    let option = chat.options;
    let objValue = {};

    rows.forEach((elem, indx) => {
      if (indx === inx) {
        if (elem.checkboxSelected) {
          elem.checkboxSelected = false;
        } else {
          elem.checkboxSelected = true;
        }
      } else {
        elem.checkboxSelected = false;
      }
    });

    this.setState({ rows: [...rows] });

    option.keys.forEach(elem => {
      objValue[elem] = data[elem];
    });

    let responseChat = {};
    responseChat.messageType = MessageTypeConstants.MESSAGE_TYPE_TABLE_RESPONSE;
    responseChat.message = {
      tableId: option.tableId,
      action: "onSelection",
      content: { ...objValue }
    };
    sendMessage(responseChat, true);
  };

  actionRow = (data, inx) => {
    let { rows } = this.state;
    let { chat, sendMessage } = this.props;
    let option = chat.options;
    let objValue = {};

    rows.forEach((elem, indx) => {
      if (indx === inx) {
        if (elem.actionSelected) {
          elem.actionSelected = false;
        } else {
          elem.actionSelected = true;
        }
      } else {
        elem.actionSelected = false;
      }
    });

    this.setState({ rows: [...rows] });

    option.keys.forEach(elem => {
      objValue[elem] = data[elem];
    });

    let responseChat = {};
    responseChat.messageType = MessageTypeConstants.MESSAGE_TYPE_TABLE_RESPONSE;
    responseChat.message = {
      tableId: option.tableId,
      action: "onAction",
      content: { ...objValue }
    };
    sendMessage(responseChat, true);
    if (
      this.props.chat &&
      this.props.chat.options &&
      this.props.chat.options.minimizeOnAction
    ) {
      this.props.makeTheTableMinimize(this.props.componentIndex);
    }
  };

  onlyForAirIndiaMobile = row => {
    const isMobile = window.innerWidth <= 600;
    const airIndiaBotIdDev = "hgpLFFxZb1oswGeuo9wyxi";
    const airIndiaBotIdProd = "eYmMJVcogncHPvAo2LYq6P";
    if (isMobile) {
      if (row["Scheduled Departure"] && row["Origin"] && row["Destination"]) {
        return (
          <div
            className="initial-info"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div className="flex-row-space-between">
              <span style={{ color: "#9B9B9B", fontSize: "12px" }}>
                Departure
              </span>
              <span
                style={{
                  color: "#666666",
                  fontSize: "12px",
                  textAlign: "right"
                }}
              >
                {row["Scheduled Departure"]}
              </span>
            </div>
            <div className="flex-row-space-between">
              <span style={{ color: "#9B9B9B", fontSize: "12px" }}>From</span>
              <span
                style={{
                  color: "#666666",
                  fontSize: "12px",
                  textAlign: "right"
                }}
              >
                {row["Origin"]}
              </span>
            </div>
            <div className="flex-row-space-between">
              <span style={{ color: "#9B9B9B", fontSize: "12px" }}>To</span>
              <span
                style={{
                  color: "#666666",
                  fontSize: "12px",
                  textAlign: "right"
                }}
              >
                {row["Destination"]}
              </span>
            </div>
            {/* <div
              style={{
                marginRight: "25px",
                display: "flex",
                flexDirection: "column",
                textAlign: "left"
              }}
            >
              {" "}
              <span style={{ fontSize: "12px", color: "#9B9B9B" }}>
                Departure
              </span>{" "}
              <span style={{ fontSize: "16px", color: "#666666" }}>
                {row["Scheduled Departure"]}
              </span>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "left"
              }}
            >
              {" "}
              <span style={{ fontSize: "12px", color: "#9B9B9B" }}>
                Arrival
              </span>{" "}
              <span style={{ fontSize: "16px", color: "#666666" }}>
                {row["Scheduled Arrival"]}
              </span>
            </div> */}
          </div>
        );
      } else return null;
    }
    return null;
  };

  showActionButton = (options, row, inx) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        {options.actionableRows && (
          <div className="dashboradIcon">
            <a
              className="img-top"
              style={{ width: "25px" }}
              onClick={() => this.actionRow(row, inx)}
            >
              <img
                style={{ width: "25px" }}
                src="./img/dashboard-icon-hover@2x.png"
                alt="actionableRow"
              />
            </a>
          </div>
        )}
      </div>
    );
  };

  render() {
    let chats =
      this.props.chats &&
      this.props.chats.componentInWindow &&
      this.props.chats.componentInWindow;
    console.log(
      "in render will see chats ",
      this.props.chats.componentInWindow
    );

    const isMobile = window.innerWidth <= 600;
    const airIndiaBotIdDev = "hgpLFFxZb1oswGeuo9wyxi";
    const airIndiaBotIdProd = "eYmMJVcogncHPvAo2LYq6P";
    console.log("this props in table", this.props.chats);

    // let rows = chat.message || [];
    // let { rows, columnNames } = this.state;

    let rows = this.props.chats &&
      this.props.chats.componentInWindow &&
      Object.keys(this.props.chats).length > 0 &&
      Object.keys(this.props.chats.componentInWindow).length > 0 && [
        ...this.props.chats.componentInWindow.message
      ];
    let columnNames =
      this.props.chats &&
      this.props.chats.componentInWindow.options &&
      Object.keys(this.props.chats.componentInWindow.message[0]);
    // if (rows.length === 0) {
    //   return <ChatOthersUI chat={chat}>No data available</ChatOthersUI>;
    // }

    let options = chats.options;
    // let columnNames = options.columnNames || Object.keys(rows[0]);
    let chatMessageTableRow = ["chatMessageTable-row"];
    if (this.state.addClass) {
      chatMessageTableRow.push("showHide-all-td");
    }

    return (
      <div
        className="d-flex flex-column chatMessageTable-container"
        style={{
          maxHeight: this.props.hasCards
            ? "calc(100vh - 320px - 100px)"
            : "500px"
        }}
      >
        {/* <div className="d-flex flex-column p-2 chatMessageTable-title">
          <h2>{options.title}</h2>
          <div>{options.description}</div>
        </div> */}
        <Table
          className={`table chatMessageTable-table ${
            this.props.linkData &&
            (this.props.linkData.botId === airIndiaBotIdDev ||
              this.props.linkData.botId === airIndiaBotIdProd)
              ? "airIndiaTable"
              : ""
          }`}
        >
          <Thead>
            <Tr className="thead-row">
              {options.selectableRows && <Th>{""}</Th>}
              {columnNames.map((h, inx) => (
                <Th key={inx}>{h}</Th>
              ))}
              <Th>{""}</Th>
              <Th>{""}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {rows.map((row, inx) => {
              let bgColor = "";
              let tableErrorIconId = "table-error-icon_" + inx;
              if (!_.isEmpty(row["errorMessage"])) {
                bgColor = "#FFDDCC";
              }
              return (
                <Tr
                  key={inx}
                  className={
                    this.state.activeId === inx
                      ? "chatMessageTable-row"
                      : "showHide-all-td"
                  }
                  style={{ backgroundColor: bgColor }}
                  onClick={() => this.toggleClass(inx)}
                >
                  {options.selectableRows && (
                    <Td
                      style={{
                        padding: "0.5rem 1rem",
                        display: "flex",
                        justifyContent: "center"
                      }}
                    >
                      {row.checkboxSelected ? (
                        <a onClick={() => this.checkingColumn(row, inx)}>
                          <img
                            style={{ width: "16px" }}
                            src="./img/checkbox-checked@2x.png"
                            alt="checkbox"
                          />
                        </a>
                      ) : (
                        <a onClick={() => this.checkingColumn(row, inx)}>
                          <img
                            style={{ width: "16px" }}
                            src="./img/checkbox-empty@2x.png"
                            alt="checkbox"
                          />
                        </a>
                      )}
                    </Td>
                  )}

                  {columnNames.map((column, index) => {
                    {
                      /* if (row[column].startsWith("#")) {
                      let regExp = /\(([^)]+)\)/;
                      let matches = regExp.exec(row[column]);
                      let newString = parseInt(matches[1], 10);

                      const optionsTime = {
                        weekday: "short",
                        year: "numeric",
                        month: "short",
                        day: "numeric",
                        hour: "numeric",
                        minute: "numeric"
                      };

                      return (
                        <Td key={index} style={{ padding: "0.5rem 1rem" }}>
                          <a className="primar`y-link">
                            {new Date(newString).toLocaleString(
                              "en-US",
                              optionsTime
                            )}
                          </a>
                        </Td>
                      );
                    } */
                    }

                    if (index === 0) {
                      return (
                        <Td key={index} style={{ padding: "0.5rem 1rem" }}>
                          <div className="flex-row-space-between">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                width: "100%",
                                padding: "0 10px"
                              }}
                            >
                              <div
                                className="flex-row-space-between"
                                style={{ marginBottom: "10px" }}
                              >
                                <a className="primar`y-link first-table-col">
                                  {/* onClick={() => this.openTableRow(row)} */}
                                  {row[column] ? row[column].toString() : ""}
                                </a>
                                <div>
                                  {isMobile &&
                                    this.showActionButton(options, row, inx)}
                                </div>
                              </div>
                              {/*This only for Air India*/}
                              {this.onlyForAirIndiaMobile(row)}
                            </div>
                          </div>
                        </Td>
                      );
                    }
                    return (
                      <Td key={index} style={{ padding: "0.5rem 1rem" }}>
                        {row[column] ? row[column].toString() : ""}
                      </Td>
                    );
                  })}
                  {!isMobile && options.actionableRows && (
                    <Td style={{ padding: "0.5rem 1rem" }}>
                      {/* {row.actionSelected ? (
                        <a
                          style={{ width: "25px" }}
                          onClick={() => this.actionRow(row, inx)}
                        >
                          <img
                            style={{ width: "25px" }}
                            src="./img/dashboard-icon-hover@2x.png"
                            alt="actionableRow"
                          />
                        </a>
                      ) : ( */}
                      {this.showActionButton(options, row, inx)}
                      {/* )} */}
                    </Td>
                  )}
                  <Td id={tableErrorIconId} style={{ verticalAlign: "middle" }}>
                    {!_.isEmpty(row["errorMessage"]) && (
                      <div>
                        <img
                          src="./img/table-alert-icon@3x.png"
                          style={{ width: "20px", cursor: "pointer" }}
                          alt="table Alert Icon"
                        />
                      </div>
                    )}
                  </Td>

                  {!_.isEmpty(row["errorMessage"]) && (
                    <Tooltip
                      target={tableErrorIconId}
                      modifiers={{
                        preventOverflow: { boundariesElement: "window" }
                      }}
                      placement="right"
                      autohide={false}
                      isOpen={
                        this.state.showErrorMsgToolTipArr[tableErrorIconId] ||
                        false
                      }
                      toggle={() => this.toggle(tableErrorIconId)}
                      delay={{ show: 0, hide: 200 }}
                      id="tableErrTooltip"
                      flip={false}
                    >
                      {row.errorMessage}
                    </Tooltip>
                  )}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* {rows.length > 5 && (
            <a
              onClick={this.toggleMore}
              style={{
                borderBottomRightRadius: "10px",
                borderBottomLeftRadius: "10px",
                borderTop: "1px solid #DEDEDE"
              }}
              className="d-flex justify-content-center align-items-center p-2 bg-white primary-link"
            >
              {more ? "See less" : "See more"}
            </a>
          )} */}
        {options.footer && options.footer.length > 0 && (
          <div className="option-footer">
            <span>{options.footer}</span>
          </div>
        )}
      </div>
    );
  }
}

let actions = {
  showModal: showModal,
  hideModal: hideModal,
  setFormInWindow: setFormInWindow
};

const mapStateToProps = state => ({
  chats: state.chats,
  route: state.route
});

const mapDataToProps = (state, props) => {
  console.log("chat in table ", state);

  return {
    chats: state.chats.componentInWindow,
    route: state.route
  };
};

export default connect(mapStateToProps, actions)(ChatMessageTableForWindow);
