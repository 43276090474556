import React, { Component } from "react";
import { Route, Switch, Router } from "react-router-dom";
import history from "../Services/History";
import MessengerContainer from "../Containers/MessengerContainer";
import FormContainer from "../Containers/FormContainer";
import ChatMessageTableForWindow from "./chats/ChatMessageTableForWindow";
import ChatMessageMapForWindow from "./chats/ChatMessageMapForWindow";
import PaymentFormView from "./content/forms/PaymentFormView";

class RouterComponent extends Component {
  render() {
    return (
      <Router history={history}>
        <div className="App-wrapper">
          <Switch>
            {/* <Route
              path="/"
              render={() => {
                return <div className="d-flex">Home</div>;
              }}
            /> */}

            {/* <Route exact path="/:type/:botId" component={LinkableMessage} /> */}
            <Route exact path="/chats" component={MessengerContainer} />
            {/* <RouterPathComponent exact path="/form" component={FormContainer} /> */}

            <Route excat path="/form" component={FormContainer} />
            <Route excat path="/table" component={ChatMessageTableForWindow} />
            <Route excat path="/map" component={ChatMessageMapForWindow} />
            <Route excat path="/payment" component={PaymentFormView} />
          </Switch>
        </div>
      </Router>
    );
  }
}
// :type/:botId
export default RouterComponent;
