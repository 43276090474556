export const MAKE_CALL = "MAKE_CALL";
export const RESET_CALL_INFO = "RESET_CALL_INFO";

export function makeCall(
  type,
  from,
  to,
  toName,
  toUserId,
  localCall,
  diallerState
) {
  return {
    type: MAKE_CALL,
    data: {
      type,
      from,
      to,
      toName,
      toUserId,
      localCall,
      diallerState
    }
  };
}

export function resetCallInfo(type, from, to, toName) {
  return {
    type: RESET_CALL_INFO
  };
}
