import _ from "lodash";
import {
  TIME_LINE_RECEIVED,
  ADD_NEW_CONVERSATION,
  CHANGE_SELECTED_CONVERSATION,
  ARCHIVED_MESSAGES_RECEIVED,
  CLOSE_CONTENT_AREA,
  CLOSE_SMART_REPLY,
  OPEN_CONTENT_AREA,
  NEW_LINK_RECEIVED,
  RESET_LINK,
  TOGGLE__FAVORITE_CONVERSATION,
  HIDE_TIMELINE,
  SHOW_TIMELINE,
  NEW_SERVER_MESSAGE_RECEIVED,
  NEW_CLIENT_MESSAGE_RECEIVED,
  NEW_SERVER_FORM_CLOSE_MESSAGE_RECEIVED,
  CLOSE_SEARCH_BOX,
  SEND_SEARCH_BOX_QUERY,
  SET_FORM_IN_POPUP,
  SET_WINDOW,
  SET_WINDOW_MIN_MAX,
  NEW_CONTACT_ACCEPT_IGNORE_MESSAGE__RECEIVED,
  CLEAR_ACCEPT_IGNORE_CONTACT,
  GET_FAVOURITES,
  CONVERSATION_SCROLL_POSITION,
  REMOVE_FROM_WINDOW,
  SHOW_CHAT_NON_CONVERSATIONAL,
  TOGGLE_TOP_NAV_BAR,
  TOGGLE_SIDE_NAV_BAR,
  REMOVE_FROM_SHOW_CARDS_ONLY,
  REMOVE_HTML_CONTENT,
  REMOVE_CARDS_CONTENT,
  REMOVE_FORM_IN_POPUP,
  RESET_MESSAGE_COUNT,
  SET_WINDOW_ROUTE,
  REMOVE_WINDOW_ROUTE
  // NO_ACTION
} from "../actions/chats";
import {
  updateConversationMetaData,
  placeMessageBasedOnTime,
  shouldShowSliderArea,
  shouldShowSpinner,
  sortByModifiedOn,
  getConversationIndex,
  getConversationById,
  shouldReplaceMessage,
  shouldReplaceMessageByType,
  REPLACEMENT_ENABLED_FOR
} from "../../Services/InteractionsService";
import { MessageTypeConstants } from "../../Services/Message";
import { IM_BOT } from "../../Utils/Constants";
import { LOGOUT_USER } from "../actions/user";
import { storeSelectedConversation } from "../../Services/StorageService";

let initialContentState = {
  contentMessage: null,
  smartReplyMessage: null,
  disableMessageInput: false,
  mainChatHeight: "calc(100vh - 71px - 90px)"
};

// let linkData = {
//   type: null,
//   botId: null,
//   message: null,
//   action: null
// };

let initialState = {
  userId: null,
  timeLine: [],
  chatLog: new Map(),
  selectedConversation: null,
  ...initialContentState,
  linkData: null,
  hiddenTimeLine: false,
  formInPopup: null,
  componentInWindow: {},
  acceptIgnoreContact: null,
  conversationPaginationParameterMap: {},
  shouldScrollToTopConversationMap: {},
  conversationModeMap: {},
  navigationBar: {
    sidebar: {},
    navbar: {}
  },
  showOnlyCards: [],
  showHTMLContent: ""

  // ,
  // searchBoxMessage: {
  //   selectionType: "single",
  //   action: "results",
  //   results: [
  //     {
  //       text: "search Text1",
  //       info: {
  //         field1: "aaa",
  //         field2: "bbb"
  //       }
  //     },
  //     {
  //       text: "search Text2",
  //       info: {
  //         field1: "aaa",
  //         field2: "bbb"
  //       }
  //     }
  //   ]
  // }
};

function chatsReducer(state = initialState, action) {
  let timeLine = [];
  switch (action.type) {
    case GET_FAVOURITES:
      let fav = [...action.data.getFavourites.favourites];
      let peopleFav = [...action.data.getFavourites.favouritePeople];
      return { ...state, favourites: fav, peopleFav };
    case TIME_LINE_RECEIVED:
      timeLine = action.data.timeLine || [];
      let chatLog = state.chatLog;
      timeLine.forEach(function(conversation) {
        chatLog.set(conversation.conversationId, []);
      });
      return { ...state, timeLine: timeLine, userId: action.data.userId };

    case ADD_NEW_CONVERSATION: {
      let { timeLine, chatLog } = state;
      let { conversation } = action.data;
      let exConversation = getConversationById(
        state,
        conversation.conversationId
      );
      if (exConversation) {
        return state;
      }
      chatLog.set(conversation.conversationId, []);
      return { ...state, timeLine: [conversation].concat(timeLine), chatLog };
    }

    case CHANGE_SELECTED_CONVERSATION: {
      let { conversation, toTop } = action.data;
      let { timeLine, chatLog } = state;
      if (!conversation) {
        return {
          ...state,
          selectedConversation: {
            conversationId: "99999999999999999999999999999999"
          }
        };
      }
      let index = timeLine.findIndex(con => {
        return con.conversationId === conversation.conversationId;
      });
      let clonedConversation = { ...conversation, newMessagesCount: 0 };

      let partOfState = {};
      if (
        clonedConversation.bot &&
        clonedConversation.bot.botId === IM_BOT &&
        !clonedConversation.channel
      ) {
        let chatMessages = chatLog.get(conversation.conversationId);
        let lastMessage =
          !_.isEmpty(chatMessages) && chatMessages[chatMessages.length - 1];
        if (
          lastMessage &&
          state.userId !== lastMessage.createdBy &&
          lastMessage.messageType ===
            MessageTypeConstants.MESSAGE_TYPE_ACCEPT_IGNORE_CONTACT
        ) {
          partOfState.acceptIgnoreContact = lastMessage;
        } else {
          partOfState.acceptIgnoreContact = null;
        }
      } else {
        partOfState.acceptIgnoreContact = null;
      }

      if (toTop) {
        if (index !== -1) {
          timeLine.splice(index, 1);
        }

        return {
          ...state,
          timeLine: [clonedConversation].concat(timeLine),
          selectedConversation: clonedConversation,
          ...initialContentState,
          showWaitSpinner: false,
          searchBoxMessage: null,
          ...partOfState
        };
      } else {
        if (index !== -1) {
          timeLine[index] = clonedConversation;
        }
      }

      return {
        ...state,
        timeLine: [].concat(timeLine),
        selectedConversation: clonedConversation,
        ...initialContentState,
        showWaitSpinner: false,
        searchBoxMessage: null,
        ...partOfState
      };
    }

    case NEW_SERVER_MESSAGE_RECEIVED:
      let { conversationId, message } = action.data;
      let { messageType } = message;
      console.log("New server Message to be received :: ::", message);

      let conversationModeMapUpdated = state.conversationModeMap;
      let conversationalMode = conversationModeMapUpdated[conversationId];

      let selectedConversation = state.selectedConversation;
      chatLog = state.chatLog;
      timeLine = state.timeLine;
      let messages = chatLog.get(conversationId) || [];

      if (messageType === MessageTypeConstants.MESSAGE_TYPE_WAIT) {
        let lastMessage =
          messages && messages.length > 0
            ? messages[messages.length - 1]
            : null;
        let checkingWait = shouldShowSpinner(
          message,
          conversationId,
          selectedConversation,
          lastMessage
        );

        if (
          shouldShowSpinner(
            message,
            conversationId,
            selectedConversation,
            lastMessage
          )
        ) {
          console.log("Webapp :: shouldShowSpinner returned true");
          return { ...state, showWaitSpinner: true };
        } else {
          // console.log("Webapp :: shouldShowSpinner returned false");
          return state;
        }
      } else if (messageType === MessageTypeConstants.MESSAGE_TYPE_DATA) {
        return state;
      } else if (messageType === MessageTypeConstants.MESSAGE_TYPE_RUNMODE) {
        console.log(
          "MESSAGE_TYPE_RUNMODE bot  ====== ",
          message.message.suggestionsLayout
        );
        let sidebar = state.navigationBar.sidebar;
        let navbar = state.navigationBar.navbar;
        let chatButtonHidden = false;
        if (message.message.sidebar) {
          sidebar = { ...message.message.sidebar };
        }
        if (message.message.navbar) {
          navbar = { ...message.message.navbar };
        }

        if (message.message.chatButtonHidden) {
          chatButtonHidden = message.message.chatButtonHidden;
        }
        let navigationBar = {
          sidebar,
          navbar
        };
        selectedConversation["chatButtonHidden"] = chatButtonHidden;
        let conversationModeMapUpdated = state.conversationModeMap;
        conversationModeMapUpdated[conversationId] = {
          chatViewMode: MessageTypeConstants.MESSAGE_TYPE_RUNMODE,
          background: message.message.background || {},
          conversational: message.message.conversational,
          suggestionsLayout: message.message.suggestionsLayout
            ? message.message.suggestionsLayout
            : "horizontal"
        };
        return {
          ...state,
          selectedConversation: selectedConversation,
          conversationModeMap: conversationModeMapUpdated,
          navigationBar: navigationBar,
          showWaitSpinner: false
        };
      } else if (
        conversationalMode &&
        !conversationalMode.conversational &&
        messageType === MessageTypeConstants.MESSAGE_TYPE_CARDS
      ) {
        if (Array.isArray(message.message)) {
          return {
            ...state,
            showOnlyCards: [...message.message],
            showWaitSpinner: false
          };
        }
        return { ...state, showWaitSpinner: false };
      } else if (messageType === MessageTypeConstants.MESSAGE_TYPE_HTML) {
        return {
          ...state,
          showHTMLContent: message.message,
          showWaitSpinner: false
        };
      } else if (
        conversationalMode &&
        !conversationalMode.conversational &&
        messageType === MessageTypeConstants.MESSAGE_TYPE_MAP
      ) {
        let options = message.options;

        if (
          conversationalMode.background &&
          conversationalMode.background.content &&
          conversationalMode.background.content.options &&
          conversationalMode.background.content.options.mapId === options.mapId
        ) {
          let newContent = { ...message.message, options };
          let updatedConversationalMode = {
            ...conversationalMode,
            background: {
              ...conversationalMode.background,
              content: newContent
            }
          };
          return {
            ...state,
            conversationModeMap: {
              ...state.conversationModeMap,
              [conversationId]: updatedConversationalMode
            }
          };
        }
        return state;
      } else if (messageType === MessageTypeConstants.MESSAGE_TYPE_SEARCH_BOX) {
        return {
          ...state,
          searchBoxMessage: { ...message, showSpinner: false }
        };
      } else if (shouldShowSliderArea(messageType)) {
        let newState = { ...state };
        if (
          conversationId &&
          selectedConversation.conversationId &&
          conversationId === selectedConversation.conversationId
        ) {
          newState = {
            ...state,
            smartReplyMessage: message,
            showWaitSpinner: false
          };
        }
        newState.timeLine = updateConversationMetaData(
          timeLine,
          selectedConversation,
          conversationId,
          message
        );
        return newState;
      } else {
        let replaceIndex = shouldReplaceMessageByType(messages, message);
        // console.log("chat reducers for forms", replaceIndex);
        if (replaceIndex !== -1) {
          let newMessage = JSON.parse(JSON.stringify(message));
          messages[replaceIndex] = newMessage;
          chatLog.set(conversationId, [...messages]);
          let newState = {
            ...state,
            chatLog, // messageType: "Runmode",
            showWaitSpinner: false
          };
          // conversational: true
          if (state.contentMessage) {
            let cm = state.contentMessage;
            let rep = REPLACEMENT_ENABLED_FOR[newMessage.messageType];
            if (rep) {
              let matcher = rep.matcher;
              if (matcher && matcher(cm, newMessage)) {
                newState.contentMessage = newMessage;
              }
            }
          }
          return newState;
        } else if (messageType === MessageTypeConstants.MESSAGE_TYPE_FORM2) {
          let { options } = message;
          // console.log("for/m message :: ::", message, conversationalMode);

          if (
            !_.isEmpty(conversationalMode) &&
            !conversationalMode.conversational
          ) {
            const componentInWindow = state.componentInWindow;
            if (!_.isEmpty(componentInWindow)) {
              const windowIndexToUpdate = getUpdateIndexFromComponentInWindow(
                conversationId,
                componentInWindow,
                options
              );

              if (windowIndexToUpdate === -1) {
                componentInWindow[conversationId] =
                  componentInWindow[conversationId] &&
                  componentInWindow[conversationId].length > 0
                    ? componentInWindow[conversationId]
                    : [];
                componentInWindow[conversationId].push(message);
              } else {
                if (
                  message.options.action === "results" ||
                  message.options.action === "validation"
                ) {
                  const windowObjectToUpdate =
                    componentInWindow[conversationId][windowIndexToUpdate];
                  const messages = windowObjectToUpdate.message;
                  if (message.message.hasOwnProperty("field")) {
                    for (let i = 0; i < messages.length; i++) {
                      let m = messages[i];
                      if (m.id === message.message.field) {
                        if (message.message.results) {
                          m.showSpinner = false;
                          m.results = message.message.results;
                        }
                        if (
                          message.message.hasOwnProperty("validationResult")
                        ) {
                          m.validationResult = message.message.validationResult;
                        }
                        if (message.message.validationMessage) {
                          m.validationMessage =
                            message.message.validationMessage;
                        }
                        break;
                      }
                    }
                  } else {
                    windowObjectToUpdate.genericError = {
                      ...message.message
                    };
                  }
                  componentInWindow[conversationId][
                    windowIndexToUpdate
                  ] = windowObjectToUpdate;
                } else if (message.options.action === "change") {
                  const windowObjectToUpdate =
                    componentInWindow[conversationId][windowIndexToUpdate];
                  let messages = windowObjectToUpdate.message;
                  const fieldsToUpdate = message.message.fields;
                  const fieldsToRemove = message.message.remove;

                  if (!_.isEmpty(fieldsToRemove)) {
                    windowObjectToUpdate.message = messages.filter(message => {
                      let mId = message.id;
                      return fieldsToRemove.indexOf(mId) !== -1;
                    });
                  }

                  if (!_.isEmpty(fieldsToUpdate)) {
                    windowObjectToUpdate.message = messages.map(message => {
                      let mId = message.id;
                      for (let i = 0; i < fieldsToUpdate.length; i++) {
                        const incomingField = fieldsToUpdate[i];
                        if (incomingField.id === mId) {
                          return incomingField;
                        }
                      }
                      return message;
                    });
                  }

                  componentInWindow[conversationId][
                    windowIndexToUpdate
                  ] = windowObjectToUpdate;
                }
              }
            } else {
              componentInWindow[conversationId] = [];
              componentInWindow[conversationId].push(message);
            }
            let updatedComponentInWindow = Object.assign({}, componentInWindow);
            return {
              ...state,
              componentInWindow: updatedComponentInWindow,
              showWaitSpinner: false
            };
          } else if (
            !_.isEmpty(conversationalMode) &&
            conversationalMode.conversational
          ) {
            if (
              options &&
              options.formId &&
              state.componentInWindow &&
              state.componentInWindow.options &&
              options.formId === state.componentInWindow.options.formId
            ) {
              let newObj = {};
              if (
                message.options.action === "results" ||
                message.options.action === "validation"
              ) {
                let currentMessage = state.componentInWindow;
                let cmm = currentMessage.message;
                for (let i = 0; i < cmm.length; i++) {
                  let m = cmm[i];
                  if (m.id === message.message.field) {
                    if (message.message.results) {
                      m.showSpinner = false;
                      m.results = message.message.results;
                    }
                    if (message.message.hasOwnProperty("validationResult")) {
                      m.validationResult = message.message.validationResult;
                    }
                    if (message.message.validationMessage) {
                      m.validationMessage = message.message.validationMessage;
                    }
                    break;
                  }
                }
                newObj = Object.assign({}, { ...state.componentInWindow });
              } else {
                newObj = Object.assign(
                  {},
                  { ...state.componentInWindow },
                  { ...message }
                );
              }
              return {
                ...state,
                formInPopup: newObj,
                componentInWindow: newObj,
                showWaitSpinner: false
              };
            } else if (
              message.options.action === "results" ||
              message.options.action === "validation"
            ) {
              // let newObj = Object.assign(
              //   {},
              //   { ...state.formInPopup },
              //   { ...message }
              // );
              return {
                ...state,
                formInPopup: { ...message },
                componentInWindow: { ...message },
                showWaitSpinner: false
              };
            }
          }
        }

        placeMessageBasedOnTime(messages, message);
        chatLog.set(conversationId, [].concat(messages));
        let newState = { ...state, chatLog: chatLog };
        if (
          selectedConversation &&
          conversationId === selectedConversation.conversationId
        ) {
          newState = { ...state, chatLog: chatLog, showWaitSpinner: false };
        }
        newState.timeLine = updateConversationMetaData(
          timeLine,
          selectedConversation,
          conversationId,
          message
        );
        return newState;
      }
    case RESET_MESSAGE_COUNT:
      return { ...state, timeLine: action.data };
    case REMOVE_HTML_CONTENT:
      return { ...state, showHTMLContent: null };

    case NEW_SERVER_FORM_CLOSE_MESSAGE_RECEIVED:
      conversationId = action.data.conversationId;
      let actionMessage = action.data.message;
      chatLog = state.chatLog;
      timeLine = state.timeLine;
      messages = chatLog.get(conversationId);
      messages.forEach((message, index) => {
        if (
          message.messageType === MessageTypeConstants.MESSAGE_TYPE_FORM2 &&
          message.options &&
          message.options.formId === actionMessage.message.formId
        ) {
          message.options.stage = "COMPLETED";
          messages[index] = JSON.parse(JSON.stringify(message));
        }
      });
      chatLog.set(conversationId, [...messages]);
      return { ...state, chatLog };

    case NEW_CLIENT_MESSAGE_RECEIVED:
      conversationId = action.data.conversationId;
      message = action.data.message;
      chatLog = state.chatLog;
      messages = chatLog.get(conversationId);

      if (message.messageId && shouldReplaceMessage(message)) {
        let index = messages.findIndex(
          msg => msg.messageId === message.messageId
        );
        if (index !== -1) {
          messages = [
            ...messages.slice(0, index),
            message,
            ...messages.slice(index + 1)
          ];
        } else {
          messages = [...messages, message];
        }
      } else {
        messages = [...messages, message];
      }
      chatLog.set(conversationId, messages);

      let newState = { ...state, chatLog };
      newState.timeLine = updateConversationMetaData(
        state.timeLine,
        state.selectedConversation,
        conversationId,
        message
      );
      return newState;

    case ARCHIVED_MESSAGES_RECEIVED: {
      let {
        conversationId,
        messages = [],
        moreMessagesExist,
        userId
      } = action.data;

      let { chatLog, timeLine, conversationPaginationParameterMap } = state;
      let exMessages = chatLog.get(conversationId) || [];
      // console.log("exMessages count :: ", exMessages ? exMessages.length : 0);
      // console.log("rcvd messages  count :: ", messages ? messages.length : 0);
      const firstMessageCreatedOnInReceivedList =
        messages.length > 0 ? messages[messages.length - 1].createdOn : "";
      conversationPaginationParameterMap[conversationId] = {
        hasMoreMessages: moreMessagesExist || false,
        createdOn: firstMessageCreatedOnInReceivedList
      };
      let allMessages = messages.concat(exMessages);
      let messagesMap = {},
        outMessages = [];
      allMessages.forEach(m => {
        if (m && m.messageId) {
          if (!messagesMap[m.messageId]) {
            outMessages.push(m);
            messagesMap[m.messageId] = true;
          }
        }
      });
      outMessages.sort(function(a, b) {
        return (
          new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()
        );
      });
      // console.log(
      //   "outMessages count :: ",
      //   outMessages ? outMessages.length : 0
      // );
      chatLog.set(conversationId, [].concat(outMessages));
      let index = getConversationIndex(timeLine, conversationId);
      let newTimeLine = [...timeLine];
      newTimeLine[index] = {
        ...getConversationById(state, conversationId),
        archivedMessagesRead: true
      };

      let lastMessage = outMessages[outMessages.length - 1];
      if (
        lastMessage &&
        userId !== lastMessage.createdBy &&
        lastMessage.messageType ===
          MessageTypeConstants.MESSAGE_TYPE_ACCEPT_IGNORE_CONTACT
      ) {
        return {
          ...state,
          chatLog,
          timeLine: newTimeLine,
          acceptIgnoreContact: lastMessage,
          conversationPaginationParameterMap
        };
      }

      return {
        ...state,
        chatLog,
        timeLine: newTimeLine,
        conversationPaginationParameterMap
      };
    }

    case CONVERSATION_SCROLL_POSITION: {
      const { status, conversation } = action.data;
      if (conversation) {
        let updatedShouldScrollToTopConversationMap =
          state.shouldScrollToTopConversationMap;
        updatedShouldScrollToTopConversationMap[
          conversation.conversationId
        ] = status;
        return {
          ...state,
          shouldScrollToTopConversationMap: {
            ...updatedShouldScrollToTopConversationMap
          }
        };
      }
      return { ...state };
    }

    case CLOSE_CONTENT_AREA:
      return { ...state, contentMessage: null, disableMessageInput: false };

    case CLOSE_SMART_REPLY:
      return { ...state, smartReplyMessage: null };

    case CLOSE_SEARCH_BOX:
      return { ...state, searchBoxMessage: null };

    case SEND_SEARCH_BOX_QUERY:
      return {
        ...state,
        searchBoxMessage: { ...state.searchBoxMessage, showSpinner: true }
      };

    case OPEN_CONTENT_AREA:
      return { ...state, ...action.data };

    case SET_FORM_IN_POPUP: {
      let { chat } = action.data;
      return { ...state, formInPopup: chat };
    }

    case REMOVE_FORM_IN_POPUP:
      return { ...state, formInPopup: null, showWaitSpinner: false };

    case SET_WINDOW: {
      return { ...state, componentInWindow: { ...action.data } };
    }
    case SET_WINDOW_ROUTE: {
      return { ...state, componentInWindow: { ...action.data } };
    }
    case REMOVE_WINDOW_ROUTE: {
      return {
        ...state,
        componentInWindow: {}
      };
    }
    case SET_WINDOW_MIN_MAX: {
      return { ...state, componentInWindow: { ...action.data } };
    }
    case REMOVE_FROM_WINDOW: {
      return {
        ...state,
        componentInWindow: { ...action.data },
        showWaitSpinner: false
      };
    }
    case NEW_CONTACT_ACCEPT_IGNORE_MESSAGE__RECEIVED:
      if (
        state.selectedConversation &&
        action.data.conversationId === state.selectedConversation.conversationId
      ) {
        return { ...state, acceptIgnoreContact: action.data.message };
      }
      return state;

    case CLEAR_ACCEPT_IGNORE_CONTACT:
      return { ...state, acceptIgnoreContact: null };

    case NEW_LINK_RECEIVED:
      return {
        ...state,
        linkData: {
          type: action.data.type,
          botId: action.data.botId,
          message: action.data.message,
          action: action.data.action
        }
      };

    case RESET_LINK:
      return { ...state, linkData: null };

    case TOGGLE__FAVORITE_CONVERSATION:
      timeLine = state.timeLine;
      let index = timeLine.findIndex(
        t => t.conversationId === action.data.conversationId
      );
      let conversation = timeLine[index];
      timeLine[index] = { ...conversation, favourite: action.data.favourite };
      sortByModifiedOn(timeLine);

      let stateData = { ...state, timeLine: [].concat(timeLine) };
      let selected = state.selectedConversation;
      if (selected.conversationId === conversation.conversationId) {
        stateData.selectedConversation = {
          ...conversation,
          favourite: action.data.favourite
        };
      }
      return stateData;

    case HIDE_TIMELINE:
      return { ...state, hiddenTimeLine: true };

    case SHOW_TIMELINE:
      return { ...state, hiddenTimeLine: true };

    case SHOW_CHAT_NON_CONVERSATIONAL:
      let updatedConversationModeMap = state.conversationModeMap;
      let conversationSelected = state.selectedConversation;
      if (_.isEmpty(conversationSelected)) {
        return { ...state };
      }
      let convId = conversationSelected.conversationId;
      updatedConversationModeMap[convId] = {
        ...updatedConversationModeMap[convId],
        showChat: action.data || false
      };
      return {
        ...state,
        conversationModeMap: updatedConversationModeMap
      };
    case REMOVE_FROM_SHOW_CARDS_ONLY:
      return { ...state, showOnlyCards: [...action.data] };
    case REMOVE_CARDS_CONTENT:
      return { ...state, showOnlyCards: [] };
    case TOGGLE_TOP_NAV_BAR:
      let navbar = Object.assign({}, state.navigationBar.navbar);
      navbar["hidden"] = !navbar.hidden;
      return {
        ...state,
        navigationBar: {
          ...state.navigationBar,
          navbar
        }
      };

    case TOGGLE_SIDE_NAV_BAR:
      let sidebar = Object.assign({}, state.navigationBar.sidebar);
      sidebar["hidden"] = !sidebar.hidden;
      return {
        ...state,
        navigationBar: {
          ...state.navigationBar,
          sidebar
        }
      };
    // case NO_ACTION:
    //   return state;
    case LOGOUT_USER:
      return {
        ...initialState,
        conversationPaginationParameterMap: {},
        shouldScrollToTopConversationMap: {},
        conversationModeMap: {}
      };

    default:
      return state;
  }
}

const getUpdateIndexFromComponentInWindow = (
  conversationId,
  componentInWindow,
  options
) => {
  const windowObject = componentInWindow[conversationId];
  if (!_.isEmpty(windowObject)) {
    for (let index = 0; index < windowObject.length; index++) {
      const message = windowObject[index];
      if (message.options && message.options.formId === options.formId) {
        return index;
      }
    }
  }
  return -1;
};
export default chatsReducer;
