import React, { Component } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
  injectStripe
} from "react-stripe-elements";
import Spinner from "react-spinkit";

const createOptions = (fontSize = "12px", padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: "#424770",
        letterSpacing: "0.025em",
        "::placeholder": {
          color: "#aab7c4"
        },
        padding
      },
      invalid: {
        color: "#9e2146"
      }
    }
  };
};

class StripeCheckoutForm extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  handleSubmit = e => {
    e.preventDefault();
    console.log("start");
    this.setState({ loading: true });
    let { stripe } = this.props;
    // console.log("let see the stripe", stripe);

    stripe
      .createPaymentMethod("card", this.element, {
        billing_details: { name: this.props.user.userName }
      })
      .then(result => {
        // console.log("stripe will see result ", result);

        if (result.error) {
          throw new Error(result.error.message);
        }
        return this.props.startPayment(result.paymentMethod.id);
      })
      .then(response => {
        // console.log("after initial payment ", response);

        let { success, errorMessage, actionRequired, clientSecret } = response;
        if (success) {
          // this.props.sendPaymentResponseMessage(true);
          return { success: true };
        }

        if (actionRequired) {
          return stripe.handleCardAction(clientSecret);
        }

        throw new Error(errorMessage);
      })
      .then(response => {
        if (response.error) {
          throw new Error(response.error.message);
        }
        if (response.success) {
          return response;
        }
        return this.props.confirmPayment(response.paymentIntent.id);
      })
      .then(response => {
        // console.log("after confirm payment ", response);

        let { success, errorMessage } = response;
        if (success) {
          this.props.sendPaymentResponseMessage(true);
          this.setState({ loading: false });
          return;
        }

        throw new Error(errorMessage);
      })
      .catch(error => {
        this.setState({ loading: false });
        this.props.sendPaymentResponseMessage(false);
      });
  };

  handleReady = element => {
    this.element = element;
  };

  render() {
    let { loading } = this.state;
    console.log("stripecheckout ", this.props);

    return (
      <div className="checkout" id="stripe-checkout">
        <form onSubmit={this.handleSubmit}>
          <div className="d-flex flex-column">
            <label>
              Card number
              <CardNumberElement
                {...createOptions(this.props.fontSize)}
                onReady={this.handleReady}
              />
            </label>
            <label>
              Expiration date
              <CardExpiryElement {...createOptions(this.props.fontSize)} />
            </label>
            <label>
              CVC
              <CardCVCElement {...createOptions(this.props.fontSize)} />
            </label>
            <div>
              {!loading && <button className="btn btn-open">Pay</button>}
              {loading && <Spinner name="circle" color="steelblue" />}
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default injectStripe(StripeCheckoutForm);
