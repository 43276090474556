export default {
  envName: "rackspaceProd",
  baseURL: "https://api.frontm.ai",
  filesAPI: "https://frontm.ai/resource",
  contentURL: "https://frontm.ai/botLogos/",
  botFilesAPI: "https://frontm.ai/resource/botfile?path=",
  gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
  paymentGatewayKey: "pk_live_Oyu2NEXa9MmN6Kq0POcBHz9J",
  soundURL: "https://frontm.ai/sounds/",
  mapboxApiAccessToken:
    "pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
  gRPCURL: "https://prod.frontm.ai:8080",
  webertcSignalServer: "frontm.ai",
  mapURL: "https://maps.frontm.ai/styles/klokantech-basic/style.json",
  gnsDomain: "voyagervoice",
  aageHempelDomain: "aagehempelEndUser",
  onshipDomain: "onshipEndUser",
};
