// const Config = (env = "dev") => {
//   switch (env) {
//     case "dev":
//       return {
//         envName: "dev",
//         baseURL: "https://elbdev.frontm.ai", // filesAPI: "https://3nf11ibj25.execute-api.us-east-1.amazonaws.com/dev",
//         filesAPI: "https://3nf11ibj25.execute-api.us-east-1.amazonaws.com/dev", // contentURL:
//         //   "https://s3.amazonaws.com/frontm-contentdelivery-mobilehub-1030065648/botLogos/",
//         contentURL: "https://devproxy.frontm.ai/botLogos/",
//         gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
//         paymentGatewayKey: "pk_test_tXlIyqK3p7LCtcF9JGwDgW0C",
//         mapboxApiAccessToken:
//           "pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
//         gRPCURL: "https://grpcdev.frontm.ai:8080",
//         webertcSignalServer: "webrtcdev.frontm.com",
//       }; // contentURL: 'https://s3.amazonaws.com/frontm-contentdelivery/botLogos/',

//     case "stage":
//       return {
//         envName: "stage",
//         baseURL: "https://stage.frontm.ai",
//         filesAPI:
//           "https://6uj792e685.execute-api.us-east-1.amazonaws.com/stage",
//         contentURL: "https://s3.amazonaws.com/frontm-contentdelivery/botLogos/",
//         gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
//         paymentGatewayKey: "pk_test_tXlIyqK3p7LCtcF9JGwDgW0C",
//         mapboxApiAccessToken:
//           "pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
//         gRPCURL: "https://stage.frontm.ai:8080",
//       };

//     case "prod":
//       return {
//         envName: "prod",
//         baseURL: "https://api.frontm.ai",
//         filesAPI: "https://eg4dh0bm07.execute-api.us-east-1.amazonaws.com/prod",
//         contentURL:
//           "https://s3.amazonaws.com/prod-frontm-contentdelivery/botLogos/",
//         gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
//         paymentGatewayKey: "pk_live_Oyu2NEXa9MmN6Kq0POcBHz9J",
//         mapboxApiAccessToken:
//           "pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
//         gRPCURL: "https://prod.frontm.ai:8080",
//         webertcSignalServer: "devproxy.frontm.ai",
//       };

//     case "rackspaceProd":
//       return {
//         envName: "rackspaceProd",
//         baseURL: "https://api.frontm.ai",
//         filesAPI:
//           "https://vrlj80akxj.execute-api.us-east-1.amazonaws.com/rackspaceProd",
//         contentURL:
//           "https://s3.amazonaws.com/592975064982-frontm-contentdelivery-production/botLogos/",
//         gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
//         paymentGatewayKey: "pk_live_Oyu2NEXa9MmN6Kq0POcBHz9J",
//         mapboxApiAccessToken:
//           "pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
//         gRPCURL: "https://prod.frontm.ai:8080",
//         webertcSignalServer: "frontm.ai",
//       };

//     case "local":
//       return {
//         envName: "local",
//         baseURL: "http://localhost:4000",
//         filesAPI: "https://3nf11ibj25.execute-api.us-east-1.amazonaws.com/dev",
//         contentURL: "https://s3.amazonaws.com/frontm-contentdelivery/botLogos/",
//         gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
//         paymentGatewayKey: "pk_test_tXlIyqK3p7LCtcF9JGwDgW0C",
//         mapboxApiAccessToken:
//           "pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
//         gRPCURL: "http://localhost:8080",
//       };

//     default:
//       return {
//         envName: "dev",
//         baseURL: "https://elbdev.frontm.ai",
//         filesAPI: "https://3nf11ibj25.execute-api.us-east-1.amazonaws.com/dev",
//         contentURL: "https://s3.amazonaws.com/frontm-contentdelivery/botLogos/",
//         gmapsApiKey: "AIzaSyA21aJlgn2xSL1bmpSpGqBWWuInLp99m1k",
//         paymentGatewayKey: "pk_test_tXlIyqK3p7LCtcF9JGwDgW0C",
//         mapboxApiAccessToken:
//           "pk.eyJ1IjoiZ2FjaWx1IiwiYSI6ImNqcHh0azRhdTFjbXQzeW8wcW5vdXhlMzkifQ.qPfpVkrWbk-GSBY3uc6z3A",
//         gRPCURL: "https://grpcdev.frontm.ai:8080",
//       };
//   }
// };

// export default Config("dev");

import devConfig from "./envConfig/dev.config";
import prodConfig from "./envConfig/prod.config";
const env = process.env.REACT_APP_ENV || "development";
const config = {
  development: devConfig,
  production: prodConfig,
};
export default config[env];
