import React, { Component } from "react";
import ChatOthers from "../chats/ChatOthers";
import ChatSelf from "../chats/ChatSelf";
import ChatAsLink from "../chats/ChatAsLink";
import { MessageTypeConstants } from "./../../Services/Message";
import HighlightChatMessage from "../content/HighlightChatMessage";
import { shouldReplaceMessage } from "../../Services/InteractionsService";
import NotificationMessage from "../chats/NotificationMessage";

export function urlifyText(text) {
  if (!text) {
    return null;
  }
  let urlRegex = RegExp("https?://[^s]+");
  if (!urlRegex.test(text)) {
    return text;
  }

  let parts = text.split(" ");
  return parts.map((part, index) => {
    if (!urlRegex.test(part)) {
      return <span>{part + (index < parts.length ? " " : "")}</span>;
    }

    if (urlRegex.test(part)) {
      return (
        <span>
          <a
            style={{ textDecoration: "underline", color: "inherit" }}
            href={part}
            target="_blank"
            rel="noopener noreferrer"
          >
            {part}
          </a>{" "}
          {index < parts.length ? " " : ""}
        </span>
      );
    }

    // return part.replace(urlRegex, function(url) {
    //
    // });
  });
}

function showAsMessage(type) {
  return (
    type === MessageTypeConstants.MESSAGE_TYPE_STRING ||
    type === MessageTypeConstants.MESSAGE_TYPE_WAIT ||
    type === MessageTypeConstants.MESSAGE_TYPE_BUTTON_RESPONSE ||
    type === MessageTypeConstants.MESSAGE_TYPE_CONTACT_CARD
  );
}

function showAsNotification(type) {
  return (
    type === MessageTypeConstants.MESSAGE_TYPE_STD_NOTIFICATION ||
    type === MessageTypeConstants.MESSAGE_TYPE_CRITICAL_NOTIFICATION
  );
}

class ChatMessage extends Component {
  shouldComponentUpdate(nextProps) {
    let oldChat = this.props.chat,
      newChat = nextProps.chat;
    if (
      oldChat.messageId !== newChat.messageId ||
      shouldReplaceMessage(newChat) ||
      this.props.active !== nextProps.active ||
      this.props.isContentShown !== nextProps.isContentShown
    ) {
      return true;
    }
    return false;
  }

  render() {
    let {
      background,
      conversation,
      chat,
      openContent,
      self,
      highlight,
      setHighlightedElementScrollHeight,
      active,
      closeContent,
      isContentShown,
      saveContactPopUp
    } = this.props;
    // console.log("chat messages =======", chat.messageType);

    if (!chat.messageType) {
      return null;
    }

    if (showAsNotification(chat.messageType)) {
      return <NotificationMessage chat={chat} />;
    } else {
      if (showAsMessage(chat.messageType)) {
        if (chat.createdBy === self) {
          return <ChatSelf chat={chat} openContent={openContent} />;
        } else {
          return (
            <HighlightChatMessage
              highlight={highlight}
              setHighlightedElementScrollHeight={
                setHighlightedElementScrollHeight
              }
            >
              <ChatOthers
                chat={chat}
                conversation={conversation}
                openContent={openContent}
                saveContactPopUp={(elem, index) => {
                  saveContactPopUp(elem, index);
                }}
              />
            </HighlightChatMessage>
          );
        }
      } else {
        return (
          <ChatAsLink
            background={background}
            self={chat.createdBy === self}
            chat={chat}
            conversation={conversation}
            openContent={openContent}
            active={active}
            closeContent={closeContent}
            isContentShown={isContentShown}
          />
        );
      }
    }
  }
}

export default ChatMessage;
