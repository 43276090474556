export const ADMINISTRATOR = "ADMIN";
export const DEVELOPER = "DEVELOPER";
export const TESTER = "QA";
export const ENDUSER = "ENDUSER";
export const FRONTM_DOMAIN = "frontmai";
export const IM_BOT = "im-bot";
export const FRONTM_BOT_ID = "onboarding-bot";
export const BANNER_TYPE_INFO = "infoMsg";
export const BANNER_TYPE_ERROR = "errorMsg";
export const BANNER_TYPE_SUCCESS = "successMsg";
export const COLOR_TYPE_INFO = "#19b373";
export const COLOR_TYPE_ERROR = "#fbbaba";
export const COLOR_TYPE_MIX = "#FEEFB3";
export const COLOR_TYPE_OFFLINE = "#FEEFB3";
export const FRONTM_WEB_BOT = {
  botId: FRONTM_BOT_ID,
  botName: "FrontM Assistant",
  // logoUrl: "AuthenticationLogo.png",
  description: "Onboarding bot for Frontm",
  logoUrl: "AuthenticationLogo.png",
  userDomain: "frontmai"
};
export const VOYAGER_VOICE_AUTH_CODE = "gnsTestBots";
export const HELPER_MESSAGE =
  "Please contact FrontM support. You have been logged in to default Voyager Voice domain";

export const ACTIVATE_ENTERPRISE_BOTS_ERROR_CODES = [
  "Invalid code",
  "Code has been already used by the user",
  "Code could not be applied. The code has expired or the maximum limit has been reached"
];
export const ONBOARDINGBOT = "onboarding-bot";

/* ====== Sign-up pages ========= */
export const VOYAGER_LANDING = "/voyagervoice";
export const THURAYA_LANDING = "/thuraya";
export const STATION_SATCOM_LANDING = "/stationsatcom";
export const FRONTM_LANDING = "/signup";
export const FORM_PATH = "/form";
export const TABLE_PATH = "/table";

/* ======= Sign-in Header Text ==== */

export const VOYAGER_LOGIN_MAIN_HEADER_TEXT = "Welcome!";
export const THURAYA_LOGIN_MAIN_HEADER_TEXT = "Welcome!";
export const VOYAGER_LOGIN_SUB_HEADER_TEXT = "Log in to Voyager Voice";
export const THURAYA_LOGIN_SUB_HEADER_TEXT = "Log in to THURAYA TRACKER";
export const STATION_SATCOM_LOGIN_MAIN_HEADER_TEXT = "Welcome!";
export const STATION_SATCOM_LOGIN_SUB_HEADER_TEXT = "Log in to Station Satcom";
/* ======= Sign-up form Text ===== */

export const VOYAGER_MAIN_HEADER_TEXT =
  "Get Low Cost, High Quality Calls between Shore and Ships";
export const VOYAGER_SUB_HEADER_TEXT =
  "Save up to $7.50 a minute on L-band calls compared to\n" +
  "standard rates and enjoy better call quality";

export const THURAYA_MAIN_HEADER_TEXT = "Welcome to a more connected world";
export const THURAYA_SUB_HEADER_TEXT = "";

export const STATION_SATCOM_MAIN_HEADER_TEXT =
  "Welcome to a more connected world";
export const STATION_SATCOM_SUB_HEADER_TEXT = "";

/* =========== Login page ======== */

export const VOYAGER_LOGO = "/img/VoyagerVoice-HORZÔÇôlogoREV-master-CMYK.svg";
export const VOYAGER_LOGO_ALT = "Voyager Logo";
export const THURAYA_LOGO = "img/thuraya_logo.png";
export const THURAYA_LOGO_ALT = "Thuraya Logo";
export const STATION_SATCOM_LOGO = "img/station-satcom-logo@2x.png";
export const STATION_SATCOM_LOGO_ALT = "Station Satcom Logo";

export const VOYAGER_LOGIN_BACKGROUND = "#151C24";
export const THURAYA_LOGIN_BACKGROUND = "#ffff";
export const STATION_SATCOM_LOGIN_BACKGROUND = "#ffff";

export const VOYAGER_LOGIN_MAIN_HEADER_TEXT_COLOR = "#fff";
export const VOYAGER_LOGIN_SUB_HEADER_TEXT_COLOR = "#fff";
export const THURAYA_LOGIN_MAIN_HEADER_TEXT_COLOR = "#4A4A4A";
export const THURAYA_LOGIN_SUB_HEADER_TEXT_COLOR = "#666666";
export const STATION_SATCOM_LOGIN_MAIN_HEADER_TEXT_COLOR = "#4A4A4A";
export const STATION_SATCOM_LOGIN_SUB_HEADER_TEXT_COLOR = "#666666";
/* ========= Signup background ======== */

export const VOYAGER_SIGNUP_BACKGROUND = "/img/voyagervoice.jpg";
export const THURAYA_SIGNUP_BACKGROUND = "/img/thuraya-onboarding-img@3x.png";
export const STATION_SATCOM_SIGNUP_BACKGROUND =
  "/img/stationsatcom-onboarding-img.png";
export const FRONTM_SIGNUP_BACKGROUND = "/img/green-background@2x.png";

/* ======= Login background ======== */

/* ======= Reset password ======== */
export const THURAYA_RESET_BACKGROUND = "/img/thuraya-ship-bg.png";
export const STATION_SATCOM_RESET_BACKGROUND =
  "/img/stationsatcom-onboarding-img.png";
