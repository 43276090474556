import React, { Component } from "react";
import Mandatory from "./Mandatory";
import InfoIcon from "./InfoIcon";
import ErrorMessage from "./ErrorMessage";
// import ModalPopup from "../../../ModalMessages/ModalPopup";
import Spinner from "react-spinkit";

class LookUpField extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  UNSAFE_componentWillReceiveProps(nextProps, nextContext) {
    // this.setState({ results: nextProps.element.results });
    if (nextProps.element.showSpinner === false) {
      this.setState({ showSpinner: false });
    }
  }

  handleBtnClick = e => {
    e.preventDefault();
    e.stopPropagation();
    let { element, doDataLookUp, action } = this.props;
    let { valueSelected } = this.state;
    if (valueSelected) {
      action("");
      this.setState({ valueSelected: false, showSpinner: false });
      this.props.clearLookUpResults(element);
      return;
    }
    this.setState({ showSpinner: true });
    doDataLookUp(element.id, element.value);
  };

  showMoreInfo = (info, text) => {
    this.setState({ moreInfo: info, moreInfoTitle: text });
  };

  hideMoreInfo = () => {
    this.setState({ moreInfo: null, moreInfoTitle: null });
  };

  render() {
    let {
      element,
      doDataLookUp,
      action,
      completed,
      onBlurField,
      isReadOnly
    } = this.props;
    let { valueSelected, moreInfo, moreInfoTitle, showSpinner } = this.state;
    let results = element.results;

    // results = [
    //   { text: "one", info: { name: "kell" } },
    //   { text: "this is two", info: { name: "max" } },
    //   { text: "this is three" },
    //   { text: "four", info: { name: "kell" } },
    //   { text: "this is five", info: { name: "max" } },
    //   { text: "this six" }
    // ];
    let content = (isReadOnly && (
      <div className="d-flex flex-row justify-content-between readOnlyForm">
        <label
          htmlFor={element.id}
          className="d-flex align-items-center flex-row"
        >
          {element.title}
          <Mandatory element={element} />
        </label>
        <label>{(element.value && element.value.text) || ""}</label>
      </div>
    )) || (
      <div className="form-group px-4">
        <label
          htmlFor={element.id}
          className="d-flex align-items-center flex-row"
        >
          {element.title}
          <Mandatory element={element} />
          <span className="ml-2">
            <InfoIcon id={`tooltip-${element.id}`} info={element.info} />
          </span>
        </label>
        <div className="d-flex align-items-center">
          <div style={{ width: "100%" }}>
            <div
              className="d-flex justify-content-center align-items-center"
              style={{
                boxSizing: "border-box",
                width: "100%",
                border: "0.2px solid rgba(91,91,91,0.2)",
                borderRadius: "10px",
                backgroundColor: "#FFFFFF",
                boxShadow: "0 0 4px 0 rgba(0,0,0,0.08)"
              }}
            >
              <input
                className="form-control chat-input border0"
                placeholder="Search"
                value={
                  element.value && element.value.text
                    ? element.value.text
                    : element.value
                    ? element.value
                    : ""
                }
                onChange={e => {
                  action(e.target.value);
                }}
                disabled={completed === true || element.readOnly}
                onBlur={onBlurField}
              />
              {!completed && !element.readOnly && (
                <a
                  onClick={this.handleBtnClick}
                  className="btn border0"
                  style={{ position: "inherit" }}
                  disabled={completed === true || element.readOnly}
                >
                  {!valueSelected && (
                    <span>
                      {!showSpinner && (
                        <img src="/img/search-icon@2x.png" width="12" />
                      )}
                      {showSpinner && (
                        <div>
                          <Spinner name="circle" color="steelblue" />
                        </div>
                      )}
                    </span>
                  )}
                  {valueSelected && (
                    <img src="/img/search-clear-icon@2x.png" width="12" />
                  )}
                  {/*{showSpinner && (*/}
                  {/*  <div>*/}
                  {/*    <Spinner name="circle" color="steelblue" />*/}
                  {/*  </div>*/}
                  {/*)}*/}
                </a>
              )}
            </div>
            {!valueSelected && results ? (
              <div
                style={{
                  // height: "112px",
                  // padding: "10px",
                  // // position: "absolute",
                  // width: "100%",
                  // overflowY: "auto",
                  // zIndex: 9999,
                  // backgroundColor: "#F4F4F4"

                  backgroundColor: "hsl(0,0%,100%)",
                  borderRadius: "4px",
                  boxShadow:
                    "0 0 0 1px hsla(0,0%,0%,0.1), 0 4px 11px hsla(0,0%,0%,0.1)",
                  marginBottom: "8px",
                  marginTop: "8px",
                  position: "absolute",
                  width: "90%",
                  zIndex: 1,
                  boxSizing: "border-box"
                }}
              >
                <div
                  style={{
                    maxHeight: "150px",
                    overflowY: "auto",
                    paddingBottom: "4px",
                    paddingTop: "4px",
                    position: "relative",
                    boxSizing: "border-box"
                  }}
                >
                  {results.map(result => (
                    <div
                      className="d-flex justify-content-between"
                      style={{
                        backgroundColor: "transparent",
                        color: "inherit",
                        cursor: "default",
                        display: "block",
                        fontSize: "inherit",
                        padding: "8px 12px",
                        width: "100%",
                        userSelect: "none",
                        boxSizing: "border-box"
                      }}
                    >
                      <a
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          action({ ...result });
                          this.setState({
                            valueSelected: true
                          });
                        }}
                      >
                        {result.text}
                      </a>
                      {result.info && Object.keys(result.info).length > 0 && (
                        <a
                          style={{
                            height: "20px",
                            width: "20px",
                            border: "1px solid #00BDF2",
                            backgroundColor: "#FFFFFF",
                            borderRadius: "50%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            color: "#00bdf2",
                            cursor: "pointer"
                          }}
                          onClick={() => {
                            this.showMoreInfo(result.info, result.text);
                          }}
                        >
                          i
                        </a>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <ErrorMessage element={element} />
        {/* {moreInfo && (
          <ModalPopup
            onClose={this.hideMoreInfo}
            size="sm"
            title={moreInfoTitle}
          >
            <div className="rounded">
              <div>
                {Object.keys(moreInfo).map(key => {
                  return (
                    <div className="d-flex justify-content-between my-1">
                      <div style={{ fontWeight: "bold" }}>{key}</div>
                      <div>{moreInfo[key]}</div>
                    </div>
                  );
                })}
              </div>
            </div>
          </ModalPopup>
        )} */}
      </div>
    );
    return content;
  }
}

export default LookUpField;
