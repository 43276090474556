import React from "react";
import Mandatory from "./Mandatory";
import CheckBox from "./CheckBox";
import InfoIcon from "./InfoIcon";
import ErrorMessage from "./ErrorMessage";

export default function CheckBoxField({
  isReadOnly = false,
  element,
  action,
  completed,
  onBlurField
}) {
  let { options = [], value } = element;
  let content = (isReadOnly && (
    <div className="d-flex flex-row justify-content-between readOnlyForm">
      <label
        htmlFor={element.id}
        className="d-flex align-items-center flex-row"
      >
        {element.title}
        <Mandatory element={element} />
      </label>
      <label>{value || ""}</label>
    </div>
  )) || (
    <div className="form-group px-4">
      <label htmlFor={element.id}>
        <div className="d-flex align-items-center">
          {element.title}
          <Mandatory element={element} />
          <span className="ml-2">
            <InfoIcon id={`tooltip-${element.id}`} info={element.info} />
          </span>
        </div>
      </label>
      {options.map((option, index) => {
        return (
          <CheckBox
            checked={value && value.indexOf(option) !== -1}
            option={option}
            action={action}
            disabled={completed || element.readOnly}
            id={element.id + "_CheckBoxField_" + index}
          />
        );
      })}
      <ErrorMessage element={element} />
    </div>
  );
  return content;
}
