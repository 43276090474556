import React, { PureComponent } from "react";
import { Marker } from "react-map-gl";
import MarkerPin from "./MarkerPin";

export default class MarkerComponent extends PureComponent {
  render() {
    let {
      markerSize = 5,
      latitude,
      longitude,
      onClick,
      markerColor,
      iconType,
      iconDirection,
      markerData
    } = this.props; //arrow, aircraft, poi, circle,

    let node = null;

    // if (iconDirection) {
    //   iconDirection -= 90;
    // }

    let offset = null;

    if (iconType === "aircraft") {
      node = (
        <img
          src={"/img/moving-maps-plane_zero.png"}
          style={
            iconDirection
              ? {
                  transform: "rotate(" + iconDirection + "deg)",
                  width: "75%",
                  cursor: "pointer"
                }
              : { width: "75%" }
          }
          onClick={onClick}
          alt="aircraft"
        />
      );
      offset = { left: -39.5, top: -29.5 };
    } else if (iconType === "arrow") {
      node = (
        <img
          src={"/img/arrow_image.png"}
          style={
            iconDirection
              ? {
                  transform: "rotate(" + iconDirection + "deg)",
                  width: "75%",
                  cursor: "pointer"
                }
              : { width: "75%", cursor: "pointer" }
          }
          onClick={onClick}
          alt="arrow"
        />
      );
      offset = { left: -27, top: -27 };
    } else {
      node = (
        <MarkerPin color={markerColor} size={markerSize} onClick={onClick} />
      );
    }

    return (
      <Marker
        anchor="bottom"
        longitude={longitude}
        latitude={latitude}
        offsetLeft={offset ? offset.left : 0}
        offsetTop={offset ? offset.top : 0}
      >
        {node}
      </Marker>
    );
  }
}
