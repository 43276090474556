import React from "react";
import ReactDOM from "react-dom";
import "./Style/index.css";
// import "./Style/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Main from "./Components/Main";
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(<Main />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
