import { MAKE_CALL, RESET_CALL_INFO } from "../actions/phone";
import { LOGOUT_USER } from "../actions/user";

let initialState = {
  type: null,
  from: null,
  to: null,
  toName: null,
  toUserId: null,
  localCall: null,
  diallerState: null
};

function phoneReducer(state = initialState, action) {
  switch (action.type) {
    case MAKE_CALL:
      let data = action.data;
      return {
        type: data.type,
        from: data.from,
        to: data.to,
        toName: data.toName,
        toUserId: data.toUserId,
        localCall: data.localCall,
        diallerState: data.diallerState
      };

    case RESET_CALL_INFO:
      return initialState;

    case LOGOUT_USER:
      return { ...initialState };
    default:
      return state;
  }
}

export default phoneReducer;
