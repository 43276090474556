import React, { Component } from "react";
import Config from "../../Utils/Config";
import Avatar from "../Common/Avatar";
import { connect } from "react-redux";

const R = require("ramda");

class ChatAvatar extends React.Component {
  render() {
    const { chat, conversation, conversational, botSubscriptions } = this.props;
    // console.log("bot name ", this.props);

    let logoURL = "";
    let size = 24;
    if (conversational === false) {
      const botId =
        (conversation && conversation.bot && conversation.bot.botId) || null;
      if (botId && botSubscriptions && botSubscriptions.length > 0) {
        for (let i = 0; i < botSubscriptions.length; i++) {
          const botDetail = botSubscriptions[i];
          if (botDetail.botId === botId) {
            logoURL = botDetail.logoUrl;
            size = 40;
            break;
          }
        }
      }
    } else {
      logoURL = R.prop(
        "logoUrl",
        botSubscriptions && botSubscriptions[0] ? botSubscriptions[0] : ""
      );
    }

    let avatar = null;
    if (conversation && conversation.channel) {
      avatar = (
        <Avatar
          name={chat.conversationOwner ? chat.conversationOwner.userName : ""}
          size={size}
          color="bg-primary"
        />
      );
    } else if (conversation && conversation.contact) {
      avatar = (
        <Avatar
          name={conversation.contact.userName}
          size={size}
          color="bg-primary"
        />
      );
    } else {
      avatar = (
        <Avatar
          name={
            botSubscriptions && botSubscriptions[0].botName
              ? botSubscriptions[0].botName
              : ""
          }
          imgSrc={`${R.prop("contentURL", Config)}${logoURL}`}
          size={size}
          color="bg-primary"
        />
      );
    }
    return avatar;
  }
}

const mapActionToProps = {};

const mapDataToProps = state => {
  let chats = state.chats;
  let conversation = chats.selectedConversation;
  let selectedConversationId = conversation && conversation.conversationId;
  return {
    conversational: selectedConversationId
      ? chats.conversationModeMap[selectedConversationId]
        ? chats.conversationModeMap[selectedConversationId].conversational ===
          false
          ? false
          : true
        : true
      : true,
    botSubscriptions: state.user.botSubscriptions
  };
};
export default connect(mapDataToProps, mapActionToProps)(ChatAvatar);
