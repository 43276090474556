import React from "react";
import { getDateTimeString } from "../../Utils/Helpers";

const ChatDateTimeUI = ({ chat }) => {
  if (!chat) {
    return null;
  }
  return (
    <div className="fs10 text-muted">{getDateTimeString(chat.createdOn)}</div>
  );
};

export default ChatDateTimeUI;
