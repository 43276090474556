import { createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./reducers";

const loggerMiddleware = createLogger();
// const loggerMiddleware = () => {};

// function configureStore(preloadedState) {
//   return createStore(
//     rootReducer,
//     preloadedState,
//     applyMiddleware(thunkMiddleware, loggerMiddleware)
//   );
// }

/*=================SID Start =================== */
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(thunkMiddleware, loggerMiddleware)
  // applyMiddleware(thunkMiddleware)
  // other store enhancers if any
);

function configureStore(preloadedState) {
  return createStore(rootReducer, preloadedState, enhancer);
}
/* ============== END ======================== */
const store = configureStore();

export default store;
