import React, { PureComponent } from "react";
import { MessageTypeConstants } from "../../Services/Message";

class NotificationMessage extends PureComponent {
  render() {
    let { chat } = this.props;

    let type = chat.messageType;

    return (
      <div className="d-flex justify-content-center my-2">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{
            height: "26px",
            width: "336px",
            borderRadius: "10px",
            backgroundColor:
              type === MessageTypeConstants.MESSAGE_TYPE_CRITICAL_NOTIFICATION
                ? "#FEC901"
                : "#CDF4FF",
            boxShadow:
              "inset 0 1px 1px 0 rgba(255,255,255,0.5), 0 1px 1px 0 rgba(0,0,0,0.1)",
            color: "#4A4A4A",
            textAlign: "center"
          }}
        >
          {chat.message}
        </div>
      </div>
    );
  }
}

export default NotificationMessage;
